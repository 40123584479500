import { Payment } from "@mui/icons-material";
import SpeedIcon from "@mui/icons-material/Speed";
// BsCardImage
// FaUserAlt
import { FaRegImages } from "react-icons/fa";
// import { FaUserAlt } from "react-icons/fa";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SportsIcon from "@mui/icons-material/Sports";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BallotIcon from "@mui/icons-material/Ballot";
import { MdSportsHandball } from "react-icons/md";
export const sideMenuItemList = [
  {
    label: "Dashboard",
    icons: <SpeedIcon />,
    path: "/",
  },
  // createNewLeague
  {
    label: "Create League",
    icons: <AddCircleOutlineIcon />,
    path: "/createNewLeague",
  },
  {
    label: "Leagues",
    // icons: <img src="/assets/icons/bowling.svg" />,
    icons: <BallotIcon />,
    path: "/leagues/",
  },
  {
    label: "Live Matches",
    icons: <SportsIcon />,
    path: "/all/ongoing/day/matches",
  },
  {
    label: "Wallet",
    icons: <AccountBalanceWalletIcon />,
    path: "/wallet",
  },
  {
    // FcSportsMode
    label: "Players",
    // icons: <img src="./assets/icons/player.png" />,
    icons: <MdSportsHandball />,
    path: "/players",
  },
  {
    label: "Gallery",
    icons: <FaRegImages />,
    path: "/gallery",
  },
  // {
  //   label: "Payment",
  //   icons: <Payment />,
  //   path: "/payment",
  // },
  {
    label: "Profile",
    icons: <PersonIcon />,
    path: "/myProfile/",
  },
  {
    label: "Business Hours",
    icons: <AccessTimeIcon />,
    path: "/businessHour",
  },
  // {
  //   label: "Logout",
  //   icons: <img src="/assets/icons/player2.svg" />,
  //   path: "/lo",
  // },
];

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { Typography, Stack } from "@mui/material";
export default function WithdrawalRequestTable({ details }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "600px", overflow: "auto" }}
    >
      <Typography variant="h6" fontFamily={"Dosis"}>
        Withdrawal Request
      </Typography>
      {details && details?.length > 0 ? (
        <Table sx={{ width: "auto" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr.No</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Date/Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ width: "auto" }}>
            {details?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right"> ${row?.requestAmount}</TableCell>
                <TableCell align="right">Withdrawal</TableCell>
                <TableCell align="right">{row?.requestStatus}</TableCell>
                <TableCell align="right">
                  {`${dayjs(row?.dateOfRequest).format("DD-MM-YYYY")}
            ${dayjs(row?.dateOfRequest).format("HH:mm")}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Stack>
          <Typography variant="body2" component={"p"}>
            No records found
          </Typography>
        </Stack>
      )}
    </TableContainer>
  );
}

import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";

import Typography from "@mui/material/Typography";

import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import { FaCamera } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { Url } from "../../url";
import { MuiTelInput } from "mui-tel-input";
import { login } from "../../redux/action/adminActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      style={{ backgroundColor: "white" }}
      ref={ref}
      {...props}
    />
  );
});

const CreateAlleyDetails = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { alley } = useSelector((state) => state);

  const [file, setfile] = useState(null);

  const [fileUrl, setfileUrl] = useState("");

  const [phone, setPhone] = React.useState("");

  const [accountValues, setAccountValues] = useState({
    countryCode: "",
    contactNumber: "",
    numberOfLane: "",
    alleyDetails: "",
    alleyName: "",
    email: "",
    city: "",
    state: "",
    addressLine1: "",
    zipCode: "",
  });

  //   update alley details state
  const handleChange = (e) => {
    setAccountValues({ ...accountValues, [e.target.name]: e.target.value });
  };
  // update phone input state
  const handleCodeChange = (newPhone) => {
    setPhone(newPhone);
  };

  // submit message / send message
  const handleSubmitAlleyDetails = async (e) => {
    console.log(".......");
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const formValues = new FormData();
      if (file) {
        formValues.append("file", file);

        formValues.append("alleyName", `${accountValues?.alleyName}`);
        // formValues.append("email", accountValues?.email);
        formValues.append(
          "contactNumber",
          `${phone.replace(/\s/g, "").slice(-10)}`
        );
        formValues.append(
          "countryCode",
          `${phone.replace(/\s/g, "").slice(0, -10)}`
        );
        formValues.append(
          "addressLine1",
          accountValues?.addressLine1 ? accountValues?.addressLine1 : null
        );
        formValues.append(
          "city",
          accountValues?.city ? accountValues?.city : null
        );
        formValues.append(
          "state",
          accountValues?.state ? accountValues?.state : null
        );
        formValues.append(
          "zipCode",
          accountValues?.zipCode ? accountValues?.zipCode : null
        );
        formValues.append(
          "numberOfLane",
          accountValues?.numberOfLane ? accountValues?.numberOfLane : null
        );
        formValues.append(
          "alleyDetails",
          accountValues?.alleyDetails ? accountValues?.alleyDetails : null
        );
        const res = await axios.post(
          "/api/v1/alley/account/creation",
          formValues
        );
        console.log("res==>", res);
        dispatch(login({ email: alley.email?.email, firstTimeLogin: false }));
  
        navigate("/");
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar("select an image", "warning"));
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error?.response?.data?.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  // ---------------------------------

  const handleFileChange = (e) => {
    setfile(e.target.files[0]);
    setfileUrl(URL.createObjectURL(e.target.files[0]));
  };
  // ---------------------------------
  useEffect(() => {
    if (alley) {
      setAccountValues({ ...accountValues, email: alley.email });
    }
  }, []);

  // ---------------------------------
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          bgcolor: "white !important",
          "& .css-cggd1w-MuiPaper-root-MuiDialog-paper": { bgcolor: "white" },
        }}
      >
        <Stack
          //   marginTop={"100px"}
          alignItems={"center"}
          justifyContent={"center"}
          p={1}
        >
          <Box
            p={2}
            width={{ md: "60%", xs: "100%" }}
            border={"1px solid rgba(0,172,6,1)"}
          >
            <form onSubmit={handleSubmitAlleyDetails}>
              <Stack justifyContent={"center"} alignItems={"center"}>
                <Typography variant="h5" textAlign={"center"}>
                  Please Update Your Details
                </Typography>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  // variant="dot"
                  badgeContent={
                    // editMode ? (
                    <Box
                      htmlFor="icon-button-photo"
                      component={"label"}
                      width={"25px"}
                      height={"25px"}
                      bgcolor={"rgba(111, 190, 68, 1)"}
                      sx={{
                        transition: "ease-in-out 300ms",
                        "&:hover": {
                          backgroundColor: "rgba(11, 94, 25, 1)",
                        },
                      }}
                      borderRadius={"100%"}
                      color={"white"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      textAlign={"center"}
                      fontSize={"15px"}
                    >
                      {/* <ImPencil /> */}
                      <FaCamera />
                    </Box>
                    // ) : (
                    //   ""
                    // )
                  }
                >
                  <input
                    accept="image/*"
                    id="icon-button-photo"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    type="file"
                    // required
                  />
                  <Avatar
                    sx={{
                      "&.MuiAvatar-root": {
                        height: "100px",
                        width: "100px",
                        color: "red",
                      },
                    }}
                    alt={"Alley"}
                    src={fileUrl}
                  />
                </Badge>
              </Stack>

              <Grid
                container
                rowGap={0.5}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                columnGap={1}
                width={"100%"}
              >
                <Grid item xs={12} sm={5.75}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Alley Name"
                    name="alleyName"
                    required
                    value={accountValues.alleyName}
                    placeholder="Enter alley name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={5.75}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Details"
                    name="alleyDetails"
                    required
                    value={accountValues.alleyDetails}
                    placeholder="About your details"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={5.75}>
                  <MuiTelInput
                    label="Enter Phone Number"
                    fullWidth
                    value={phone}
                    onChange={handleCodeChange}
                    defaultCountry="US"
                    placeholder="Enter Phone Number"
                    sx={{
                      " & input": {
                        color: "grey",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5.75}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Email"
                    name="email"
                    required
                    disabled
                    sx={{
                      "& .MuiInputBase-root": {
                        color: "white !important",
                      },
                    }}
                    value={accountValues.email}
                    placeholder="Your email"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={5.75}>
                  <TextField
                    fullWidth
                    size="small"
                    // multiline
                    // rows={4}
                    label="Number of Lane"
                    name="numberOfLane"
                    required
                    value={accountValues.numberOfLane}
                    placeholder="Enter the number of lane"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Address</Typography>
                </Grid>
                <Grid item xs={12} sm={5.75}>
                  <TextField
                    fullWidth
                    size="small"
                    // multiline
                    // rows={4}
                    label="Address Line 1"
                    name="addressLine1"
                    required
                    value={accountValues.addressLine1}
                    placeholder="Address line 1"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={5.75}>
                  <TextField
                    fullWidth
                    size="small"
                    label="City"
                    name="city"
                    required
                    value={accountValues.city}
                    placeholder="City"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={5.75}>
                  <TextField
                    fullWidth
                    size="small"
                    // multiline
                    // rows={4}
                    label="State"
                    required
                    name="state"
                    value={accountValues.state}
                    placeholder="State"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={5.75}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Enter Pin / Zip Code"
                    name="zipCode"
                    required
                    value={accountValues.zipCode}
                    placeholder="Pin / Zip Code"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={2}
                spacing={1}
              >
                <Button
                  autoFocus
                  variant="contained"
                  size="small"
                  type="submit"
                  color="primary"
                  sx={{ color: "white" }}
                >
                  Create Account
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
};

export default CreateAlleyDetails;

import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import LeagueCards from "../../../components/cards/LeagueCards";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { isLoading } from "../../../redux/action/defaultActions";
import axios from "axios";
import { useEffect } from "react";
import MatchCards from "../../../components/cards/MatchCards";

function createCardData(Image) {
  return { Image };
}

const rows = [
  createCardData("/assets/images/matchPic1.png"),
  createCardData("/assets/images/matchPic2.png"),
  createCardData("/assets/images/matchPic3.png"),
  createCardData("/assets/images/matchpic4.png"),
];

export default function UpcomingMatchesPage() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  //******************* */ api hit get team league details data  *************************
  const getData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/upcoming/leagues/matches`);
      console.log("allMatches", res);
      setData(res?.data?.leaguesMatches);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Stack spacing={3} justifyContent={"center"}>
        <Box
          borderRadius={"5px"}
          p={2}
          component={Paper}
          py={2}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            p={1}
            py={2}
          >
            <Typography variant="h6">Upcoming League Matches </Typography>
            {/* <Typography
              fontFamily={"Dosis"}
              variant="h5"
              to="/"
              sx={{
                textDecoration: "none",
                color: "green !important",
                "&:hover": {
                  color: "rgb(67, 191, 55)",
                  textDecoration: "2px underline",
                  textDecorationColor: "green ",
                },
              }}
            >
             Filter
            </Typography> */}
          </Stack>
          {/* <Stack>
            <Typography sx={{ color: "white" }}>Friday, August 11</Typography>
          </Stack> */}

          <Grid container spacing={1}>
            {data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sx={6}
                    md={5}
                    lg={4}
                    mt={1}
                    position={"relative"}
                  >
                    <MatchCards
                      teamNameA={"Routela"}
                      teamNameB={"Rishabh"}
                      dateOfMatch={"2023-09-09T17:00:00.000Z"}
                      timeOfMatch={"2023-09-09T17:00:00.000Z"}
                    />
                  </Grid>
                );
              })
            ) : (
              <Box width={"100%"} height={"100%"}>
                <Typography
                  mt={3}
                  variant="h3"
                  sx={{ fontFamily: "Dosis", textAlign: "center" }}
                >
                  OOP'S SORRY NO MATCHES
                </Typography>
                <Box
                  mt={2}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    component={"img"}
                    alt="sorry"
                    width={"250px"}
                    src="/assets/images/bowling-breaks.png"
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

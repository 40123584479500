import { Stack } from "@mui/material";
import React from "react";
import LeaguesCard from "./LeaguesCard";

const CreateNewLeague = () => {
  return (
    <Stack justifyContent={"center"}   spacing={2}>
      <LeaguesCard />
    </Stack>
  );
};

export default CreateNewLeague;

import { Grid, Box, Typography, Stack, Button } from "@mui/material";
import dayjs from "dayjs";

import React from "react";
import Carousel from "react-material-ui-carousel";



export default function CarouselHeroSection({ data }) {
  return (
    <>
      <Carousel
        prev={() => {}}
        next={() => {}}
        animation={"slide"}
        swipe={true}
        indicators={true}
        indicatorContainerProps={{
          style: {
            transitionDuration: "200ms",
          },
        }}
        interval="2500"
      >
        {data?.map((item, index) => {
          return (
            <Box
              key={index}
              //   container
              p={2}
              borderRadius={"10px"}
              sx={{
                // position: "relative",
                // height: "600px",
                background:
                  " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={4} p={1}>
                  <img
                    className="headImg"
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      height: "12rem",
                      borderRadius: "50%",
                    }}
                    src={`${item?.league?.leagueImages?.url}`}
                    alt=""
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Stack fontFamily={"Dosis"} spacing={2}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        textDecorationLine: "underline",
                      }}
                    >
                      {item?.league?.leagueName}
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6} p={1}>
                        <Typography variant="body2" component={"p"} padding={2}>
                          <span
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            Start Date :
                          </span>{" "}
                          {dayjs(item?.league?.startDate).format("DD MMM YYYY")}
                        </Typography>
                        <Typography variant="body2" component={"p"} padding={2}>
                          <span
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            End Date :
                          </span>{" "}
                          {dayjs(item?.league?.leagueEndDate).format(
                            "DD MMM YYYY"
                          )}
                        </Typography>
                      </Grid>
                      <Grid Grid item xs={12} sm={12} md={6} p={1}>
                        <Typography variant="body2" component={"p"} padding={2}>
                          <span
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            Total Teams :
                          </span>{" "}
                          {item?.league?.numberOfTeam}
                        </Typography>
                        <Typography variant="body2" component={"p"} padding={2}>
                          <span
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            League Fee :
                          </span>{" "}
                          ${item?.league?.leagueFee}
                        </Typography>
                        <Typography variant="body2" component={"p"} padding={2}>
                          <span
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            Duration:
                          </span>{" "}
                          {item?.leagueLength}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Carousel>
    </>
  );
}

import { Grid, Box, Button, useMediaQuery, IconButton } from "@mui/material";
import React from "react";
import ItemsCarousel from "react-items-carousel";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../redux/action/defaultActions";
import axios from "axios";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MatchCards from "../../../components/cards/MatchCards";
export default function OverViewCarousal() {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const m = useMediaQuery("(max-width:900px)");
  const s = useMediaQuery("(max-width:850px)");
  const l_g = useMediaQuery("(min-width:920px)");
  const [container_width, setcontainer_width] = useState(3);
  const chevronWidth = 40;
  //  Api section get data on it
  const { leagueId } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const getApi = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `/api/v1/league/active/league/live/matches/${leagueId}`
      );
      // console.log("team ====>", res?.data);
      console.log("dekhooo=>", res?.data?.leaguesMatches);
      setData(res?.data?.leaguesMatches);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  useEffect(() => {
    if (s) {
      setcontainer_width(1);
    } else if (l_g) {
      setcontainer_width(3);
    } else {
      setcontainer_width(2);
    }
    getApi();
  }, [m, l_g, s]);
  return (
    <>
      <div style={{ padding: `0 ${chevronWidth}px` }}>
        {/* <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          infiniteLoop={true}
          numberOfCards={container_width}
          slidesToScroll={1}
          gutter={20}
          leftChevron={
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(16, 16, 16, 1)",
                },
                color: "white",
                fontSize: "20px",
                borderRadius: "50%",
              }}
              aria-label="delete"
            >
              <ChevronLeftIcon />
            </IconButton>
          }
          rightChevron={
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(16, 16, 16, 1)",
                },
                color: "white",
                fontSize: "20px",
                borderRadius: "50%",
              }}
              aria-label="delete"
            >
              <ChevronRightIcon />
            </IconButton>
          }
          outsideChevron
          chevronWidth={chevronWidth}
        >
          {data?.map((item, index) => {
            return (
              <>
                <Box sx={{ position: "relative" }} p={1}>
                  <MatchCards
                    key={index}
                    teamNameA={"Bold"}
                    teamNameB={"Hold"}
                    // {item?.matches?.teamB?.teamName}
                    dateOfMatch={item?.teamMatchDate}
                    timeOfMatch={item?.teamMatchTiming}
                  />
                </Box>
              </>
            );
          })}
         
        </ItemsCarousel> */}
      </div>
    </>
  );
}

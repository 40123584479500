import * as React from "react";
import Box from "@mui/material/Box";

import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import axios from "axios";
import LeagueCards from "../../../components/cards/LeagueCards";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../redux/action/defaultActions";
import { useNavigate, useParams } from "react-router-dom";
import MatchCards from "../../../components/cards/MatchCards";

export default function LiveMatches() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [liveMatchesData, setLiveMatchesData] = React.useState([]);
  const { leagueId } = useParams();
  const getApiData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/active/league/live/matches/${leagueId}`);
      console.log("liveMathes==>", res.data);
      setLiveMatchesData(res.data);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log(error.message);
    }
  };
  React.useEffect(() => {
    getApiData();
  }, []);
  return (
    <>
      <Stack spacing={3} justifyContent={"center"}>
        <Box
          borderRadius={"5px"}
          p={2}
          component={Paper}
          py={2}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Typography variant="h6">Live Matches</Typography>
          <Grid container spacing={1} mt={1}>
            {liveMatchesData?.liveMatchesData?.length > 0 ? (
              liveMatchesData?.leaguesMatches?.map((item, index) => {
                console.log("item==>", item);
                return (
                  <Grid
                    item
                    xs={12}
                    sx={6}
                    mt={1}
                    md={5}
                    lg={4}
                    // onClick={() => navigate(`/active/leagues/matches`)}
                    position={"relative"}
                  >
                    {/* <LeagueCards
                    key={index}
                    logo={""}
                    teamNameA={item?.matches?.teamA?.teamName}
                    teamNameB={item?.matches?.teamB?.teamName}
                    matchType="live"
                    matchData={item}
                    redirectTo={`/active/leagues/matches`}
                  /> */}
                    <MatchCards
                      key={index}
                      teamNameA={item?.matches?.teamA?.teamName}
                      teamNameB={item?.matches?.teamB?.teamName}
                      dateOfMatch={item?.matches?.teamMatchDate}
                      timeOfMatch={item?.matches?.teamMatchTimin}
                    />
                  </Grid>
                );
              })
            ) : (
              <Box width={"100%"} height={"100%"}>
                <Typography
                  mt={3}
                  variant="h3"
                  sx={{ fontFamily: "Dosis", textAlign: "center" }}
                >
                  OOP'S SORRY NO MATCHES
                </Typography>
                <Box
                  mt={2}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    component={"img"}
                    alt="sorry"
                    width={"250px"}
                    src="/assets/images/bowling-breaks.png"
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

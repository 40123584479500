import { Box } from "@mui/system";
import React, { useEffect } from "react";
import UpdateScore from "./UpdateScore";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading } from "../../redux/action/defaultActions";

const LiveMatch = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(isLoading(false));
  }, []);

  return (
    <Box>
      <Typography>Live Match | Update Score</Typography>
      <UpdateScore />
    </Box>
  );
};

export default LiveMatch;

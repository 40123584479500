import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button1 from "../reusablecomponents/buttons/Button1";
import { Box } from "@mui/system";
import PanelButtons from "../reusablecomponents/buttons/PanelButtons";
import Button from "@mui/material/Button";
import Button2 from "../reusablecomponents/buttons/Button2";
import Button3 from "../reusablecomponents/buttons/Button3";
import { IconButton, Paper } from "@mui/material";
import InputWIthIcon from "../reusablecomponents/formcontrols/InputWIthIcon";
import { Mail, MailLock } from "@mui/icons-material";
import SingleInputWithLabels from "../reusablecomponents/formcontrols/SingleInputWithLabels";
import SingleInputWithLabels2 from "../reusablecomponents/formcontrols/SingleInputWithLabels2";
const ThemeComponents = () => {
  const [values, setValues] = useState({
    selectedValue: "--",
    date: "",
  });
  // const [selectedValue, setSelectedValue] = useState("--");
  console.log("selectedValue=>", values?.selectedValue);
  return (
    <>
      <Typography>Button</Typography>
      <Box display={"flex"}>
        <Button1 name="button1" />
        <Button1 name="button1" fontSize={"25px"} />
      </Box>
      <Box
        mt={2}
        minHeight={"250px "}
        p={2}
        display={"flex"}
        sx={{
          borderRadius: "10px",
          background:
            " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
        }}
      >
        {/* use of button2 and button3 */}
        <Button2 name="button2" sx={{ ml: 1 }} />
        <Button3 name="button3" />
      </Box>

      <Box
        mt={1}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <PanelButtons name="Ongoing Leagues" value={15} />
        <PanelButtons name="Ongoing Leagues" value={15} />
        <PanelButtons name="Ongoing Leagues" value={15} />
        <PanelButtons name="Ongoing Leagues" value={15} />
      </Box>
      <Box
        component={Paper}
        mt={2}
        sx={{
          background:
            " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          borderRadius: "10px",
        }}
        p={4}
      >
        <InputWIthIcon
          inputType="text"
          inputName="new"
          inputPlaceholder="hey how are you"
          onChangeHandler={() => {}}
        />
        <InputWIthIcon
          inputType="text"
          inputName="new"
          inputPlaceholder="hey how are you"
          startAdornment={<Mail />}
          onChangeHandler={() => {}}
        />
        <InputWIthIcon
          inputType="text"
          inputName="new"
          inputPlaceholder="hey how are you"
          endAdornment={<Mail />}
          onChangeHandler={() => {}}
        />
        <SingleInputWithLabels
          labelText={"Label Name"}
          inputType={"text"}
          inputName={"eventName"}
          labelInputId={"eventName"}
          inputValue={""}
          onChangeHandler={() => {}}
          // requiredTrue={true}
          helperText={"Number of matches every team will be offered to play.*"}
          placeholderText={"Enter A Value Here"}
        />
         <SingleInputWithLabels2
          labelText={"Label Name"}
          inputType={"text"}
          inputName={"eventName"}
          labelInputId={"eventName"}
          // inputValue={""}
          onChangeHandler={() => {}}
          // requiredTrue={true}
          helperText={"Number of matches every team will be offered to play.*"}
          placeholderText={"Enter A Value Here"}
        />
        <SingleInputWithLabels
          labelText={"Label Name"}
          inputType={"text"}
          inputName={"eventName"}
          labelInputId={"eventName"}
          inputValue={""}
          onChangeHandler={() => {}}
          // requiredTrue={true}
          // helperText={"Number of matches every team will be offered to play.*"}
          placeholderText={"Enter A Value Here"}
        />
        <SingleInputWithLabels
          labelText={"Label Name"}
          inputType={"textarea"}
          inputName={"eventName"}
          labelInputId={"eventName"}
          inputValue={""}
          rows={4}
          onChangeHandler={() => {}}
          // requiredTrue={true}
          // helperText={"Number of matches every team will be offered to play.*"}
          placeholderText={"Enter A Value Here"}
        />
        <SingleInputWithLabels
          labelText={"Label Name"}
          inputType={"textarea"}
          inputName={"eventName"}
          labelInputId={"eventName"}
          inputValue={""}
          rows={3}
          onChangeHandler={() => {}}
          // requiredTrue={true}
          helperText={"Number of matches every team will be offered to play.*"}
          placeholderText={"Enter A Value Here"}
        />
        <SingleInputWithLabels
          labelText={"Label Name"}
          inputType={"select"}
          inputName={"eventName"}
          labelInputId={"eventName"}
          inputValue={values?.selectedValue}
          onChangeHandler={(e) => {
            setValues({ ...values, selectedValue: e.target.value });
          }}
          // requiredTrue={true}
          options={["item1", "item2", "item3", "item3"]}
          initialValue={"select"}
          helperText={"Number of matches every team will be offered to play.*"}
          placeholderText={"Enter A Value Here"}
        />

        <SingleInputWithLabels
          labelText={"Label Name"}
          inputType={"select"}
          inputName={"eventName"}
          labelInputId={"eventName"}
          inputValue={values?.selectedValue}
          onChangeHandler={(e) => {
            setValues({ ...values, selectedValue: e.target.value });
          }}
          // requiredTrue={true}
          options={["item1", "item2", "item3", "item3"]}
          initialValue={"select"}
          // helperText={"Number of matches every team will be offered to play.*"}
          placeholderText={"Enter A Value Here"}
        />
        <SingleInputWithLabels
          labelText={"Label Name"}
          inputType={"date"}
          inputName={"eventName"}
          labelInputId={"eventName"}
          inputValue={values?.date}
          onChangeHandler={(e) => {
            setValues({ ...values, date: e.target.value });
          }}
          // requiredTrue={true}
          // helperText={"Number of matches every team will be offered to play.*"}
          placeholderText={"Enter A Value Here"}
        />
      </Box>
    </>
  );
};

export default ThemeComponents;

import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { NavLink, useNavigate } from "react-router-dom";
const PanelButtons = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        component={NavLink}
        to={props?.path}
        onClick={props?.path ? () => {} : () => navigate(props?.path)}
        sx={{
          ...props?.sx,
          color: "white",
          bgcolor: "red",
          borderRadius: "15px",
          minWidth: "150px",
          textAlign: "center",
          fontFamily: "Dosis",
          py: 2,
          background: "rgb(233,227,227)",
          background:
            " linear-gradient(90deg, rgba(233,227,227,0.3) 45%, rgba(16,17,16,0.9) 95%)",
          transition: "all 0.5s ease",
          "&:hover": {
            background:
              " linear-gradient(90deg, rgba(233,227,227,0.2) 38%, rgba(16,17,16,0.9) 90%)",
              color:"green !important"
          },
          border: "1px solid rgb(217 207 207 / 22%)",
          display: "flex",
          justifyContent: "center",
          px: 2,
          alignItems: "center",
          cursor: "pointer",
         
        }}
      >
        <Typography
          //   mr={1}
          variant="h5"
          fontWeight={"200"}
          fontFamily={"Montserrat"}
        >
          {props.name}
        </Typography>
        &nbsp;
        <Typography
          component={"span"}
          sx={{ color: "#6FBE44", fontWeight: "bolder", fontSize: "28px" }}
        >
          {props?.value}
        </Typography>
      </Button>
    </>
  );
};

export default PanelButtons;

import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoading } from "../../redux/action/defaultActions";
import dayjs from "dayjs";

const AllMatchesScoreUpdateHomeScreen = () => {
  const [all_liveMatches, setAll_liveMatches] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllMatches = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/active/leagues/live/matches`);
      console.log("res==>", res);
      setAll_liveMatches(res?.data?.leaguesMatches);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getAllMatches();
  }, []);

  return (
    <>
      <Box width={"100%"}>
        <Stack>Select Match And Update Score</Stack>
        <Grid container>
          {all_liveMatches ? (
            all_liveMatches?.map((live_match, index) => {
              return (
                <Grid
                  key={index}
                  item
                  mt={1}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{ position: "relative" }}
                >
                  <Card
                    onClick={() =>
                      navigate(
                        `/active/leagues/update-team-scrores/${live_match?.leagueId}`,
                        { state: { matchInfo: live_match } }
                      )
                    }
                    sx={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      mt: 2,
                      border: "1px solid rgb(217 207 206 / 15%)",
                      minWidth: 280,
                      maxWidth: 320,
                      background:
                        " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                      "&:hover": {
                        background:
                          " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 100%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 25%)",
                      },
                    }}
                  >
                    <CardContent>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Avatar
                            // style={{ height: "40px", width: "40px" }}
                            alt={live_match?.matches?.teamA?.teamName}
                            src={"/assets/images/club1.png"}
                          />
                          <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight={"200"}
                          >
                            {live_match?.matches?.teamA?.teamName}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="h6"
                          fontWeight={"200"}
                          fontSize={"15px"}
                          sx={{ color: "rgb(67, 191, 55)" }}
                          component={"span"}
                        >
                          vs
                        </Typography>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Avatar
                            // style={{ height: "40px", width: "40px" }}
                            alt={live_match?.matches?.teamB?.teamName}
                            src={"/assets/images/club1.png"}
                          />
                          <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight={"200"}
                          >
                            {live_match?.matches?.teamB?.teamName}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Box
                        p={0.5}
                        border={"1px solid grey"}
                        borderRadius={"15px"}
                        px={2}
                        sx={{
                          position: "absolute",
                          top: "2px",
                          left: "12px",
                          zIndex: 22,
                          background: "rgba(46, 46, 46, 1)",
                          // background:
                          //   " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                        }}
                      >
                        <Stack direction={"row"}>
                          <Typography sx={{ color: "white", fontSize: "12px" }}>
                            {dayjs(live_match?.matches?.teamMatchDate).format(
                              "DD MMM YYYY"
                            )}{" "}
                            &nbsp;&nbsp;
                            <span
                              style={{
                                color: "rgb(67, 191, 55)",
                                // marginRight: "1rem",
                                // marginLeft: "1rem",
                              }}
                            >
                              {dayjs(
                                live_match?.matches?.teamMatchTiming
                              ).format("hh:mm A")}
                            </span>
                          </Typography>
                        </Stack>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <Box width={"100%"} height={"100%"} >
              <Typography
              mt={3}
                variant="h3"
                sx={{ fontFamily: "Dosis", textAlign: "center" }}
              >
                OOPS SORRY NO MATCHES SCHEDULED TODAY
              </Typography>
              <Box mt={2} width={"100%"}  display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Box
                  component={"img"}
                  alt="sorry"
                  width={"250px"}
                  src="/assets/images/bowling-breaks.png"
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default AllMatchesScoreUpdateHomeScreen;

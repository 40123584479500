import { CenterFocusStrong } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box, color, margin } from "@mui/system";
import dayjs from "dayjs";
import React from "react";
import { useState } from "react";
import Toggler from "./Toggler";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { useEffect } from "react";

const BusinessHours = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editData, setEditData] = useState(false);
  const [data, setData] = useState();
  const weekday = [
    { weekDay: "Monday", openingTime: "", closingTime: "", open: false },
    { weekDay: "Tuesday", openingTime: "", closingTime: "", open: false },
    { weekDay: "Wednesday", openingTime: "", closingTime: "", open: false },
    { weekDay: "Thursday", openingTime: "", closingTime: "", open: false },
    { weekDay: "Friday", openingTime: "", closingTime: "", open: false },
    { weekDay: "Saturday", openingTime: "", closingTime: "", open: false },
    { weekDay: "Sunday", openingTime: "", closingTime: "", open: false },
  ];
  const [operationTime, setOperationTime] = useState(weekday);
  const handleChange = (e, index) => {
    setOperationTime((optime) => {
      return optime.map((feature, index1) => {
        if (index1 === index) {
          return (feature = {
            ...optime[index],
            [e.target.name]: e.target.value,
          });
        }
        return feature;
      });
    });
    setEditData(true);
  };
  function handletoggleOpenClose(val, index) {
    // console.log("responses====>", operationTime[index], index);
    let temp = operationTime;
    if (temp[index].open) {
      temp[index] = {
        ...val,
        openingTime: "",
        closingTime: "",
        open: false,
      };
    } else {
      temp[index] = { ...val, open: true };
    }
    setOperationTime([...temp]);
    // console.log("temp==>", temp);
  }
  const handleUpdate = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.put(`api/v1/alley/timing/update`, {
        operatingTime: [...operationTime],
      });
      dispatch(isLoading(false));
      // console.log("update==>", res);
    } catch (error) {
      console.log(error.message);
      dispatch(isLoading(false));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`/api/v1/alley/create/timing`, {
        operatingTime: [...operationTime],
      });
      console.log(res);
      if (res.data.success === true) {
        navigate("/");
        dispatch(isLoading(false));
        dispatch(
          openSnackbar("Business Hours Created Successfully", "success")
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/alley/working/hours`);
      setData(res);
      dispatch(isLoading(false));
      let temp = [];
      if (res?.data?.alleyWorkingHours?.operatingTime.length > 0) {
        res?.data?.alleyWorkingHours?.operatingTime.forEach((element) => {
          temp.push({
            weekDay: element?.weekDay,
            openingTime:
              element?.openingTime === "closed" ? "" : element?.openingTime,
            closingTime:
              element?.closingTime === "closed" ? "" : element?.closingTime,
            open:
              element?.openingTime === "closed" ||
              element?.closingTime === "closed"
                ? false
                : true,
          });
        });
        setOperationTime([...temp]);
      } else {
        setOperationTime([...weekday]);
      }
      console.log("getdata==>", res.data.alleyWorkingHours.operatingTime);
    } catch (error) {
      console.log(error.message);
      dispatch(isLoading(false));
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box width={"100%"}>
      <Box component={Paper} p={1.5}>
        <form onSubmit={handleSubmit}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h5" fontFamily={"Dosis"}>
              Business Hours
            </Typography>
            {editData ? (
              <Button
                type="submit"
                variant="h6"
                fontFamily={"Dosis"}
                // onClick={handleUpdate}
              >
                Update
              </Button>
            ) : (
              ""
            )}
          </Stack>
          {operationTime?.map((days, index) => {
            return (
              <Grid
                key={index}
                container
                justifyContent={"space-between"}
                spacing={0.25}
              >
                <Grid
                  mt={2}
                  xs={12}
                  md={3.8}
                  display={"flex"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    mt={1}
                    variant="body2"
                    border={"2px solid green"}
                    width={"60%"}
                    component={"label"}
                    sx={{
                      textAlign: "center",
                      p: 1,
                      borderRadius: "10px",
                      backgroundColor: days?.open
                        ? "green !important"
                        : "transparent",
                    }}
                  >
                    {editData ? days?.weekDay : days?.weekDay}
                  </Typography>
                  <Box mt={1}>
                    <Toggler
                      handletoggleOpenClose={handletoggleOpenClose}
                      days={days}
                      // editData={editData}
                      index={index}
                    />
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  md={3.8}
                  display={"flex"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mt={2}
                >
                  <Typography mt={1}>Open At:</Typography>
                  <Box
                    mt={1}
                    component={"input"}
                    id={`opening${index}`}
                    width={"80%"}
                    name="openingTime"
                    onChange={(e, v) => {
                      handleChange(e, index);
                      console.log(e.target.value);
                    }}
                    // onInput={(e) => handleChange(e, index)}
                    disabled={!days?.open}
                    value={days?.openingTime}
                    required
                    type="time"
                    sx={{
                      background: "transparent",
                      border: "0.5px solid grey !important",
                      borderRadius: "5px",
                      p: 0.2,
                      color: "white",
                      "&::-webkit-calendar-picker-indicator": {
                        filter:
                          "invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xs={12}
                  md={3.8}
                  display={"flex"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mt={2}
                >
                  <Typography mt={1}>Close At:</Typography>
                  <Box
                    mt={1}
                    component={"input"}
                    id={`closing${index}`}
                    width={"80%"}
                    type="time"
                    name="closingTime"
                    value={days?.closingTime}
                    onChange={(e) => handleChange(e, index)}
                    disabled={!days?.open}
                    required
                    sx={{
                      background: "transparent",
                      border: "0.5px solid grey !important",
                      borderRadius: "5px",
                      p: 0.2,
                      color: "white",
                      "&::-webkit-calendar-picker-indicator": {
                        filter:
                          "invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)",
                      },
                      "& .disabled": {
                        borderColor: "red !important",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </form>
      </Box>
    </Box>
  );
};

export default BusinessHours;

import { ActionTypes } from "../constants/actionTypes";
const initialState = {
  isAuthenticated: false,
  isUser: false,
  firstTimeLogin: false,
};
export const admin_reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADMIN_LOGIN:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isUser: true,
      };
    case ActionTypes.ADMIN_AUTH:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isUser: true,
      };
    case ActionTypes.ADMIN_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export const wallet_reducer = (state = 0, { type, payload }) => {
  switch (type) {
    case ActionTypes.UPDATE_WALLET_BALANCE:
      return payload;

    default:
      return state;
  }
};

export const profileData_reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PROFILE_DATA:
      return { ...payload };
    default:
      return state;
  }
};

import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Url } from "../../url";
import { Container, Box, Typography, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const [privacyPolicyData, setprivacyPolicyData] = useState("");

  const getPrivacyPolicyData = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get("/api/v1/admin/privacypolicy");
      console.log("res==>", res);
      setprivacyPolicyData(res.data.privacyPolicy);
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getPrivacyPolicyData();
  }, []);

  return (
    <>
      <Container>
        {/* <Avatar src="./" /> */}
        <Stack>
          <Box
            component={"img"}
            height={"53px"}
            src="/assets/logo/Vector.svg"
          />
          <Typography
            variant="h4"
            mt={1}
            textAlign={"center"}
            fontWeight={"600"}
            letterSpacing={"1.5px"}
            // fontSize={"25px"}
            fontFamily={"Dosis"}
          >
            Pocket League
          </Typography>
          <Typography
            mt={1}
            textAlign={"center"}
            fontWeight={"300"}
            letterSpacing={"2px"}
            fontSize={"18px"}
            fontFamily={"Dosis"}
            color={"rgba(111, 190, 68, 1)"}
          >
            Unite Through Gaming
          </Typography>
        </Stack>

        {privacyPolicyData !== "" ? (
          <Box
            sx={{
              mt: 0.5,
              border: "5px solid #27823B",
              borderStyle: "offset",
              p: 2,
              mb: 0.5,
              "& > :first-child": {
                color: "#27823B",
                textAlign: "center",
                mb: 1,
                textTransform: "uppercase",
                fontWeight: "600",
                //   textDecoration: "underline",
              },
              "& > h1,h2,h3,h4,h5,h6": {
                color: "gray",
                //   textDecoration: "underline",
                textTransform: "capitalize",
              },
              "& > p,li,ul": {
                listStyleType: "none",
              },
              "& >p,li,ul": {
                textAlign: "left",
              },
            }}
            dangerouslySetInnerHTML={{
              __html: privacyPolicyData,
            }}
          />
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

export default PrivacyPolicy;

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import SingleInputWithLabels from "../../reusablecomponents/formcontrols/SingleInputWithLabels";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import AddIcon from "@mui/icons-material/Add";

const LeaguesCard = () => {
  const formdata = new FormData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [fileName, setfileName] = useState("");
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [valData, setValData] = useState({
    v1: {},
    v2: {},
  });

  // ************************************************************
  const handleChange = (e) => {
    console.log(e.target.files[0]);
    console.log("fileName==>", e.target.files[0].name);
    setfileName(e.target.files[0].name);
    setFile(URL.createObjectURL(e.target.files[0]));

    setFileData(e.target.files[0]);
  };

  // *************************************************************
  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(isLoading(true));
    console.log("===>", formdata);
    console.log(values);
    for (const key in values) {
      formdata.append(key, values[key]);
    }
    formdata.append("file", fileData);

    try {
      const response = await axios.post(`/api/v1/league/new/league`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success === true) {
        dispatch(isLoading(false));
        dispatch(openSnackbar("League has been created", "success"));
        navigate("/");
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error?.response?.data?.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }

      dispatch(isLoading(false));
    }
  };
  return (
    <Card sx={{ minWidth: 275, pb: 3, borderRadius: "10px" }}>
      <Stack
        borderRadius={"10px 10px 0px 0px"}
        bgcolor={"#333333"}
        p={1}
        px={2}
        direction={"row"}
        fontFamily={"Dosis"}
        justifyContent={"space-between"}
      >
        <Typography fontFamily={"Dosis"} variant="h6">
          Create New League
        </Typography>
       
      </Stack>
      <CardContent sx={{ p: 2 }}>
        <form onSubmit={handleSubmit}>
          <SingleInputWithLabels
            labelText={"Lague Name"}
            inputType={"text"}
            inputName={"leagueName"}
            labelInputId={"leagueName"}
            inputValue={values?.leagueName || ""}
            onChangeHandler={(e) => {
              setValues({ ...values, leagueName: e.target.value });
            }}
            requiredTrue={true}
          
            placeholderText={"Enter the name of league"}
          />
          <SingleInputWithLabels
            labelText={"Description"}
            inputType={"text"}
            inputName={"description"}
            labelInputId={"description"}
            inputValue={values?.description || ""}
            rows={3}
            onChangeHandler={(e) => {
              setValues({ ...values, description: e.target.value });
            }}
            requiredTrue={true}
            placeholderText={"Enter the team description"}
          />
          <SingleInputWithLabels
            labelText={"Type of League"}
            inputType={"select"}
            inputName={"typeOfLeague"}
            labelInputId={"typeOfLeague"}
            inputValue={values?.typeOfLeague}
            onChangeHandler={(e) => {
              setValues({ ...values, typeOfLeague: e.target.value });
            }}
            requiredTrue={true}
            options={["duo", "quad"]}
            initialValue={"select"}
            helperText={"Type / format of the league.*"}
            placeholderText={"Select A Value Here"}
          />
          <SingleInputWithLabels
            labelText={"Total No of Teams"}
            inputType={"number"}
            inputName={"numberOfTeam"}
            labelInputId={"noOfTeam"}
            inputValue={values?.numberOfTeam}
            onChangeHandler={(e) => {
              setValues({ ...values, numberOfTeam: e.target.value });
            }}
            requiredTrue={true}
            helperText={"Number of teams will participate in the league.*"}
            placeholderText={"Enter number of teams"}
          />
          <SingleInputWithLabels
            labelText={"Match Per Team"}
            inputType={"number"}
            inputName={"matchPerTeam"}
            labelInputId={"noOfTeam"}
            inputValue={values?.matchPerTeam}
            onChangeHandler={(e) => {
              setValues({ ...values, matchPerTeam: e.target.value });
            }}
            requiredTrue={true}
            helperText={
              "Number of matches every team will be offered to play.*"
            }
            placeholderText={"Enter Number Of Mathches Team Will Play"}
          />
          <SingleInputWithLabels
            labelText={"Percentage Factor"}
            inputType={"number"}
            inputName={"percentageFactor"}
            labelInputId={"percentageFactor"}
            inputValue={values?.percentageFactor}
            onChangeHandler={(e) => {
              setValues({ ...values, percentageFactor: e.target.value });
            }}
            requiredTrue={true}
            helperText={"This field define critaria For Handicapped Scores*"}
            placeholderText={"Enter Percentage Factor in (%)"}
          />
          <SingleInputWithLabels
            labelText={"Basis Score"}
            inputType={"number"}
            inputName={"basisScore"}
            labelInputId={"basisScore"}
            inputValue={values?.basisScore}
            onChangeHandler={(e) => {
              setValues({ ...values, basisScore: e.target.value });
            }}
            requiredTrue={true}
            helperText={"It defines basic weightage for handicapped score*"}
            placeholderText={"Enter Basis Factor"}
          />
          <SingleInputWithLabels
            labelText={"Intervals Of Days"}
            inputType={"number"}
            inputName={"intervalsOfDays"}
            labelInputId={"intervalsOfDays"}
            inputValue={values?.intervalsOfDays}
            placeholderText={"Select A Value Here"}
            onChangeHandler={(e) => {
              setValues({ ...values, intervalsOfDays: e.target.value });
            }}
            requiredTrue={true}
            helperText={"Days interval of the match.*"}
          />
          <SingleInputWithLabels
            labelText={"Starting Date"}
            inputType={"date"}
            inputName={"startDate"}
            labelInputId={"startDate"}
            inputValue={values?.startDate}
            onChangeHandler={(e) => {
              setValues({ ...values, startDate: e.target.value });
            }}
            requiredTrue={true}
            helperText={"Starting date of the league."}
            placeholderText={"Select A Date Here"}
          />
          <SingleInputWithLabels
            labelText={"Timing"}
            inputType={"time"}
            inputName={"startTiming"}
            labelInputId={"startTiming"}
            inputValue={values?.startTiming}
            onChangeHandler={(e) => {
              setValues({ ...values, startTiming: e.target.value });
            }}
            requiredTrue={true}
            helperText={"Time of the league."}
            placeholderText={"Enter the timing Here"}
          />
          <SingleInputWithLabels
            labelText={"Total League Fee"}
            inputType={"number"}
            inputName={"leagueFee"}
            labelInputId={"leagueFee"}
            inputValue={values?.leagueFee || ""}
            onChangeHandler={(e) => {
              setValues({ ...values, leagueFee: e.target.value });
            }}
            requiredTrue={true}
            placeholderText={"Enter the total amount of league fee"}
          />
          <SingleInputWithLabels
            labelText={"Prize Pool"}
            inputType={"text"}
            inputName={"prizePool"}
            labelInputId={"prizePool"}
            inputValue={values?.prizePool || ""}
            onChangeHandler={(e) => {
              setValues({ ...values, prizePool: e.target.value });
            }}
            requiredTrue={true}
            placeholderText={"Enter the pool prize"}
          />

          <Grid container mt={2} sx={{ borderBottom: "2px solid grey" }}>
            <Grid
              item
              component={"label"}
              htmlFor={"image"}
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              sx={{
                fontFamily: "Dosis",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              Add Image
            </Grid>
            <Grid
              item
             
              xl={5}
              lg={5}
              md={5}
              sm={5}
              xs={12}
              sx={{ paddingBottom: "20px" }}
            >
              <Box
                component={"label"}
                htmlFor="upload-photo"
                sx={{
                  display: "flex",
                  height: "45px",
                  color: "white",
                  fontFamily: "Dosis",
                  cursor: "pointer",
                  alignItems: "center",
                  outline: "none",
                  border: "1px solid rgba(255,255,255,0.5)",
                  borderRadius: "5px",
                  background:
                    " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",
                  paddingX: "10px",
                  fontSize: "15px",
                  "&:focus": { border: "2px solid rgba(255,255,255,0.5)" },
                }}
              >
                {fileName && file ? fileName : "UPLOAD"}

                <input
                  type="file"
                  id="upload-photo"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
          {file ? (
            <>
              <Stack
                direction={{ md: "row", xs: "column" }}
                justifyContent={"start"}
                spacing={1}
                alignItems={"center"}
              >
                <img
                  src={file}
                  alt="logo"
                  accept="image/*"
                  style={{
                    height: "150px",
                    width: "150px",
                    marginTop: "10px",
                  }}
                />
                <Button
                  size="small"
                  variant="text"
                  onClick={() => setFile(null)}
                  sx={{
                    bgcolor: "transparent",
                    "&:hover": { bgcolor: "rgba(0,0,0,0.2)" },
                    maxHeight: "35px",
                  }}
                >
                  Remove
                </Button>
              </Stack>
            </>
          ) : (
            ""
          )}
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              size="small"
              type="submit"
              sx={{
                bgcolor: "rgb(133, 219, 72)",
                color: "#fff",
                fontWeight: "500",

                marginTop: "20px",
              }}
            >
              <AddIcon />
              Create League
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default LeaguesCard;

import React from "react";
import Button from "@mui/material/Button";

const Button3 = (props) => {
  return (
    <>
      {props?.name ? (
        <Button
          size={props?.size ? props?.size : "small"}
          variant="contained"
          type={props?.type ? props?.type : "button"}
          fullWidth={props?.fullWidth?true:false}
          onClick={props?.type === "submit" ? () => {} : () => props?.onClick}
          sx={{
            m: props?.m,
            position: "relative",
            color: "white",
            fontFamily: "Dosis",
            textAlign: "center",
            borderRadius: "20px",
            // width:'fit-content',
            background: "rgb(3,14,1)",
            background:
              "linear-gradient(220deg, rgba(0,0,1,1) 0%, rgba(255,255,255,0.5) 100%)",

            textTransform: props.textTransform
              ? props?.textTransform
              : "capitalize",
            textShadow: "1px 1px 1px black",

            // border: "2px solid rgba(255,255,255,0.2)",
            maxHeight: "50px",
          }}
        >
          <span
            style={{
              position: "absolute",
              // color: "rgba(111, 190, 68, 1)",
              color: "white",
              fontWeight:"300",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.4)",
              // backgroundColor: "red",
              borderRadius: "20px",
              width: "100%",
              height: "100%",
              display: "flex",
              fontSize: props?.fontSize?props?.fontSize:"22px",
              border: "1px solid rgba(255,255,255,0.3)",

              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "10px black",
            }}
          >
            {" "}
            {props?.name}
          </span>
        </Button>
      ) : (
        ""
      )}
    </>
  );
};

export default Button3;

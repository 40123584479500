import * as React from "react";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { Link, Paper, Typography } from "@mui/material";

import { Stack, borderRadius } from "@mui/system";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  isLoading,
  setTempSideMenu,
} from "../../../redux/action/defaultActions";
import axios from "axios";

const ActiveLeagueMainPage = () => {
  const dispatch = useDispatch();
  const { leagueId } = useParams();
  const [data, setData] = React.useState([]);
  const upcomingLeaguePageUrls = [
    // { name: "Overview", url: `/active/leagues/${leagueId}/` },
    { name: "Teams", url: `/active/leagues/${leagueId}/` },
    { name: "Matches", url: `/active/leagues/${leagueId}/matches` },
    // { name: "Table", url: `/active/leagues/${leagueId}/table` },
    { name: "Details", url: `/active/leagues/${leagueId}/details` },
  ];
  // { name: "Matches", url: "/upcomingMatches" },{ name: "Matches", url: "/pastMatches" }
  const navigate = useNavigate();
  const getApi = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `/api/v1/league/alley/single/league/${leagueId}`
      );
      setData(res?.data?.alleyLeague);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  React.useEffect(() => {
    getApi();
    dispatch(setTempSideMenu(`/active/leagues/${leagueId}/`, "active"));

    return () => {
      dispatch(setTempSideMenu("", ""));
    };
  }, []);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          borderRadius={"5px"}
          p={1}
          py={2}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={3} p={1}>
              <img
                className="headImg"
                style={{
                  width: "100%",
                  maxWidth: "250px",
                  minHeight: "7rem",
                  paddingLeft: "1rem",
                }}
                src={data?.leagueImages?.url}
                alt="Active-league"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Stack>
                <Typography variant={{ xs: "h6", sm: "h5" }}>
                  {data?.leagueName}
                </Typography>
                <Typography variant="body2" component={"p"}>
                  {data?.description}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Stack overflow={"auto"}>
            <Box component={"nav"}>
              {upcomingLeaguePageUrls?.map((page, index) => {
                return (
                  <Typography
                    component={NavLink}
                    key={index}
                    to={page?.url}
                    // onClick={() => navigate(page?.url)}
                    fontFamily={"Dosis"}
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "white",
                      "&.active": {
                        color: "rgb(67, 191, 55)",
                        textDecoration: "5px underline",
                        textDecorationColor: "green",
                      },
                      "&:hover": {
                        textDecoration: "5px underline",
                        textDecorationColor: "green",
                        color: "rgb(67, 191, 55)",
                      },
                    }}
                    className="link mx-2"
                    // href="#"
                    underline="hover"
                  >
                    {page?.name}
                  </Typography>
                );
              })}
            </Box>
          </Stack>
        </Box>
        <Outlet />
      </Box>
    </>
  );
};

export default ActiveLeagueMainPage;

import { Button, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import TeamScoreCard from "./TeamScoreCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading } from "../../redux/action/defaultActions";
import axios from "axios";

const UpdateTeamScores = () => {
  const { leagueId } = useParams();
  const { state } = useLocation();
  // console.log("state==>", state.matchInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [live_matchTeamScoreTableInfo, setLive_matchTeamScoreTableInfo] =
    useState(null);

  const getLiveMatchTeamsScoreSquadInfo = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.put(
        `/api/v1/league/single/day/match/score/${leagueId}`,
        {
          teamA: state?.matchInfo?.matches?.teamA?._id,
          teamB: state?.matchInfo?.matches?.teamB?._id,
          matchDate: state?.matchInfo?.matches?.teamMatchDate,
        }
      );
      console.log("response==>", res?.data?.matchData);
      setLive_matchTeamScoreTableInfo(res?.data?.matchData);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    if (state) {
      getLiveMatchTeamsScoreSquadInfo();
    }
  }, []);

  return (
    <Box width={"100%"}>
      <Stack
        mt={1}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">
          League : {live_matchTeamScoreTableInfo?.leagueName}{" "}
        </Typography>
        <Typography variant="body2" component={"caption"}>
          Type : {live_matchTeamScoreTableInfo?.teamSize}{" "}
        </Typography>
      </Stack>
      <Grid
        container
        mt={1}
        spacing={2}
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        {live_matchTeamScoreTableInfo?.leaguesMatches?.map(
          (teamData, index) => {
            return (
              <Grid item key={index} xs={12} md={12} xl={6}>
                <TeamScoreCard
                  data={teamData}
                  matchDate={state?.matchInfo?.matches?.teamMatchDate}
                  bsScore={live_matchTeamScoreTableInfo?.basisScore}
                  pFactor={live_matchTeamScoreTableInfo?.percentageFactor}
                  getLiveMatchTeamsScoreSquadInfo={
                    getLiveMatchTeamsScoreSquadInfo
                  }
                />
              </Grid>
            );
          }
        )}
      </Grid>
      <Stack mt={2}>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(
              `/active/leagues/update-team-scrores/match-summary/${leagueId}`,
              { state: { matchInfo: state?.matchInfo } }
            )
          }
          sx={{ border: "0.5px solid grey", backgroundColor: "transparent" }}
        >
          Match summary
        </Button>
      </Stack>
    </Box>
  );
};

export default UpdateTeamScores;

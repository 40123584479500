import * as React from "react";
// import './upcomingMatches.css';

import Box from "@mui/material/Box";

import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";

export default function Matches() {
  const { leagueId } = useParams();
  const matchesPageUrls = [
    { name: "Live Match", url: `/active/leagues/${leagueId}/matches/` },
    {
      name: "Upcoming Matches",
      url: `/active/leagues/${leagueId}/matches/upcoming-matches`,
    },
    {
      name: "Past Matches",
      url: `/active/leagues/${leagueId}/matches/past-matches`,
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Stack spacing={3} justifyContent={"center"}>
        <Box
          borderRadius={"5px"}
          p={2}
          component={Paper}
          py={2}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Stack display={"flex"} flexDirection={"row"} component={"nav"}>
            {matchesPageUrls?.map((page, index) => {
              return (
                <Box
                  component={NavLink}
                  key={index}
                  to={page.url}
                  sx={{
                    py: 0.25,
                    px: 1,
                    fontFamily: "Dosis",
                    background:
                      " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                    border: "1px solid grey",
                    borderRadius: "50px",
                    marginRight: "10px",
                    textDecoration: "none",
                    color: "white",
                    "&:hover": {
                      textDecoration: "5px",
                      color: "rgb(67, 191, 55)",
                      background:
                        " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                    },
                    "&.active": {
                      textDecoration: "5px",
                      color: "rgb(67, 191, 55)",
                      background:
                        " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                    },
                  }}
                >
                  {page?.name}
                </Box>
              );
            })}
          </Stack>
        </Box>
        <Outlet />
      </Stack>
    </>
  );
}

// import React from 'react'
import * as React from "react";
// import './table.css';

import Box from "@mui/material/Box";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import { Table } from "@mui/material";

function createData(SNo, Image, Team, Type, HCP, AVE, ABS) {
  return { SNo, Image, Team, Type, HCP, AVE, ABS };
}

const rows = [
  createData(
    1,
    "/assets/images/club1.png",
    "Team Almeria",
    "Regular",
    32,
    184,
    174
  ),
  createData(
    2,
    "/assets/images/club2.png",
    "Atletico Madrid",
    "Regular",
    32,
    184,
    174
  ),
  createData(3, "/assets/images/club3.png", "Cadiz", "Regular", 32, 184, 174),
  createData(
    4,
    "/assets/images/Rectangle n1.png",
    "Manchester United",
    "Regular",
    32,
    184,
    174
  ),
  createData(
    5,
    "/assets/images/Rectangle n2.png",
    "Real Betis",
    "Regular",
    32,
    184,
    174
  ),
];

const TableActiveLeague = () => {
  return (
    <Stack spacing={3} justifyContent={"center"}>
      <Box
        borderRadius={"5px"}
        p={2}
        component={Paper}
        py={2}
        // sx={{
        //   background:
        //     " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
        // }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="left">Team</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">HCP&nbsp;(g)</TableCell>
                <TableCell align="left">AVE&nbsp;(g)</TableCell>
                <TableCell align="left">ABS&nbsp;(g)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.SNo}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.SNo}
                  </TableCell>
                  <TableCell align="left">
                    <img
                      style={{
                        width: "2rem",
                        height: "2rem",
                        marginRight: "1rem",
                      }}
                      src={row.Image}
                      alt=""
                    />
                    {row.Team}
                  </TableCell>
                  <TableCell align="left">{row.Type}</TableCell>
                  <TableCell align="left">{row.HCP}</TableCell>
                  <TableCell align="left">{row.AVE}</TableCell>
                  <TableCell align="left">{row.ABS}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

export default TableActiveLeague;

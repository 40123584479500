import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const ResultPage = () => {
  return (
   <>
   <Box>
    <Typography>Result</Typography>
   </Box>
   
   </>
  )
}

export default ResultPage

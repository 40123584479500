import { Grid, List, ListItem, Typography, Link, Avatar } from "@mui/material";
import React from "react";
import Pagination from "@mui/material/Pagination";
// import Stack from '@mui/material/Stack';
import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";

function createData(SNo, Image, Team, Type, HCP, AVE, ABS) {
  return { SNo, Image, Team, Type, HCP, AVE, ABS };
}



const Teams = ({ teamData }) => {
  console.log("team==>", teamData);
  return (
    <Stack spacing={3} justifyContent={"center"}>
      <Box
        borderRadius={"5px"}
        p={2}
        component={Paper}
        py={2}
        // sx={{
        //   background:
        //     " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
        // }}
      >
        <TableContainer component={Paper}>
          {teamData?.length > 0 ? (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="left">Team</TableCell>
                  <TableCell align="left">Team Size</TableCell>
                  <TableCell align="left">Player 1</TableCell>
                  <TableCell align="left">Player 2</TableCell>
                  {teamData[0]?.teamSize === "quad" ? (
                    <>
                      <TableCell align="left">Player 3</TableCell>
                      <TableCell align="left">Player 4</TableCell>
                    </>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {teamData?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        direction: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <img
                      style={{
                        width: "2rem",
                        height: "2rem",
                        marginRight: "1rem",
                      }}
                      src={row.Image}
                      alt=""
                    /> */}
                      <Avatar src={"/asset/images"} alt={row?.teamName} />
                      &nbsp; &nbsp;
                      {row?.teamName}
                      {/* {row.Team} */}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {row?.teamSize}
                    </TableCell>
                    {row?.teamMember?.map((member, i) => {
                      return (
                        <TableCell
                          key={i}
                          align="left"
                        >{`${member?.firstName} ${member?.lastName}`}</TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            "No Teams Joined"
          )}
        </TableContainer>
        {/* <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Pagination count={10} size="small" />
        </Stack> */}
      </Box>
    </Stack>
  );
};

export default Teams;

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
export default function AllPlayersTable() {
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState();
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ********************Get All Players Details function********************
  const getData = async () => {
    dispatch(isLoading(true));
    try {
      dispatch(isLoading(false));
      const res = await axios.get(`/api/v1/league/alley/all/users/linked`);
      // console.log(res?.data?.users);
      setData(res?.data?.users);
    } catch (error) {
      dispatch(isLoading(false));
      dispatch(openSnackbar("something went wrong", "error"));
      console.log(error.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box width={"100%"}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 340 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ bgcolor: "gray" }}>
              <TableCell
                colSpan={7}
                sx={{ bgcolor: "#4D4C4C", fontSize: "25px" }}
              >
                Players
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                color: "#242424",
              }}
            >
              <TableCell>#</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Player's Name</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left">Average</TableCell>
              <TableCell align="left">Leagues</TableCell>
              {/* <TableCell align="left">Active</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              background:
                " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
            }}
          >
            {data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  color: "white",
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">
                  <img
                    style={{ maxHeight: "3rem", maxWidth: "3rem" }}
                    src={row?.profilePicture?.url}
                    alt="ProfilePic"
                  />
                </TableCell>
                <TableCell align="left">{row?.userName}</TableCell>
                <TableCell align="left">{row?.totalScore}</TableCell>
                <TableCell align="left">{row.avgScore}</TableCell>
                <TableCell align="left">
                  <Link
                    onClick={() => navigate("/players/leagues-by-players")}
                    sx={{
                      color: "blue",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    {row?.noOfLeagues}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

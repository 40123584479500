export const ActionTypes = {
  ADMIN_LOGIN: "ADMIN_LOGIN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  ADMIN_AUTH: "ADMIN_AUTH",

  OPEN_SNACKBAR: "OPEN_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",

  SET_ISLOADING: "SET_OPEN_ISLOADING",

  SET_TEMP_PATH: "SET_TEMP_PATH",

  UPDATE_WALLET_BALANCE: "UPDATE_WALLET_BALANCE",

  SET_PROFILE_DATA: "SET_PROFILE_DATA",
};

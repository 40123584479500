import { Box } from "@mui/system";
import React from "react";
import OtpCart from "./OptCart";
import { Link, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

const OtpVerification = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate("/login");
  };
  return (
    <Box
      height={"100vh"}
      sx={{
        backgroundImage: "url(/assets/background/background.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      width={"100%"}
      //   bgcolor={"black"}
      color={"white"}
    >
      <Box width={{ md: "30%", xs: "90%", sm: "50%", lg: "29%" }}>
        <OtpCart />
        <Typography
        
          sx={{
            width: "96%",
            textAlign: "center",
            display: "block",
            color: "white",
            marginTop: "1rem",
          }}
        >
          Already have an account ? &nbsp;{" "}
          <Link
            component={NavLink}
            to="/login"
            onClick={handleNav}
            sx={{ color: "blue" }}
          >
            Login
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default OtpVerification;

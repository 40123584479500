import React, { useState } from "react";

import { Box, Stack } from "@mui/system";
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Url } from "../../url";
import { useEffect } from "react";
import { ImPencil } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
const ProfilePicUpload = ({
  profilePicture,
  editMode,
  alleyData,
  seteditemode,
  getData
}) => {
  const { profileData } = useSelector((state) => state);
  const [accountValues, setAccountValues] = useState({});
  const [displayImg, setDisplayImg] = useState("");
  const [fileUrl, setfileUrl] = useState("");
  const dispatch = useDispatch();
  // =================

  const [changeProfile, setChangeProfile] = useState(false);
  const [file, setfile] = useState(null);
  // =========handlefilechange =======
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setfile(e.target.files[0]);
      setfileUrl(URL.createObjectURL(file));
      setChangeProfile(true);
    }
  };
  // =================================
  // ===========================================
  const handleUploadProfilePicture = async (event) => {
    event.preventDefault();
    dispatch(isLoading(true));
    const formData = new FormData();
    formData.append("file", file);
    try {
      if (fileUrl) {  const formValues = new FormData();
        formValues.append(
          "alleyName",
          `${accountValues?.firstName} ${accountValues?.lastName}`
        );
        formValues.append("email", accountValues?.email);
        formValues.append(
          "contactNumber",
          profileData?.alleyAccount?.contactNumber
        );
        formValues.append(
          "countryCode",
          profileData?.alleyAccount?.countryCode * 1
        );
        formValues.append("addressLine1", `${accountValues?.addressLine1}`);
        formValues.append("city", `${accountValues?.city}`);
        formValues.append("state", `${accountValues?.state}`);
        formValues.append("zipCode", `${accountValues?.zipCode}`);
        formValues.append("numberOfLane", accountValues?.numberOfLane);

        const res = await axios.put(
          `/api/v1/alley/profile/image/update`,
          formData
        );
        console.log("res==>", res);
        dispatch(isLoading(false));
        seteditemode(false);
        getData();
        // dispatch(openSnackbar("please select a file to upload", "error"));
        // dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar("please select a file to upload", "error"));
        dispatch(isLoading(false));
      }
    } catch (error) {
      dispatch(isLoading(false));
      console.error("Error uploading profile picture:", error);
    }
  };

  useEffect(() => {
    if (profilePicture) {
      setDisplayImg(profilePicture);
    }
    if (profileData) {
      setAccountValues({
        countryCode: profileData?.alleyAccount?.countryCode * 1,
        contactNumber: profileData?.alleyAccount?.contactNumber,
        numberOfLane: profileData?.alleyAccount?.numberOfLane,
        firstName: profileData?.alleyAccount?.alleyName.trim().split(" ")[0],
        lastName: profileData?.alleyAccount?.alleyName.trim().split(" ")[1]
          ? profileData?.alleyAccount?.alleyName.trim().split(" ")[1]
          : "",
        email: profileData?.email,
        city: profileData?.alleyAccount?.city,
        state: profileData?.alleyAccount?.state,
        addressLine1: profileData?.alleyAccount?.addressLine1,
        zipCode: profileData?.alleyAccount?.zipCode,
      });
    }
  }, []);

  useEffect(() => {
    if (editMode === false) {
      setfileUrl("");
    }
  }, [editMode]);

  return (
    <Box>
      <form onSubmit={handleUploadProfilePicture}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          // variant="dot"
          badgeContent={
            editMode ? (
              <Box
                width={"20px"}
                height={"20px"}
                bgcolor={"rgba(111, 190, 68, 1)"}
                sx={{
                  transition: "ease-in-out 300ms",
                  "&:hover": {
                    backgroundColor: "rgba(11, 94, 25, 1)",
                  },
                }}
                borderRadius={"100%"}
                color={"white"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
                fontSize={"10px"}
                htmlFor="icon-button-photo"
                component={"label"}
              >
                <ImPencil />
              </Box>
            ) : (
              ""
            )
          }
        >
          <input
            accept="image/*"
            id="icon-button-photo"
            style={{ display: "none" }}
            onChange={handleFileChange}
            type="file"
          />
          <Avatar
            sx={{
              "&.MuiAvatar-root": {
                height: "100px",
                width: "100px",
                color: "red",
              },
            }}
            alt={alleyData?.userName}
            src={fileUrl ? fileUrl : profilePicture}
          />
        </Badge>
        {fileUrl ? (
          <Stack>
            <Button
              type="submit"
              variant="outlined"
              sx={{
                mt: 1.5,
                p: 0,
                py: 0.5,
                px: 1.5,

                backgroundColor: "transparent",
                transition: "ease-in-out 300ms",
                borderColor: "rgba(11, 94, 25, 1) !important",
                color: "rgba(111, 190, 68, 1) !important",
                maxHeight: "35px",
                "&:hover": {
                  backgroundColor: "rgba(111, 190, 68, 1)",
                  color: "white !important",
                  border: "transparent",
                  outline: "none",
                },
              }}
            >
              UPLOAD
            </Button>
          </Stack>
        ) : (
          ""
        )}
      </form>
    </Box>
  );
};

export default ProfilePicUpload;

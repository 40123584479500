import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";

import React, { useState } from "react";

const SingleInputWithLabels = ({
  labelText,
  inputType,
  inputName,
  inputValue,
  onChangeHandler,
  labelInputId,
  requiredTrue,
  placeholderText,
  helperText,
  rows,
  options,
  initialValue,
}) => {
  const [selectedValue, setSelectedValue] = useState("--");
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    console.log("cahnged=>", e.target.value);
  };
  const today = new Date();
  const minDate = today.toISOString().split("T")[0];
  // console.log("datte=>", minDate);
  if (helperText) {
    return (
      <>
        {inputType !== "textarea" ? (
          <Grid container mt={2}>
            <Grid
              component={"label"}
              htmlFor={labelInputId}
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              sx={{
                fontFamily: "Dosis",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {labelText} {requiredTrue ? "*" : ""}
            </Grid>

            {inputType === "date" ? (
              <Grid
                component={"input"}
                required={requiredTrue}
                type={"date"}
                name={inputName}
                value={inputValue}
                onChange={onChangeHandler}
                id={labelInputId}
                placeholder={placeholderText}
                min={minDate}
                item
                xl={5}
                lg={5}
                md={5}
                sm={5}
                xs={12}
                sx={{
                  height: "45px",
                  color: "white",
                  outline: "none",
                  border: "1px solid rgba(255,255,255,0.5)",
                  borderRadius: "5px",
                  background:
                    " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",
                  paddingX: "10px",
                  fontSize: "18px",
                  "&:focus": { border: "2px solid rgba(255,255,255,0.5)" },
                  "&::-webkit-calendar-picker-indicator": {
                    filter:
                      "invert(2%) sepia(85%) saturate(7399%) hue-rotate(356deg) brightness(280%) contrast(2.5%)",
                  },
                }}
              ></Grid>
            ) : inputType === "select" ? (
              <Grid
                component={Select}
                required={requiredTrue}
                // type={""}
                name={inputName}
                value={inputValue ? inputValue : selectedValue}
                onChange={onChangeHandler}
                id={labelInputId}
                placeholder={placeholderText}
                item
                xl={5}
                lg={5}
                md={5}
                sm={5}
                xs={12}
                sx={{
                  height: "45px",
                  outline: "none",
                  textTransform: "capitalize",
                  border: "1px solid rgba(255,255,255,0.5)",
                  background:
                    " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",

                  borderRadius: "5px",
                  "& fieldset": { border: "none" },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                  paddingX: "10px",
                  fontFamily: "Dosis",
                  color: "white",
                  fontSize: "18px",

                  "&:focus": { border: "2px solid rgba(255,255,255,0.5)" },
                }}
              >
                <MenuItem value={"--"}>{initialValue}</MenuItem>
                {options?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Grid>
            ) : (
              <Grid
                component={"input"}
                required={requiredTrue}
                type={inputType}
                name={inputName}
                value={inputValue}
                onChange={onChangeHandler}
                id={labelInputId}
                placeholder={placeholderText}
                item
                xl={5}
                lg={5}
                md={5}
                sm={5}
                xs={12}
                sx={{
                  height: "45px",
                  outline: "none",
                  border: "1px solid rgba(255,255,255,0.5)",
                  background:
                    " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",

                  borderRadius: "5px",
                  paddingX: "10px",
                  fontFamily: "Dosis",
                  color: "white",
                  fontSize: "18px",
                  "&:focus": { border: "2px solid rgba(255,255,255,0.5)" },
                  "&::-webkit-calendar-picker-indicator": {
                    filter:
                      "invert(2%) sepia(85%) saturate(7399%) hue-rotate(356deg) brightness(280%) contrast(2.5%)",
                  },
                }}
              ></Grid>
            )}

            {helperText ? (
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={12}
                sx={{
                  mt: 1,
                  paddingRight: "20px",
                  alignItems: "center",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  color: "lightgrey",
                }}
              >
                <Typography
                  textAlign={"right"}
                  ml={1}
                  fontFamily={"Dosis"}
                  fontWeight={"200"}
                  fontSize="12px"
                  color={"lightgray"}
                >
                  {helperText}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        ) : (
          <Grid container mt={2}>
            <Grid
              component={"label"}
              htmlFor={labelInputId}
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              sx={{
                fontFamily: "Dosis",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {labelText} {requiredTrue ? "*" : ""}
            </Grid>
            <Grid
              component={"textarea"}
              rows={rows}
              required={requiredTrue}
              name={inputName}
              value={inputValue}
              onChange={onChangeHandler}
              id={labelInputId}
              placeholder={placeholderText}
              item
              xl={5}
              lg={5}
              md={5}
              sm={5}
              xs={12}
              sx={{
                outline: "none",
                border: "1px solid rgba(255,255,255,0.5)",
                borderRadius: "5px",
                background:
                  " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",
                padding: "10px",
                fontSize: "18px",
                "&:focus": { border: "2px solid rgba(255,255,255,0.5)" },
                resize: "none",
                fontFamily: "Dosis",
                color: "white",
                "&::-webkit-calendar-picker-indicator": {
                  filter:
                    "invert(2%) sepia(85%) saturate(7399%) hue-rotate(356deg) brightness(280%) contrast(2.5%)",
                },
              }}
            ></Grid>
            {helperText ? (
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={12}
                sx={{
                  mt: 1,
                  paddingRight: "20px",
                  alignItems: "center",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  color: "lightgrey",
                }}
              >
                <Typography
                  textAlign={"right"}
                  ml={1}
                  fontFamily={"Dosis"}
                  fontWeight={"200"}
                  fontSize="12px"
                  color={"lightgray"}
                >
                  {helperText}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        )}
      </>
    );
  } else {
    return (
      <>
        {inputType !== "textarea" ? (
          <Grid container mt={2}>
            <Grid
              component={"label"}
              htmlFor={labelInputId}
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              sx={{
                fontFamily: "Dosis",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {labelText} {requiredTrue ? "*" : ""}
            </Grid>

            {inputType === "date" ? (
              <Grid
                component={"input"}
                required={requiredTrue}
                type={"date"}
                name={inputName}
                value={inputValue}
                onChange={onChangeHandler}
                id={labelInputId}
                placeholder={placeholderText}
                min={minDate}
                item
                xl={8}
                lg={8}
                md={8}
                sm={8}
                xs={12}
                sx={{
                  height: "45px",
                  color: "white",

                  "&::-webkit-calendar-picker-indicator": {
                    filter:
                      "invert(2%) sepia(85%) saturate(7399%) hue-rotate(356deg) brightness(280%) contrast(2.5%)",
                  },
                  outline: "none",
                  border: "1px solid rgba(255,255,255,0.5)",
                  borderRadius: "5px",
                  background:
                    " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",
                  paddingX: "10px",
                  fontSize: "18px",
                  "&:focus": {
                    border: "2px solid rgba(255,255,255,0.5)",
                    color: "white",
                  },
                }}
              ></Grid>
            ) : inputType === "select" ? (
              <Grid
                component={Select}
                required={requiredTrue}
                // type={""}
                name={inputName}
                value={inputValue ? inputValue : selectedValue}
                onChange={onChangeHandler}
                id={labelInputId}
                placeholder={placeholderText}
                item
                xl={8}
                lg={8}
                md={8}
                sm={8}
                xs={12}
                sx={{
                  height: "45px",
                  outline: "none",
                  textTransform: "capitalize",
                  border: "1px solid rgba(255,255,255,0.5)",
                  background:
                    " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",

                  borderRadius: "5px",
                  "& fieldset": { border: "none" },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                  paddingX: "10px",
                  fontFamily: "Dosis",
                  color: "white",
                  fontSize: "18px",

                  "&:focus": { border: "2px solid rgba(255,255,255,0.5)" },
                }}
              >
                <MenuItem value={"--"}>{initialValue}</MenuItem>
                {options?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Grid>
            ) : (
              <Grid
                component={"input"}
                required={requiredTrue}
                type={inputType}
                name={inputName}
                value={inputValue}
                onChange={onChangeHandler}
                id={labelInputId}
                placeholder={placeholderText}
                item
                xl={8}
                lg={8}
                md={8}
                sm={8}
                xs={12}
                sx={{
                  height: "45px",
                  outline: "none",
                  border: "1px solid rgba(255,255,255,0.5)",
                  background:
                    " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",

                  borderRadius: "5px",
                  paddingX: "10px",
                  fontFamily: "Dosis",
                  color: "white",
                  fontSize: "18px",
                  "&:focus": { border: "2px solid rgba(255,255,255,0.5)" },
                  "&::-webkit-calendar-picker-indicator": {
                    filter:
                      "invert(2%) sepia(85%) saturate(7399%) hue-rotate(356deg) brightness(280%) contrast(2.5%)",
                  },
                }}
              ></Grid>
            )}

            {helperText ? (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{ mt: 1, paddingRight: "20px", color: "light-grey" }}
              >
                <Typography textAlign={"right"} fontSize="12px">
                  {helperText}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        ) : (
          <Grid container mt={2}>
            <Grid
              component={"label"}
              htmlFor={labelInputId}
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              sx={{
                fontFamily: "Dosis",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {labelText} {requiredTrue ? "*" : ""}
            </Grid>
            <Grid
              component={"textarea"}
              rows={rows}
              required={requiredTrue}
              name={inputName}
              value={inputValue}
              onChange={onChangeHandler}
              id={labelInputId}
              placeholder={placeholderText}
              item
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={12}
              sx={{
                outline: "none",
                border: "1px solid rgba(255,255,255,0.5)",
                borderRadius: "5px",
                background:
                  " linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(250,255,255,0.2) 100%, rgba(0,0,0,0.4) 100%)",
                padding: "10px",
                fontSize: "18px",
                "&:focus": { border: "2px solid rgba(255,255,255,0.5)" },
                resize: "none",
                fontFamily: "Dosis",
                color: "white",
                "&::-webkit-calendar-picker-indicator": {
                  filter:
                    "invert(2%) sepia(85%) saturate(7399%) hue-rotate(356deg) brightness(280%) contrast(2.5%)",
                },
              }}
            ></Grid>
          </Grid>
        )}
      </>
    );
  }
};

export default SingleInputWithLabels;

import React, { useEffect, useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import PictureImage from "../Gallery/PictureSection";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading } from "../../redux/action/defaultActions";

const formdata = new FormData();
const GalleryImage = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [edit, setEdit] = useState(false);
  const [images, setImages] = useState([]);
  // ============================
  const handleChange = (e) => {
    console.log(e.target.files);
    formdata.append("file", e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  };
  // ============================
  const handleClicked = () => {
    setEdit(!edit);
  };
  // ============================
  const handleUpload = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`/api/v1/alley/images/upload`, formdata);
      console.log(res);
      setFile(null);
      dispatch(isLoading(false));
      getData();
    } catch (error) {
      console.log(error);
      dispatch(isLoading(false));
    }
  };
  // ============================
  const getData = async () => {
    console.log("===> api k bhr to dekho");
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/alley/images/data`);
      console.log("===> kuch to dekho", res?.data);
      setImages(res?.data?.gallery?.alleyGallery);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log(error);
    }
  };
  // ============================
  const handleDeleteImage = async (item_id) => {
    console.log(item_id);
    dispatch(isLoading(true));
    try {
      const res = await axios.delete(`/api/v1/alley/images/delete/${item_id}`);
      console.log("===> id bheaj ke dekho", res);
      setImages(res?.data?.gallery?.alleyGallery);
      getData();
      dispatch(isLoading(false));
      setEdit(false);
    } catch (error) {
      dispatch(isLoading(false));
      console.log(error);
    }
  };
  // ============================
  useEffect(() => {
    getData();
  }, []);

  return (
    <Box width={"100%"}>
      <Box>
        <Stack
          sx={{ fontFamily: "dosis", fontWeight: "700", fontSize: "1.5rem" }}
        >
          Gallery
        </Stack>
        <Stack
          spacing={2}
          direction={{ md: "row", xs: "column" }}
          mt={1}
          //   sx={{ marginBottom: "2.5rem" }}
          p={2}
          alignItems={"center"}
          component={Paper}
        >
          {file ? (
            <>
              <Stack>
                <img
                  src={file}
                  alt="logo"
                  accept=".png, .jpg, .jpeg"
                  style={{
                    height: "150px",
                    width: "150px",
                    marginTop: "10px",
                  }}
                />
                <Button
                  size="small"
                  variant="text"
                  onClick={() => setFile(null)}
                  sx={{
                    bgcolor: "transparent",
                    "&:hover": { bgcolor: "rgba(0,0,0,0.2)" },
                    maxHeight: "35px",
                  }}
                >
                  Remove
                </Button>
              </Stack>
            </>
          ) : (
            <Box
              component={"input"}
              type="file"
              name="file"
              onChange={handleChange}
            />
          )}

          <Button
            variant="contained"
            // component="label"
            size="small"
            disabled={!file}
            sx={{
              marginTop: "10px",
              fontFamily: "dosis",
              maxHeight: "40px",
            }}
            onClick={handleUpload}
          >
            Upload File
          </Button>
        </Stack>
      </Box>
      <Stack mt={2} direction={"row"} justifyContent={"space-between"}>
        <Typography
          sx={{ fontFamily: "dosis", fontWeight: "700", fontSize: "1.5rem" }}
        >
          Uploaded Images
        </Typography>
        <Button
          variant="text"
          sx={{
            marginTop: "10px",
            fontFamily: "dosis",
            maxHeight: "40px",
            bgcolor: "rgba(215,210,198,1)",

            "&:hover": { bgcolor: "rgba(0,0,0,0.5)", color: "red" },
          }}
          onClick={handleClicked}
        >
          {edit ? "Close" : "Edit Gallery"}
        </Button>
      </Stack>
      <Stack component={Paper} mt={1} p={2}>
        <PictureImage
          images={images}
          edit={edit}
          handleDeleteImage={handleDeleteImage}
          setEdit={setEdit}
        />
      </Stack>
    </Box>
  );
};

export default GalleryImage;

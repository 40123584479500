import * as React from "react";
// import './upcomingMatches.css';

import Box from "@mui/material/Box";

import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import GamesIcon from "@mui/icons-material/Games";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import PanelButtons from "../../../reusablecomponents/buttons/PanelButtons";
import AddIcon from '@mui/icons-material/Add';
export default function LeagueDashboard() {
  const handleChange = async () => {
    try {
    } catch (error) {}
  };
  const leaguePageUrls = [
    { name: "Past Leagues", url: "/leagues/past-leagues" },
    { name: "Ongoing Leagues", url: "/leagues/" },
    { name: "Upcoming Leagues", url: "/leagues/upcoming-leagues" },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Stack spacing={3} justifyContent={"center"}>
        <Box
          borderRadius={"5px"}
          p={1}
          py={2}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Stack mb={1}>
            <Typography variant="h5">Manage Leagues</Typography>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <PanelButtons
                name="Active Leagues"
                // value={15}
                path="/activeLeagues"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <PanelButtons
                name="Upcoming Leagues"
                // value={15}
                path="/upcomingLeagues"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <PanelButtons
                name="Past Leagues"
                // value={15}
                path="/pastLeagues"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <PanelButtons
                name="Popular Leagues"
                // value={15}
                path="/popularLeagues"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                variant="text"
                startIcon={
                  // <img src="./assets/images/NewLeaque.png" alt="new" />
                  <AddIcon/>
                }
                sx={{
                  mt: 2,
                  color: "#fff",
                  fontWeight: "500",
                  bgcolor: "rgba(133, 219, 72,0.8)",
                  ":hover": {
                    bgcolor: "rgba(133, 219, 72,0.4)",
                    color: "white",
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate("/createNewLeague")}
              >
                &nbsp; Create New League
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                variant="text"
                startIcon={<GamesIcon style={{ color: "white !important" }} />}
                sx={{
                  mt: 2,
                  // color: "#fff",
                  color: "white",
                  fontWeight: "500",
                  bgcolor: "rgba(133, 219, 72,0.8)",
                  "& .MuiButton-startIcon": {
                    "& svg": {
                      fill: "white !important",
                    },
                  },
                  "&:hover": {
                    bgcolor: "rgba(133, 219, 72,0.4)",
                    color: "white",
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate("/all/ongoing/day/matches")}
              >
                &nbsp; Update Scores
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          borderRadius={"5px"}
          p={2}
          component={Paper}
          py={2}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            p={1}
            py={2}
          >
            <Typography>Overview</Typography>
          </Stack>
          <Stack display={"flex"} flexDirection={"row"}>
            {leaguePageUrls?.map((page, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleChange()}
                  style={{
                    background:
                      " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                    color: "white",
                    border: "1px solid grey",
                    borderRadius: "50px",
                    marginRight: "10px",
                  }}
                >
                  <Link
                    fontFamily={"Dosis"}
                    component={NavLink}
                    to={page?.url}
                    sx={{
                      textDecoration: "none",
                      color: "white",
                      "&:hover": {
                        textDecoration: "5px",
                        color: "rgb(67, 191, 55)",
                      },
                      "&.active": {
                        textDecoration: "5px",
                        color: "rgb(67, 191, 55)",
                      },
                    }}
                    className="link mx-2"
                    underline="hover"
                  >
                    {page?.name}
                  </Link>
                </button>
              );
            })}
          </Stack>
          {/* <Stack mt={2}>
            <Typography sx={{ color: "white" }}>
              From{" "}
              <span
                style={{
                  color: "rgb(67, 191, 55)",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                }}
              >
                Friday, August 11
              </span>
              To
              <span style={{ color: "rgb(67, 191, 55)", marginLeft: "1rem" }}>
                Sunday, August 30
              </span>
            </Typography>
          </Stack> */}

          <Outlet />
        </Box>
      </Stack>
    </>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { isLoading } from "../../redux/action/defaultActions";
import { useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Avatar } from "@mui/material";
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function LeagueInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leagueId } = useParams();
  const [data, setData] = useState();
  const [match, setMatch] = useState([]);
  // ********************Get Single details function********************
  const getApi = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `/api/v1/league/alley/single/league/${leagueId}`
      );
      setData(res?.data?.alleyLeague);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  // ********************Get LeaderBoad Details function********************
  const getTeamData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/leaderboard/${leagueId}`);
      setMatch(res?.data?.alleyLeague?.leagueTeams);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  useEffect(() => {
    getApi();
    getTeamData();
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2}>
        <Item
          sx={{
            // backgroundColor: "#4D4C4C",
            backgroundColor: "transparent",
            color: "white",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Box
            className="fdiv"
            sx={{
              fontSize: "1rem",
            }}
          >
            {data?.leagueName}
          </Box>
          <Box className="fdiv">{data?.description}</Box>
        </Item>
        <Item
          component={"img"}
          sx={{
            maxWidth: "100%",
            maxHeight: "320px",
            // backgroundColor: "#4D4C4C",
          }}
          src={data?.leagueImages?.url}
          alt="League Logo"
        />
        <Item>
          <Box className="third" sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              className="thirdLeft"
              style={{
                width: "50%",
                textAlign: "left",
              }}
            >
              <Card
                sx={{
                  minWidth: 275,
                  // bgcolor: "#4D4C4C",
                  color: "white",
                  // bgcolor:"transparent"
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 16, color: "rgba(111, 190, 68, 1)" }}
                    gutterBottom
                  >
                    About
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, fontWeight: "normal" }}
                    gutterBottom
                  >
                    {data?.description}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: "rgba(111, 190, 68, 1)" }}
                    gutterBottom
                  >
                    Schedule
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, fontWeight: "normal" }}
                    gutterBottom
                  >
                    {dayjs(data?.startDate).format("YYYY-MM-DD")} &nbsp;&nbsp;
                    {dayjs(data?.startTiming).format("hh:mm")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 16,
                      color: "rgba(111, 190, 68, 1)",
                    }}
                    gutterBottom
                  >
                    League Type
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, fontWeight: "normal" }}
                    gutterBottom
                  >
                    {data?.typeOfLeague}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: "rgba(111, 190, 68, 1)" }}
                    gutterBottom
                  >
                    Teams Joined
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, fontWeight: "normal" }}
                    gutterBottom
                  >
                    {data?.numberOfTeam}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: "rgba(111, 190, 68, 1)" }}
                    gutterBottom
                  >
                    PrizePool
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, fontWeight: "normal" }}
                    gutterBottom
                  >
                    ${data?.prizePool}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            <Box
              className="thirdRight"
              // component={Paper}
              sx={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                width: "50%",
                // backgroundColor: "#4D4C4C",
                borderLeft: "0.5px solid grey",
                // boxShadow: "5px 5px 5px white",
              }}
            >
              <Typography
                sx={{
                  // backgroundColor: "#4D4C4C",
                  overflow: "hidden",
                  color: "white",
                }}
              >
                Teams
              </Typography>
              <Box
                sx={{
                  maxWidth: "95%",
                  maxHeight: "18.5rem",
                  overflow: "auto",
                  // backgroundColor: "#4D4C4C",
                  color: "white",
                }}
              >
                <Stack spacing={2}>
                  {match?.map((data, index) => {
                    return (
                      <Item
                        sx={{
                          bgcolor: "transparent",
                          marginTop: "8px",
                          paddingY: "10px",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          key={index}
                          sx={{ maxWidth: "40rem", overflow: "hidden" }}
                        >
                          <Table
                            sx={{
                              minWidth: 480,
                              bgcolor: "lightgrey",
                              fontSize: "16px",
                              minHeight: "50px",
                            }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ paddingRight: "0px" }}>
                                  <Avatar
                                    alt={data?.teamName.trim()}
                                    sx={{
                                      textTransform: "capitalize",
                                    }}
                                    src="/static/images/avatar/1.jpg"
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{ color: "black", paddingX: "0px" }}
                                  align="right"
                                >
                                  {data?.teamName}
                                </TableCell>
                                <TableCell
                                  sx={{ color: "black" }}
                                  align="right"
                                >
                                  {data?.teamTotal}
                                </TableCell>
                                <TableCell
                                  sx={{ color: "black" }}
                                  align="right"
                                >
                                  {data.totalHCScore}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableContainer>
                      </Item>
                    );
                  })}
                </Stack>
              </Box>
            </Box>
          </Box>
        </Item>
      </Stack>
    </Box>
  );
}

import { InputAdornment, TextField } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import React, { useState } from "react";

const InputWIthIcon = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  return (
    <>
      {/* check if the fieldtype is password or not */}
      {props?.inputType && props?.inputType === "password" ? (
        <TextField
          label="Size"
          id="standard-size-normal"
          fullWidth
          autoComplete="off"
          type={showPassword ? "text" : "password"}
          placeholder={props?.inputPlaceholder || ""}
          name={props?.inputName || ""}
          onChange={props?.onChangeHandler}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment
                sx={{ color: "rgba(255,255,255,0.6)" }}
                position="start"
              >
                {props?.startAdornment}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                sx={{
                  color: "rgba(255,255,255,0.6)",
                  cursor: "pointer",
                  "&::active": { scale: 0.8 },
                }}
                onClick={handleShowPassword}
                position="end"
              >
                {showPassword ? (
                  <VisibilityOffOutlinedIcon style={{ fontSize: "30px" }} />
                ) : (
                  <VisibilityOutlinedIcon style={{ fontSize: "30px" }} />
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            mt: 1,
            "::-webkit-input-placeholder": {
              color: " pink",
            },
            "& .MuiInput-root": {
              padding: "8px 10px",
              borderRadius: "10px",
              background: "rgb(233,227,227)",
              fontFamily: "Dosis",
              letterSpacing: "1.5px",
              fontSize: "22px",

              background:
                " linear-gradient(90deg, rgba(233,227,227,0.3) 45%, rgba(16,17,16,0.9) 95%)",
              border: "1px solid rgba(255,255,255,0.5) !important ",
              color: "rgba(255,255,255,0.8)",
              //   bgcolor:"red"
            },

            "& .MuiFormLabel-root": {
              display: "none",
            },
          }}
        />
      ) : (
        // textfield for the other input types
        <TextField
          label="Size"
          id="standard-size-normal"
          autoComplete="off"
          fullWidth
          type={props?.inputType ? props?.inputType : "text"}
          placeholder={props?.inputPlaceholder || ""}
          name={props?.inputName || ""}
          onChange={props?.onChangeHandler}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment
                sx={{ color: "rgba(255,255,255,0.6)" }}
                position="start"
              >
                {props?.startAdornment}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                sx={{
                  color: "rgba(255,255,255,0.6)",
                }}
                position="end"
              >
                {props?.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            mt: 1,
            "::-webkit-input-placeholder": {
              color: " pink",
            },
            "& .MuiInput-root": {
              padding: "8px 10px",
              borderRadius: "10px",
              background: "rgb(233,227,227)",
              fontFamily: "Dosis",
              letterSpacing: "1.5px",
              fontSize: "22px",

              background:
                " linear-gradient(90deg, rgba(233,227,227,0.3) 45%, rgba(16,17,16,0.9) 95%)",
              border: "1px solid rgba(255,255,255,0.5) !important ",
              color: "rgba(255,255,255,0.8)",
              //   bgcolor:"red"
            },

            "& .MuiFormLabel-root": {
              display: "none",
            },
          }}
        />
      )}
    </>
  );
};

export default InputWIthIcon;

import React, { useState } from "react";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import Button1 from "../../reusablecomponents/buttons/Button1";
import { Box, Stack } from "@mui/system";
import { IoMailOutline } from "react-icons/io5";
// import { TfiKey } from "react-icons/tfi";
import InputWIthIcon from "../../reusablecomponents/formcontrols/InputWIthIcon";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { Url } from "../../url";

const ForgetCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email);
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`/api/v1/alley/password/forgot`, {
        email: email,
      });

      if (res.data.success === true) {
        dispatch(isLoading(false));
        dispatch(
          openSnackbar("OTP sent to your resgisterd mail-id", "success")
        );
        navigate(`/otpVerification/${email}`);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error?.response?.data?.message, "error"));
        setEmail("");
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
        setEmail("");
      } else {
        dispatch(openSnackbar("Something went wrong", "error"));
        console.log(error);
      }
      dispatch(isLoading(false));
    }
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        p: 1,
        py: 2,
        background:
          " linear-gradient(90deg, rgba(22,38,0,0.9808298319327731) 11%, rgba(0,9,0,0.9864320728291317) 98%)",
        border: "1px solid rgb(52 78 37)",
        borderRadius: "10px",
      }}
    >
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Stack>
            <Box
              component={"img"}
              height={"53px"}
              src="/assets/logo/Vector.svg"
            />
            <Typography
              variant="h4"
              mt={1}
              textAlign={"center"}
              fontWeight={"600"}
              letterSpacing={"1.5px"}
              // fontSize={"25px"}
              fontFamily={"Dosis"}
            >
              Pocket League
            </Typography>
            <Typography
              mt={1}
              textAlign={"center"}
              fontWeight={"300"}
              letterSpacing={"2px"}
              fontSize={"18px"}
              fontFamily={"Dosis"}
              color={"rgba(111, 190, 68, 1)"}
            >
              Unite Through Gaming
            </Typography>
            <Typography
              variant="h4"
              mt={1}
              textAlign={"center"}
              fontWeight={"400"}
              letterSpacing={"1px"}
              // fontSize={"25px"}
              fontFamily={"Dosis"}
              textTransform={"capitalize"}
            >
              Foget Password
            </Typography>
          </Stack>
          <Stack>
            <InputWIthIcon
              startAdornment={<IoMailOutline style={{ fontSize: "26px" }} />}
              inputName={"email"}
              inputType={"email"}
              inputPlaceholder={"Enter Admin Email Id"}
              onChangeHandler={(e) => setEmail(e.target.value)}
            />
          </Stack>
        </CardContent>
        <CardActions sx={{ paddingX: "16px" }}>
          <Button1
            buttonType={"submit"}
            size="small"
            fontSize={"23px"}
            name="Sign In"
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default ForgetCard;

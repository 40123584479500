import {
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PlayerScores from "./PlayerScores";
import { Stack } from "@mui/system";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading } from "../../redux/action/defaultActions";

const TeamScoreCard = ({
  data,
  matchDate,
  getLiveMatchTeamsScoreSquadInfo,
  pFactor,
  bsScore,
}) => {
  const { leagueId } = useParams();
  const dispatch = useDispatch();
  const [scoreValues, setScoreValues] = useState([]);

  //   =====================================================
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    const temp = [];
    scoreValues.forEach((player) => {
      temp.push({
        userID: player?.playerId,
        handicapScore: {
          hca: player?.handicapScore,
          matchID: [...data?.matchID],
        },
        matchDate: matchDate,
        scoreData: [
          {
            matchID: player?.game1?.matchID,
            userScore: player?.game1?.score,
            matchDate: matchDate,
          },
          {
            matchID: player?.game2?.matchID,
            userScore: player?.game2?.score,
            matchDate: matchDate,
          },
          {
            matchID: player?.game3?.matchID,
            userScore: player?.game3?.score,
            matchDate: matchDate,
          },
        ],
      });
    });
    console.log("scoreData=========>", temp);

    try {
      const res = await axios.post(
        `/api/v1/league/match/score/update/${leagueId}/${data?.team?._id}`,
        temp
      );

      dispatch(isLoading(false));
      getLiveMatchTeamsScoreSquadInfo();
      console.log("res==>", res);
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    if (data) {
      const temp = [];
      data?.team?.members?.forEach((element) => {
        console.log("");
        temp.push({
          playerName: `${element?.firstName} ${element?.lastName}`,
          handicapScore: element?.scores?.handicapScore?.hca,
          playerId: element?.userAuth,

          game1: {
            score: element?.scores?.playerScores[0]?.score,
            matchID: element?.scores?.playerScores[0]?.matchID,
          },
          game2: {
            score: element?.scores?.playerScores[1]?.score,
            matchID: element?.scores?.playerScores[1]?.matchID,
          },
          game3: {
            score: element?.scores?.playerScores[2]?.score,
            matchID: element?.scores?.playerScores[2]?.matchID,
          },
        });
      });
      setScoreValues([...temp]);
    }
  }, []);

  return (
    <Card sx={{ minWidth: 300, border: "1px solid green" }}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <TableContainer
            sx={{
              minWidth: "300px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "2px",
                height: "5px",
                bgcolor: "transparent",
                borderRadius: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                bgcolor: "white",
                height: "2px",

                borderRadius: "6px",
              },
            }}
          >
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <caption style={{ captionSide: "top" }}>
                <Typography variant="h6" fontFamily={"Dosis"} gutterBottom>
                  {" "}
                  Team :{" "}
                  <span style={{ color: "green" }}> {data?.team?.name}</span>
                </Typography>
              </caption>
              <TableHead>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& td , th": {
                      border: "0",
                    },
                  }}
                >
                  <TableCell>Players</TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "green !important", fontWeight: "bolder" }}
                  >
                    G1
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "green !important", fontWeight: "bolder" }}
                  >
                    G2
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "green !important", fontWeight: "bolder" }}
                  >
                    G3
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "green !important", fontWeight: "bolder" }}
                  >
                    HC
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scoreValues?.map((playerInfo, index) => (
                  <PlayerScores
                    key={index}
                    playerData={playerInfo}
                    setScoreValues={setScoreValues}
                    playerIndex={index}
                    scoreValues={scoreValues}
                    pFactor={pFactor}
                    bsScore={bsScore}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Stack
            justifyContent={"center"}
            mb={2}
            alignItems={"center"}
            direction={"flow"}
          >
            <Button
              type="submit"
              size="small"
              variant="outlined"
              sx={{
                background: "transparent",
                border: "1px solid white",
                "&:focus": { backgroundColor: "green !important" },
              }}
            >
              Save
            </Button>
          </Stack>
        </CardActions>
      </form>
    </Card>
  );
};

export default TeamScoreCard;

import React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";

const Button2 = (props) => {
  return (
    <>
      {props?.name ? (
        <Button
          size={props?.size ? props?.size : "small"}
          variant="contained"
          type={props?.type ? props?.type : "button"}
          fullWidth
          onClick={props?.type === "submit" ? () => {} : () => props?.onClick}
          sx={{
            m: props?.m,
            position: "relative",
            color: "white",
            fontFamily: "Dosis",
            textAlign: "center",
            borderRadius: "20px",
            background: "rgb(76,191,40)",
            background:
              "linear-gradient(180deg, rgba(76,191,40,0.3) 3%, rgba(231,251,227,0.4) 100%, rgba(246,247,245,1) 90%, rgba(234,255,229,0.3) 100%)",

            textTransform: props.textTransform
              ? props?.textTransform
              : "capitalize",
            textShadow: "-0.5px 0.5px 0px white",

            // border: "1px solid rgba(255,255,255,0.3)",
            maxHeight: "50px",
          }}
        >
          {/* {props?.name} */}
          <span
            style={{
              position: "absolute",
              color: "rgba(111, 190, 68, 1)",
              top: 0,
              left: 0,
              fontWeight: "300",
              backgroundColor: "rgba(0,0,0,0.5)",
              // backgroundColor: "red",
              borderRadius: "20px",
              width: "100%",
              height: "100%",
              display: "flex",
              fontSize: "22px",
              border: "2px solid rgba(255,255,255,0.2)",
              textShadow: "1px 1px 1px black",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "10px black",
            }}
          >
            {" "}
            {props?.name}
          </span>
        </Button>
      ) : (
        ""
      )}
    </>
  );
};

export default Button2;

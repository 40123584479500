import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack, border, color } from "@mui/system";
import React, { useEffect, useState } from "react";
import ProfilePicUpload from "./ProfilePicUpload";
import { NavLink, Outlet } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateProfileData } from "../../redux/action/adminActions";

const MyProfile = () => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const [editemode, seteditemode] = useState(false);
  const [fileUrl, setfileUrl] = useState("");
  const getData = async () => {
    try {
      const res = await axios.get(`/api/v1/alley/profile/data`);
      console.log("profile picture==>", res?.data?.alley);
      setData(res?.data?.alley);
      dispatch(updateProfileData(res?.data?.alley));
    } catch (error) {
      console.log("error=>", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box width={"100%"} p={2}>
      <Box bgcolor={"white"} borderRadius={"10px"} minHeight={"600px"} px={0}>
        <Box borderRadius={"10px 10px 0px 0px"} bgcolor={"grey"} px={2} py={2}>
          <Typography>My Profile</Typography>
        </Box>
        <Box>
          <Stack color={"black"} py={2} px={5}>
            <Stack
              // mt={1}
              justifyContent={"end"}
              direction={"row"}
              alignItems={"center"}
            >
              <Button
                variant="outlined"
                onClick={() => seteditemode(!editemode)}
                sx={{
                  backgroundColor: "transparent",
                  transition: "ease-in-out 300ms",
                  borderColor: "rgba(11, 94, 25, 1) !important",
                  color: "rgba(111, 190, 68, 1) !important",
                  maxHeight: "35px",
                  "&:hover": {
                    backgroundColor: "rgba(111, 190, 68, 1)",
                    color: "white !important",
                    border: "none",
                    outline: "none",
                  },
                }}
              >
                {editemode ? "Close" : "Edit"}
              </Button>
            </Stack>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={3}>
                <ProfilePicUpload
                  profilePicture={data?.alleyAccount?.profilePicture?.url}
                  editMode={editemode}
                  alleyData={data}
                  seteditemode={seteditemode}
                  getData={getData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <Stack>
                  <Typography>
                    {data?.userName ? <>{data?.userName}</> : <></>}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography>
                    {data?.email ? <>{data?.email}</> : <></>}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography>
                    {data?.alleyAccount?.contactNumber ? (
                      <>
                        {data?.alleyAccount?.countryCode +
                          " " +
                          data?.alleyAccount?.contactNumber}
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack px={5} py={3}>
            <Stack
              component={"nav"}
              px={2}
              width={"100%"}
              py={1}
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              borderRadius={"8px"}
              sx={{ border: "2px solid grey", overflowX: "auto" }}
            >
              <Button
                size="small"
                component={NavLink}
                to={"/myProfile/"}
                sx={{
                  backgroundColor: "white",
                  fontSize: { xs: "10px", sm: "12px", md: "15px" },
                  color: "grey",
                  "&:hover": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                  "&.active": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                }}
              >
                Account settings
              </Button>
              {/* <Button
                component={NavLink}
                to={"/myProfile/address"}
                sx={{
                  backgroundColor: "white",
                  fontSize: { xs: "10px", sm: "12px", md: "15px" },
                  color: "grey",
                  "&:hover": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                  "&.active": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                }}
              >
                Address
              </Button> */}
              <Button
                component={NavLink}
                to={"/myProfile/manage-password"}
                sx={{
                  backgroundColor: "white",
                  fontSize: { xs: "10px", sm: "12px", md: "15px" },
                  color: "grey",
                  "&:hover": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                  "&.active": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                }}
              >
                Manage password
              </Button>
              {/* <Button
                component={NavLink}
                to={"/myProfile/payment-method"}
                sx={{
                  backgroundColor: "white",
                  fontSize: { xs: "10px", sm: "12px", md: "15px" },
                  color: "grey",
                  "&:hover": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                  "&.active": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                }}
              >
                Payment method
              </Button> */}
              {/* <Button
                component={NavLink}
                to={"/myProfile/timing"}
                sx={{
                  backgroundColor: "white",
                  fontSize: { xs: "10px", sm: "12px", md: "15px" },
                  color: "grey",
                  "&:hover": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                  "&.active": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                }}
              >
                Timings
              </Button> */}
            </Stack>
          </Stack>
          <Box>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyProfile;

import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import * as React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import newLeague from "../../../public/assets/images/NewLeaque.png";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useState } from "react";

export default function TableLeagues({ data, tableType, parentPath }) {
  const navigate = useNavigate();
  const columns = [
    {
      field: "index",
      headerName: "Sr-No",
      valueGetter: (params) => params.value + 1,
    },
    {
      field: "leagueName",
      headerName: "LeagueName",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      border: "0px transparent",
      width: 180,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              color: "green !important",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate(`${parentPath}/${params?.row?._id}/`)}
          >
            {params?.row?.leagueName}
          </Typography>
        );
      },
    },
    {
      field: "typeOfLeague",
      headerName: "Format",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      border: "0px transparent",
      width: 120,
    },
    {
      field: "description",
      headerName: "Descriptions",
      Text: "center",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              color: "green !important",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate(`${parentPath}/${params?.row?._id}`)}
          >
            {params?.row?.description}
          </Typography>
        );
      },
    },
    {
      field: "Team",
      headerName: "Team",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 120,
      renderCell: (row) => {
        return row.row.matchPerTeam + "/" + row.row.numberOfTeam;
      },
    },
    {
      field: "StartingOn",
      headerName: "StartingOn",
      description: "This column has a value getter and is not sortable.",
      sortable: false,

      width: 150,

      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {`${dayjs(params?.row?.startDate).format("DD-MM-YYYY")}
            ${dayjs(params?.row?.startDate).format("HH:mm")}`}
          </Typography>
        );
      },
    },
    tableType === "upcoming"
      ? {
          field: "Action",
          headerName: "action",
          description: "This column has a value getter and is not sortable.",
          sortable: false,
          width: 120,
          renderCell: (row) => {
            return <Button variant="contained">Edit</Button>;
          },
        }
      : "",
  ];
  const [editbtn, setEditbtn] = useState(false);
  function CustomToolbar() {
    return (
      <Box
        py={2}
        px={1}
        bgcolor={"rgba(51, 51, 51, 0.9)"}
        borderBottom={"1px solid rgba(131, 135, 138, 0.8)"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} alignItems={"center"}>
          <Typography fontSize={"22px"}>My League</Typography>
          <KeyboardArrowRightIcon />
          <Typography
            fontSize={"17px"}
            sx={{ textTransform: "capitalize" }}
            fontWeight={"200"}
          >
            {tableType}
          </Typography>
        </Grid>
        {/*  */}
        <Grid display={"flex"} alignItems={"center"}>
          <GridToolbarQuickFilter
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "rgba(158, 158, 158, 1)",
                borderRadius: "10px",
                borderColor: "rgba(158, 158, 158, 1)",
              },
              "&:svg": {
                fill: "red",
              },
            }}
          />
        </Grid>
        <Grid
          mx={1}
          // bgcolor={"rgb(133, 219, 72)"}
          color={"white"}
          borderRadius={"8px"}
          display={"flex"}
          alignItems={"center"}
          // sx={{
          //   ":hover": {
          //     bgcolor: "#AF5",
          //     color: "white",
          //     cursor: "pointer",
          //   },
          // }}
        >
          <Button
            // variant="text"
            startIcon={<img src="/assets/images/NewLeaque.png" alt="logo" />}
            sx={{
              color: "#fff",
              fontWeight: "500",
            }}
            onClick={() => navigate("/createNewLeague")}
          >
            &nbsp; Create New League
          </Button>
        </Grid>
      </Box>
    );
  }

  console.log("==================================");
  console.log(data);

  return (
    <div style={{ minHeight: 450, width: "100%" }}>
       <DataGrid
        getRowId={(row) => row._id}
        rows={data}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
        sx={{
          fontSize: "15px",
          minHeight: "100px",
          opacity: 1,
          color: "white",
          border: "1px solid rgba(131, 135, 138, 0.8)",
          "&.MuiDataGrid-columnHeaders,.MuiDataGrid-withBorderColor": {
            borderColor: "rgba(131, 135, 138, 0.5) !important",
          },
        }}
      />
    </div>
  );
}

import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import axios from "axios";
import { logout } from "../../redux/action/adminActions";
import { useNavigate } from "react-router-dom";

const ManagePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordValues, setPasswordValues] = useState({});
  const handleChange = (e) => {
    setPasswordValues({ ...passwordValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      if (passwordValues?.newPassword === passwordValues?.confirmPassword) {
        const res = await axios.put("/api/v1/alley/password/update", {
          oldPassword: passwordValues?.oldPassword,
          newPassword: passwordValues?.newPassword,
        });
        console.log("res==>", res);
        dispatch(isLoading(false));
        dispatch(openSnackbar("Password Updated Succefully", "success"));
        handleLogout();
      } else {
        dispatch(isLoading(false));
        dispatch(openSnackbar("confirm password doesn't match", "warning"));
      }
      // api/v1/alley/password/update
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  const handleLogout = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get("/api/v1/alley/sign/out");
      dispatch(logout());
      dispatch(openSnackbar("please login", "success"));
      navigate("/");
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  return (
    <Stack px={5} py={3} component={"form"} onSubmit={handleSubmit}>
      <Stack p={1} border={"2px solid #686868"} borderRadius={"8px"}>
        <Typography variant="h6" sx={{ color: "grey" }}>
          Change Alley Password
        </Typography>
        <Box width={"100%"}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                required
                name="oldPassword"
                placeholder="Enter Current Password"
                label="Current Password"
                size="small"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={
                  passwordValues?.oldPassword ? passwordValues?.oldPassword : ""
                }
                type="password"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                required
                name="newPassword"
                placeholder="Enter New Password"
                label="New Password"
                size="small"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={
                  passwordValues?.newPassword ? passwordValues?.newPassword : ""
                }
                type="password"
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                name="confirmPassword"
                placeholder="Enter Confirm Password"
                label="Confirm Password"
                size="small"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={
                  passwordValues?.confirmPassword
                    ? passwordValues?.confirmPassword
                    : ""
                }
                type="text"
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Stack mt={2} alignItems={"center"} justifyContent={"center"}>
        <Button
          variant="contained"
          type="submit"
          color="inherit"
          size="small"
          sx={{
            color: "white",
            backgroundColor: "#6FBE44 !important",
            maxHeight: "35px",
            borderRadius: "3px !important",
            px: "30px",
          }}
        >
          {" "}
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default ManagePassword;

import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Link,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Button1 from "../../reusablecomponents/buttons/Button1";
import { Box, Stack } from "@mui/system";
import { IoMailOutline } from "react-icons/io5";
import { TfiKey } from "react-icons/tfi";
import InputWIthIcon from "../../reusablecomponents/formcontrols/InputWIthIcon";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { Url } from "../../url";
import { login } from "../../redux/action/adminActions";
const LoginCard = () => {
  const [checkPrivacyPolicyTC, setcheckPrivacyPolicyTC] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
  // const [passwordType, setPasswordType] = useState("password");
  // const [alert, setAlert] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNav = () => {
    navigate("/forget");
  };
  // handle submit api call
  const handleSubmit = async (e) => {
    e.preventDefault();
    // dispatch(openSnackbar("api hit", "success"));
    dispatch(isLoading(true));

    try {
      const response = await axios.post(`${Url}/sign/in`, data);
      console.log(response.headers);
      if (response.data.success === true) {
        console.log("===>", response.data.alleyAuth.alleyAccount);
        if (response.data.alleyAuth.alleyAccount) {
          dispatch(login({ email: data?.email, firstTimeLogin: false }));
          navigate("/");
        } else {
          dispatch(login({ email: data?.email, firstTimeLogin: true }));
          navigate("/first-time-login");
        }
        dispatch(isLoading(false));
        dispatch(openSnackbar("logged in Successfully", "success"));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        p: 1,
        py: 2,
        background:
          " linear-gradient(90deg, rgba(22,38,0,0.9808298319327731) 11%, rgba(0,9,0,0.9864320728291317) 98%)",
        border: "1px solid rgb(52 78 37)",
        borderRadius: "10px",
      }}
    >
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Stack>
            <Box
              component={"img"}
              height={"53px"}
              src="/assets/logo/Vector.svg"
            />
            <Typography
              variant="h4"
              mt={1}
              textAlign={"center"}
              fontWeight={"600"}
              letterSpacing={"1.5px"}
              // fontSize={"25px"}
              fontFamily={"Dosis"}
            >
              Pocket League
            </Typography>
            <Typography
              mt={1}
              textAlign={"center"}
              fontWeight={"300"}
              letterSpacing={"2px"}
              fontSize={"18px"}
              fontFamily={"Dosis"}
              color={"rgba(111, 190, 68, 1)"}
            >
              Unite Through Gaming
            </Typography>
            <Typography
              variant="h4"
              mt={1}
              textAlign={"center"}
              fontWeight={"400"}
              letterSpacing={"1px"}
              fontSize={"25px"}
              fontFamily={"Dosis"}
              textTransform={"capitalize"}
            >
              Alley Sign In
            </Typography>
          </Stack>
          <Stack>
            <InputWIthIcon
              startAdornment={<IoMailOutline style={{ fontSize: "26px" }} />}
              inputName={"email"}
              inputType={"email"}
              inputPlaceholder={"Enter Admin Email Id"}
              onChangeHandler={(e) =>
                setData({ ...data, email: e.target.value })
              }
            />
            <InputWIthIcon
              startAdornment={<TfiKey style={{ fontSize: "26px" }} />}
              inputName={"password"}
              inputType={"password"}
              inputPlaceholder={"Enter Password"}
              onChangeHandler={(e) =>
                setData({ ...data, password: e.target.value })
              }
            />
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Checkbox
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
              }}
              checked={checkPrivacyPolicyTC}
              onClick={() => setcheckPrivacyPolicyTC(!checkPrivacyPolicyTC)}
            />
            <Typography
              variant={"body2"}
              textTransform={"capitalize"}
              component={"p"}
            >
              I Accept{" "}
              <Typography
                variant="body2"
                sx={{
                  cursor: "pointer",
                  transition: "ease-in-out 0.3s",
                  "&:hover": {
                    color: "blue !important",
                    textDecoration: "underline",
                  },
                }}
                component={"span"}
                // onClick={() => navigate("/privacy-policy")}
                onClick={()=>window.open("https://pocketleague.app/privacy-policy", '_blank')}

              >
                privacy policy
              </Typography>{" "}
              and{" "}
              <Typography
                variant="body2"
                sx={{
                  cursor: "pointer",
                  transition: "ease-in-out 0.3s",
                  "&:hover": {
                    color: "blue !important",
                    textDecoration: "underline",
                  },
                }}
                component={"span"}
                // onClick={() => navigate("/terms-and-conditions")}
                onClick={()=>window.open("https://pocketleague.app/terms-and-conditions", '_blank')}
              >
                Terms and Condition
              </Typography>
            </Typography>
          </Stack>
        </CardContent>
        <Link
          component={NavLink}
          to={"/forget"}
          sx={{
            width: "96%",
            textAlign: "right",
            display: "block",
            color: "white",
          }}
          onClick={handleNav}
        >
          Forgot password?
        </Link>
        <CardActions sx={{ paddingX: "16px" }}>
          <Button1
            disabled={!checkPrivacyPolicyTC}
            buttonType={"submit"}
            size="small"
            fontSize={"23px"}
            name="Sign In"
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default LoginCard;

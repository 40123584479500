import React, { useEffect, useState } from "react";
import TeamScoreSummary from "./TeamScoreSummary";
import { Box, Stack } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading } from "../../redux/action/defaultActions";
import axios from "axios";

const MatchSummary = () => {
  const { state } = useLocation();
  const { leagueId } = useParams();
  const dispatch = useDispatch();
  const [matchSummary, setmatchSummary] = useState(null);

  const getLiveMatches = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.put(
        `/api/v1/league/single/day/match/score/${leagueId}`,
        {
          teamA: state?.matchInfo?.matches?.teamA?._id,
          teamB: state?.matchInfo?.matches?.teamB?._id,
          matchDate: state?.matchInfo?.matches?.teamMatchDate,
        }
      );
      // console.log("response==>", res?.data?.matchData);
      setmatchSummary(res?.data?.matchData);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  // console.log("===>", matchSummary);

  useEffect(() => {
    console.log("state==>", state);
    if (state) {
      getLiveMatches();
    }
  }, []);

  return (
    <Box width={"100%"}>
      <Stack>
        <Typography variant="h4">Match Summary</Typography>
      </Stack>
      <Grid container mt={1} width={"100%"} display={"flex"}>
        <Grid item xs={12} sx={{ position: "relative" }}>
          <TeamScoreSummary summaryData={matchSummary?.leaguesMatches[0]} />
          <Box
            p={0.5}
            border={"1px solid grey"}
            borderRadius={"15px"}
            px={2}
            sx={{
              position: "absolute",
              top: "-12px",
              right: "12px",
              zIndex: 22,
              background: "rgba(46, 46, 46, 1)",
              // background:
              //   " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
            }}
          >
            <Stack direction={"row"}>
              <Typography sx={{ color: "white", fontSize: "12px" }}>
                {matchSummary?.leaguesMatches[0]
                  ? matchSummary?.leaguesMatches[0]?.team?.name
                  : "Team A"}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2} mb={2}>
          <Typography textAlign={"center"}> VS</Typography>
        </Grid>
        <Grid item xs={12} sx={{ position: "relative" }}>
          <TeamScoreSummary summaryData={matchSummary?.leaguesMatches[1]} />
          <Box
            p={0.5}
            border={"1px solid grey"}
            borderRadius={"15px"}
            px={2}
            sx={{
              position: "absolute",
              top: "-12px",
              right: "12px",
              zIndex: 22,
              background: "rgba(46, 46, 46, 1)",
              // background:
              //   " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
            }}
          >
            <Stack direction={"row"}>
              <Typography sx={{ color: "white", fontSize: "12px" }}>
                {matchSummary?.leaguesMatches[1]
                  ? matchSummary?.leaguesMatches[1]?.team?.name
                  : "Team A"}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MatchSummary;

import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControl, MenuItem, Select } from '@mui/material';


const colm = [{ "set": "1" }, { "set": "2" }, { "set": "3" }, { "set": "4" }, { "set": "5" }, { "set": "6" }, { "set": "7" }, { "set": "8" }, { "set": "9" }]

const ScoreTable = () => {

    const [addScore, setAddScore] = useState(0);

    const changeScore = (event) => {
        setAddScore(event.target.value);
    };
   
    return (
        <Paper sx={{ width: '100%', overflowX:'auto'}}>
            <TableContainer sx={{ borderCollapse: 'collapse',maxWidth: '100%', width: '100%', overflowX: 'auto'}}>
                <Table sx={{ border: "1px solid gray" }}>
                    <TableHead>
                        <TableRow sx={{ border: "1px solid gray" }}>
                            {colm.map((item, index) => {
                                return (

                                    <TableCell key={index} sx={{ border: "1px solid gray" }} align="center" colSpan={2}>
                                        {item.set}
                                    </TableCell>
                                )
                            })}
                            <TableCell sx={{ border: "1px solid gray", background: "rgb(67, 191, 55)" }} align="center" colSpan={3}>
                                10
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ background: "white" }}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21].map(() => {
                                return (
                                    <TableCell variant='body' size='small' sx={{ border: "1px solid gray", p: 0, m: 0 }}>
        
                                        <FormControl sx={{ m: 0, minWidth: 60, p: 0 }}>
                                            <Select
                                                // value={addScore}
                                                onChange={changeScore}
                                                displayEmpty
                                                inputProps={{ IconComponent: () => null }}
                                                sx={{ color: 'black' }}

                                            >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                                <MenuItem value={10}>10</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                )
                            })}

                        </TableRow>
                        <TableRow sx={{ border: "1px solid gray", background: 'lightgray' }}>
                            {colm.map((item, index) => {
                                return (

                                    <TableCell key={index} sx={{ border: "1px solid gray" }} align="center" colSpan={2}>
                                        {item.set}
                                    </TableCell>
                                )
                            })}
                            <TableCell sx={{ border: "1px solid gray", background: "rgb(67, 191, 55)" }} align="center" colSpan={3}>
                                10
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default ScoreTable

import React, { useEffect, useState } from "react";
import Teams from "../../../components/Tables/TeamsTable";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isLoading } from "../../../redux/action/defaultActions";
import axios from "axios";

const ActiveLeagueTeams = () => {
  const [leagueTeams, setLeagueTeams] = useState([]);
  const dispatch = useDispatch();
  const { leagueId } = useParams();
  const getLeagueTeamInfo = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/teams/list/${leagueId}`);
      console.log("res==>", res?.data?.alleyLeagueTeams);
      setLeagueTeams(res?.data?.alleyLeagueTeams?.leagueTeams);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  useEffect(() => {
    getLeagueTeamInfo();
  }, []);
  return <Teams teamData={leagueTeams} />;
};

export default ActiveLeagueTeams;

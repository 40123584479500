import React from "react";
import CreateAlleyDetails from "./CreateAlleyDetail";
import { useSelector } from "react-redux";

const FirstTimeLogin = () => {
  const { alley } = useSelector((state) => state);
  console.log("alley==>", alley);

  return (
    <div>
      first time login
      <CreateAlleyDetails open={true} />
    </div>
  );
};

export default FirstTimeLogin;

import { Box, Stack, borderRadius } from "@mui/system";
import React from "react";
import SummaryPlayerScoreCard from "./SummaryPlayerScoreCard";
import { Avatar, Grid, Paper, Typography } from "@mui/material";

const TeamScoreSummary = ({ summaryData }) => {
  console.log("summaryData==>", summaryData);
  return (
    <Box
      // width={"100%"}
      overflow={"auto"}
      border={"1px solid green"}
      sx={{ display: "flex", justifyContent: "center", flexDirection: "row" }}
      borderRadius={"15px"}
      p={2}
    >
      <Stack
        direction={"row"}
        width={"100%"}
        // overflow={"auto"}
        spacing={2}
        sx={{
          minWidth: "300px",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            marginTop: "2px",
            width: "2px",
            height: { xs: "2px", md: "5px" },
            bgcolor: "transparent",
            borderRadius: "6px",
            // display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            bgcolor: "white",
            height: "2px",

            borderRadius: "6px",
          },
        }}
      >
        {summaryData?.team?.members?.map((matchPlayerInfo, index) => {
          return (
            <Box
              minWidth={"260px"}
              key={index}
              p={1}
              px={2}
              mx={1}
              borderRight={"0.5px solid rgba(210,210,210, 0.2)"}
              component={Paper}
              borderRadius={"10px"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                justifyContent={"start"}
              >
                <Avatar
                  alt={`${matchPlayerInfo?.firstName} ${matchPlayerInfo?.lastName}`}
                  src={matchPlayerInfo?.profilePicture?.url}
                />
                <Typography>{`${matchPlayerInfo?.firstName} ${matchPlayerInfo?.lastName}`}</Typography>
              </Stack>
              <Stack width={"100%"}>
                <SummaryPlayerScoreCard
                  scoreName={"G1"}
                  score={matchPlayerInfo?.scores?.playerScores[0]?.score}
                />
                <SummaryPlayerScoreCard
                  scoreName={"G2"}
                  score={matchPlayerInfo?.scores?.playerScores[1]?.score}
                />
                <SummaryPlayerScoreCard
                  scoreName={"G3"}
                  score={matchPlayerInfo?.scores?.playerScores[2]?.score}
                />
                <SummaryPlayerScoreCard
                  scoreName={"HC"}
                  score={matchPlayerInfo?.scores?.handicapScore?.hca}
                />
                <SummaryPlayerScoreCard
                  scoreName={"AVG"}
                  score={matchPlayerInfo?.scores?.avgScore}
                />
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default TeamScoreSummary;

import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import Switch from "@mui/material/Switch";
import Grid from "@mui/system/Unstable_Grid/Grid";
const Toggler = ({ handletoggleOpenClose, days, index }) => {
  const toggleOpenClose = () => {
    handletoggleOpenClose(days, index);
  };
  useEffect(() => {}, [days?.open]);
  return (
    <Box>
     
      <Button
        type="button"
        onClick={toggleOpenClose}
        style={{
          backgroundColor: "transparent",
          border: "none",
          minWidth: "100px",
        }}
      >
        {days?.open ? (
          <Grid container>
            <ToggleOnIcon sx={{ color: "blue", fontSize: "40px" }} />
            <Typography sx={{ color: "white", paddingTop: "8px" }}>
              open
            </Typography>
          </Grid>
        ) : (
          <Grid container>
            <ToggleOffIcon sx={{ color: "red", fontSize: "40px" }} />
            <Typography sx={{ color: "white", paddingTop: "8px" }}>
              close
            </Typography>
          </Grid>
        )}
      </Button>
    </Box>
  );
};
export default Toggler;

import { TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";

const PlayerScores = ({
  playerData,
  setScoreValues,
  playerIndex,
  scoreValues,
  pFactor,
  bsScore,
}) => {
  // console.log("playerData=>", playerData);

  const handleUpdateScoreChange = (e, index) => {
    let temp = scoreValues;
    if (e.target.name === "handicapScore") {
      temp[index] = {
        ...temp[index],
        handicapScore: e.target.value,
      };
    } else {
      temp[index] = {
        ...temp[index],
        [e.target.name]: {
          ...temp[index][e.target.name],
          score: e.target.value,
        },
      };
    }
    setScoreValues([...temp]);
  };

  const calculateHC = (index) => {
    let temp = scoreValues;
    const avg = Math.floor(
      (temp[index].game1?.score * 1 +
        temp[index].game2?.score * 1 +
        temp[index].game3?.score * 1) /
        3
    );
    console.log("avg==>", avg);
    console.log("pfactor==>", pFactor);
    console.log("bass==>", bsScore);

    temp[index] = {
      ...temp[index],
      handicapScore: Math.floor(((bsScore - avg) * pFactor) / 100),
    };
    setScoreValues([...temp]);
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "& td , th": {
          border: "0",
        },
      }}
    >
      <TableCell component="th" scope="row">
        {playerData?.playerName}
      </TableCell>

      <TableCell align="center">
        {" "}
        <TextField
          type="number"
          required
          name="game1"
          value={playerData?.game1?.score}
          placeholder="XXX"
          onChange={(e) => handleUpdateScoreChange(e, playerIndex)}
          onBlur={() => calculateHC(playerIndex)}
          sx={{
            width: "58px",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
      </TableCell>
      <TableCell align="center">
        {" "}
        <TextField
          type="number"
          required
          name="game2"
          placeholder="XXX"
          onChange={(e) => handleUpdateScoreChange(e, playerIndex)}
          onBlur={() => calculateHC(playerIndex)}
          value={playerData?.game2?.score}
          sx={{
            width: "58px",
            textAlign: "center",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
      </TableCell>
      <TableCell align="center">
        {" "}
        <TextField
          type="number"
          required
          name="game3"
          placeholder="XXX"
          value={playerData?.game3?.score}
          onChange={(e) => handleUpdateScoreChange(e, playerIndex)}
          onBlur={() => calculateHC(playerIndex)}
          sx={{
            width: "58px",
            textAlign: "center",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
      </TableCell>
      <TableCell align="center">
        {" "}
        <TextField
          type="number"
          required
          name="handicapScore"
          placeholder="XXX"
          value={playerData?.handicapScore}
          // onChange={(e) => handleUpdateScoreChange(e, playerIndex)}
          disabled
          sx={{
            width: "58px",
            textAlign: "center",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            "& input .Mui-disabled ": {
              color: "green !important",
              opacity: 1,
              "-webkit-text-fill-color": "green !important",
            },
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default PlayerScores;

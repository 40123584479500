import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import AlleyLeagueCard from "./AlleyLeagueCard";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../redux/action/defaultActions";
function PreviousLeagues() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pastData, setPastData] = useState([]);
  const getApi = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/alley/past/league`);
      // console.log("res==>", res?.data?.alleyLeagues);
      setPastData(res?.data?.alleyLeagues);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  useEffect(() => {
    getApi();
  }, []);
  return (
    <Box>
      <Grid container spacing={1}>
        {/* console.log(pastData); */}
        {pastData.length > 0 ? (
          <>
            {pastData?.map((item, index) => {
              return (
                <Grid item xs={12} sx={6} md={5} lg={4}>
                  <AlleyLeagueCard
                    key={index}
                    logo={item?.league?.leagueImages?.url}
                    leagueName={item?.league?.leagueName}
                    to={`/past/leagues/${item?.league?._id}/`}
                  />
                </Grid>
              );
            })}
          </>
        ) : (
          <Box mt={2} p={2}>
            <Typography variant="h6" fontFamily={"Dosis"}>
              No Past Leagues
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
}
//  ALl 10 Gone, The Chunky Bunch, Gourilla Hands, Strike & Spare
export default PreviousLeagues;

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const ShowScoreTable = ({ game }) => {

    return (
        <Paper sx={{ width: '100%', overflowX: 'auto' }}>
            <TableContainer sx={{ borderCollapse: 'collapse', maxWidth: '100%', width: '100%', overflowX: 'auto' }}>
                <Table sx={{ border: "1px solid gray" }}>
                    <TableHead >
                        <TableRow sx={{ border: "1px solid gray" }}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((setNo, index) => {
                                return <TableCell key={index} sx={{ border: "1px solid gray", ":last-child": {background:'rgb(67, 191, 55)'}  }} align="center" colSpan={index === game.length-1 ? 3 : 2}>
                                    {setNo}
                                </TableCell>
                            })}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ background: "white" }}>
                            {game?.map((gameTb, index) => {
                                return (
                                    <>
                                        {gameTb?.score?.map((cd, index) => {
                                            return (
                                                <TableCell variant='body'  size='small' sx={{ border: "1px solid gray", p: 0, m: 0, color:'black', textAlign:'center', height:'35px' }}>

                                                    {cd.value}
                                                </TableCell>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </TableRow>
                        <TableRow sx={{ border: "1px solid gray", background: 'lightgray' }}>
                            {game?.map((gameTotal, index) => {
                                return (
                                    <>
                                        <TableCell variant='body' size='small' colSpan={index === game.length-1 ? 3 : 2} align='center' sx={{ border: "1px solid gray", p: 0, m: 0, color: 'black', ":last-child": {background:'rgb(67, 191, 55)'}, height: '35px' }}>
                                            {gameTotal.setTotal}
                                        </TableCell >
                                    </>
                                )
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    )
}

export default ShowScoreTable

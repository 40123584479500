import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import InputWIthIcon from "../../reusablecomponents/formcontrols/InputWIthIcon";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Url } from "../../url";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { IoMailOutline } from "react-icons/io5";
import Button1 from "../../reusablecomponents/buttons/Button1";
import { TfiKey } from "react-icons/tfi";

const ResetCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { emailId } = useParams();
  const [data, setData] = useState({ password: "", confirmPass: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      if (data?.password === data?.confirmPass) {
        const res = await axios.post(`${Url}/password/reset/${emailId}`, {
          password: data.password,
          confirmPassword: data.confirmPass,
        });

        console.log(res);
        if (res?.status === 200) {
          
          dispatch(isLoading(false));
          dispatch(openSnackbar("Reset Password Successfully", "success"));
          setData("");
          navigate("/login");
        }
      } else {
        dispatch(isLoading(false));
        dispatch(openSnackbar("confirm password mismatch", "error"));
      }
    } catch (error) {
      dispatch(isLoading(false));
      dispatch(openSnackbar("Something went wrong", "error"));
      console.log(error);
    }
  };
  return (
    <Card
      sx={{
        minWidth: 275,
        p: 1,
        py: 2,
        background:
          " linear-gradient(90deg, rgba(22,38,0,0.9808298319327731) 11%, rgba(0,9,0,0.9864320728291317) 98%)",
        border: "1px solid rgb(52 78 37)",
        borderRadius: "10px",
      }}
    >
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Stack>
            <Box
              component={"img"}
              height={"53px"}
              src="/assets/logo/Vector.svg"
            />
            <Typography
              variant="h4"
              mt={1}
              textAlign={"center"}
              fontWeight={"600"}
              letterSpacing={"1.5px"}
              // fontSize={"25px"}
              fontFamily={"Dosis"}
            >
              Pocket League
            </Typography>
            <Typography
              mt={1}
              textAlign={"center"}
              fontWeight={"300"}
              letterSpacing={"2px"}
              fontSize={"18px"}
              fontFamily={"Dosis"}
              color={"rgba(111, 190, 68, 1)"}
            >
              Unite Through Gaming
            </Typography>
            <Typography
              variant="h4"
              mt={1}
              textAlign={"center"}
              fontWeight={"400"}
              letterSpacing={"1px"}
              fontSize={"20px"}
              fontFamily={"Dosis"}
              textTransform={"capitalize"}
            >
             Create New Password
            </Typography>
          </Stack>
          <Stack>
            <InputWIthIcon
              startAdornment={<TfiKey style={{ fontSize: "26px" }} />}
              inputName={"password"}
              inputType={"password"}
              inputPlaceholder={"Enter Password"}
              onChangeHandler={(e) =>
                setData({ ...data, password: e.target.value })
              }
            />
            <InputWIthIcon
              startAdornment={<TfiKey style={{ fontSize: "26px" }} />}
              inputName={"confirmpass"}
              inputType={"password"}
              inputPlaceholder={"Re-Enter Password"}
              onChangeHandler={(e) =>
                setData({ ...data, confirmPass: e.target.value })
              }
            />
          </Stack>
        </CardContent>

        <CardActions sx={{ paddingX: "16px" }}>
          <Button1
            buttonType={"submit"}
            size="small"
            fontSize={"23px"}
            name="Sign In"
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default ResetCart;

import { Grid, Typography } from "@mui/material";
import React from "react";

const SummaryPlayerScoreCard = ({ scoreName, score }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={5}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"start"}
      >
        <Typography sx={{ color: "green !important", fontWeight: "bold" }}>
          {scoreName}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography variant="h6">:</Typography>
      </Grid>
      <Grid
        item
        xs={5}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
      >
        <Typography sx={{ color: "white !important", fontWeight: "bolder" }}>
          {score}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SummaryPlayerScoreCard;

import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import Button1 from "../../reusablecomponents/buttons/Button1";
import { Url } from "../../url";
import { MuiOtpInput } from "mui-one-time-password-input";

const OtpCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { emailId } = useParams();
  const [otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("otp=>", otp);
    dispatch(isLoading(true));
    // Make API call to verify the OTP
    try {
      const res = await axios.post(`/api/v1/alley/password/otp/verify/${emailId}`, {
        verifyOTP: otp,
      });
      console.log("res===>",res)
      if (res?.data?.success) {
        dispatch(isLoading(false));
        dispatch(openSnackbar("logged in Successfully", "success"));
        navigate(`/resetPassword/${emailId}`);
      }
      else if (res.data.err) {
        dispatch(isLoading(false))
        dispatch(openSnackbar(res.data.message, "error"));
      }

      setOtp("");
    } catch (error) {
      console.log(error.message);
      dispatch(isLoading(false));
      dispatch(openSnackbar("something went wrong", "error"));
    }
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        p: 1,
        py: 2,
        background:
          " linear-gradient(90deg, rgba(22,38,0,0.9808298319327731) 11%, rgba(0,9,0,0.9864320728291317) 98%)",
        border: "1px solid rgb(52 78 37)",
        borderRadius: "10px",
      }}
    >
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Stack>
            <Box
              component={"img"}
              height={"53px"}
              src="/assets/logo/Vector.svg"
            />
            <Typography
              variant="h4"
              mt={1}
              textAlign={"center"}
              fontWeight={"600"}
              letterSpacing={"1.5px"}
              // fontSize={"25px"}
              fontFamily={"Dosis"}
            >
              Pocket League
            </Typography>
            <Typography
              mt={1}
              textAlign={"center"}
              fontWeight={"300"}
              letterSpacing={"2px"}
              fontSize={"18px"}
              fontFamily={"Dosis"}
              color={"rgba(111, 190, 68, 1)"}
            >
              Unite Through Gaming
            </Typography>
            <Typography
              variant="h4"
              mt={1}
              textAlign={"center"}
              fontWeight={"400"}
              letterSpacing={"1px"}
              // fontSize={"25px"}
              fontFamily={"Dosis"}
              textTransform={"capitalize"}
            >
             Verify OTP
            </Typography>
          </Stack>
          <Stack>
            <MuiOtpInput length={4} value={otp} onChange={handleChange} />
          </Stack>
        </CardContent>
        <CardActions sx={{ paddingX: "16px" }}>
          <Button1
            buttonType={"submit"}
            size="small"
            fontSize={"23px"}
            name="Sign In"
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default OtpCart;

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import dayjs from "dayjs";
import React from "react";

const MatchCards = ({ teamNameA, teamNameB, dateOfMatch, timeOfMatch }) => {
  return (
    // <Card
    //   sx={{
    //     borderRadius: "10px",
    //     mt: 2,
    //     border: "1px solid rgb(217 207 206 / 15%)",
    //     minWidth: 280,
    //     maxWidth: 340,
    //     background:
    //       " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
    //   }}
    // >
    //   <CardContent>
    //     <Stack
    //       direction={"row"}
    //       justifyContent={"start"}
    //       spacing={1}
    //       alignItems={"center"}
    //     >
    //       <Stack direction={"row"} alignItems={"center"} spacing={1}>
    //         <Avatar alt={teamNameA} src="/assets/" />
    //         <Typography variant="body2" component={"span"} fontWeight={"200"}>
    //           {teamNameA}
    //         </Typography>
    //       </Stack>
    //       <Typography
    //         variant="h6"
    //         fontWeight={"200"}
    //         fontSize={"15px"}
    //         sx={{ color: "rgb(67, 191, 55)" }}
    //         component={"span"}
    //       >
    //         vs
    //       </Typography>
    //       <Stack direction={"row"} alignItems={"center"} spacing={1}>
    //         <Avatar alt={teamNameB} src="/assets/" />
    //         <Typography variant="body2" component={"span"} fontWeight={"200"}>
    //           {teamNameB}{" "}
    //         </Typography>
    //       </Stack>
    //     </Stack>
    //   </CardContent>
    // </Card>
    <Card
      // onClick={() =>
      //   navigate(
      //     `/active/leagues/update-team-scrores/${live_match?.leagueId}`,
      //     { state: { matchInfo: live_match } }
      //   )
      // }
      sx={{
        // position: "relative",
        borderRadius: "10px",
        cursor: "pointer",
        mt: 1.5,
        border: "1px solid rgb(217 207 206 / 15%)",
        minWidth: 280,
        maxWidth: 320,
        background:
          " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
        "&:hover": {
          background:
            " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 100%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 25%)",
        },
      }}
    >
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          spacing={1}
          alignItems={"center"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar
              // style={{ height: "40px", width: "40px" }}
              alt={teamNameA}
              src={"/assets/images/"}
            />
            <Typography variant="body2" component={"span"} fontWeight={"200"}>
              {teamNameA}
            </Typography>
          </Stack>
          <Typography
            variant="h6"
            fontWeight={"200"}
            fontSize={"15px"}
            sx={{ color: "rgb(67, 191, 55)" }}
            component={"span"}
          >
            vs
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar
              // style={{ height: "40px", width: "40px" }}
              alt={teamNameB}
              src={"/assets/images"}
            />
            <Typography variant="body2" component={"span"} fontWeight={"200"}>
              {teamNameB}
            </Typography>
          </Stack>
        </Stack>
        <Box
          p={0.5}
          border={"1px solid grey"}
          borderRadius={"15px"}
          px={2}
          sx={{
            position: "absolute",
            top: "2px",
            left: "12px",
            zIndex: 22,
            background: "rgba(46, 46, 46, 1)",
            // background:
            //   " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Stack direction={"row"}>
            <Typography sx={{ color: "white", fontSize: "12px" }}>
              {dayjs(dateOfMatch).format("DD MMM YYYY")} &nbsp;&nbsp;
              <span
                style={{
                  color: "rgb(67, 191, 55)",
                  // marginRight: "1rem",
                  // marginLeft: "1rem",
                }}
              >
                {dayjs(timeOfMatch).format("hh:mm A")}
              </span>
            </Typography>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MatchCards;

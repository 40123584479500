import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import LiveScore from "./LiveScore";

const AllMatchesScore = () => {
  return (
    <>
      <Grid container spacing={1}>
        {[1, 2, 3, 4].map(() => {
          return (
            <Grid item sx={6} mt={1}>
              <Card>
                <CardContent>
                  <LiveScore />
                </CardContent>
                <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default AllMatchesScore;

import { createTheme } from "@mui/material";
const MuiTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000000",
      contrastText: "#00ff0c",
    },
    secondary: {
      main: "#10e81f",
    },
    background: {
      Paper: "#070707",
      default: "#000000",
      paper: "rgba(16, 16, 16, 1)",
    },
    type: "dark",
    text: {
      primary: "rgba(249,246,246,0.87)",
      secondary: "#777676",
      disabled: "rgba(84,82,82,0.38)",
      hint: "rgba(230,11,11,0.38)",
    },
    green: {
      color: "rgba(111, 190, 68, 1)",
    },
    divider: "rgba(228,239,230,0.12)",
  },
  typography: {
    htmlFontSize: 16,
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
        variant: "contained",
      },
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          padding: "12px 20px",
          fontWeight: "600",
          borderRadius: "10px",
          textTransform: "capitalize",
          backgroundColor: "rgba(0,172,6,1)",
          "&:hover": {
            backgroundColor: "rgba(0,172,6,0.7)",
            boxShadow: "none",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#191919",
          color: "#74BF4C",
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
        color: "rgba(111, 190, 68, 1)",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
        shrink: true,
        disableAnimation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          transform: "none",
          position: "static",
          color: "'#333333",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
        backgroundColor: "rgba(111, 190, 68, 1)",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        shrink: true,
        disableAnimation: true,
        color: "secondary",
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          transform: "none",
          position: "static",
          "& .MuiInputBase-root": {
            border: "1px solid grey",
            "&:focused": {
              border: "1px solid #0D507D",
            },
          },
          "& .Mui-focused fieldset": {
            border: "none",
            outline: "none",
          },
        },
      },
    },
    MuiList: {
      defaultProps: {
        dense: false,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
        color: "rgba(111, 190, 68, 1)",
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
export default MuiTheme;

import * as React from "react";
// import './upcomingMatches.css';

import Box from "@mui/material/Box";

import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import axios from "axios";
import dayjs from "dayjs";
import LeagueCards from "../../../components/cards/LeagueCards";
import MatchCards from "../../../components/cards/MatchCards";

export default function UpcomingMatchesMui() {
  const [upcomingMatchesData, setUpcomingMatchesData] = React.useState([]);

  const getApiData = async () => {
    const res = await axios.get(
      "/api/v1/league/active/leagues/upcoming/matches"
    );
    setUpcomingMatchesData(res?.data);
    console.log(res?.data);
  };
  React.useEffect(() => {
    getApiData();
  }, []);

  return (
    <>
      <Stack spacing={3} justifyContent={"center"}>
        <Box
          borderRadius={"5px"}
          p={2}
          component={Paper}
          py={2}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            p={1}
            py={1}
          >
            <Typography variant="h6" >Upcoming Matches </Typography>
            {/* <Typography
              fontFamily={"Dosis"}
              to="/"
              sx={{
                textDecoration: "none",
                color: "rgbac(154 148 155,1)",
                "&:hover": {
                  color: "rgb(67, 191, 55)",
                  textDecoration: "2px underline",
                  textDecorationColor: "green",
                },
              }}
            >
              Filter
            </Typography> */}
          </Stack>
          <Grid container spacing={1}>
            {upcomingMatchesData?.leaguesMatches?.length > 0 ? (
              upcomingMatchesData?.leaguesMatches?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Stack mt={2}>
                      <Typography sx={{ color: "white" }}>
                        {dayjs(item?.matches?.teamMatchDate).format(
                          "dddd, MMMM D, YYYY"
                        )}
                        <span style={{ color: "rgb(67, 191, 55)" }}>
                          {dayjs(item?.matches?.teamMatchTiming).format(
                            "h:mm:ss a"
                          )}
                        </span>
                      </Typography>
                    </Stack>
                    <Grid
                      item
                      xs={12}
                      sx={6}
                      md={5}
                      lg={4}
                      position={"relative"}
                    >
                      {/* <LeagueCards
                      key={index}
                      logo={""}
                      teamNameA={item?.matches?.teamA?.teamName}
                      teamNameB={item?.matches?.teamB?.teamName}
                    /> */}
                      <MatchCards
                        teamNameA={item?.matches?.teamA?.teamName}
                        teamNameB={item?.matches?.teamB?.teamName}
                        dateOfMatch={item?.matches?.teamMatchDate}
                        timeOfMatch={item?.matches?.teamMatchTimin}
                      />
                    </Grid>
                  </React.Fragment>
                );
              })
            ) : (
              <Box width={"100%"} height={"100%"}>
                <Typography
                  mt={3}
                  variant="h3"
                  sx={{ fontFamily: "Dosis", textAlign: "center" }}
                >
                  OOP'S SORRY NO MATCHES
                </Typography>
                <Box
                  mt={2}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    component={"img"}
                    alt="sorry"
                    width={"250px"}
                    src="/assets/images/bowling-breaks.png"
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { isLoading } from "../../redux/action/defaultActions";

const LeagueCards = ({
  logo,
  leagueName,
  matchType,
  redirectTo,
  matchData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Card
      onClick={() => {
        dispatch(isLoading(true));
        navigate(`${redirectTo}/${matchData?._id}/`);
      }}
      sx={{
        borderRadius: "10px",
        mt: 2,
        border: "1px solid rgb(217 207 206 / 15%)",
        cursor: "pointer",
        minWidth: 250,
        maxWidth: 340,
        background:
          " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
      }}
    >
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent={"start"}
          spacing={1}
          alignItems={"center"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <img style={{ height: "40px", width: "40px" }} src={logo} />
            <Typography variant="body2" component={"span"} fontWeight={"200"}>
              {leagueName}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default LeagueCards;

import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { isLoading } from "../../../redux/action/defaultActions";
import axios from "axios";
import ListOfDetails from "./ListOfDetails";
import ListOfTeams from "./ListOfTeams";

const DetailsActiveLegue = () => {
  const { leagueId } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [team, setTeam] = useState([]);
  //******************* */ api hit get team league details data  *************************
  const getData = async () => {
    dispatch(isLoading(true));
    try {
      dispatch(isLoading(false));
      const res = await axios.get(
        `/api/v1/league/alley/single/league/${leagueId}`
      );
      // console.log("details", res?.data?.alleyLeague);
      setData(res?.data?.alleyLeague);
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error)``;
    }
  };
  //******************* */ api hit get total team number *************************
  const getTeamData = async () => {
    dispatch(isLoading(true));
    try {
      dispatch(isLoading(false));
      const res = await axios.get(`/api/v1/league/teams/list/${leagueId}`);
      // console.log("team==>", res?.data?.alleyLeagueTeams?.leagueTeams);
      setTeam(res?.data?.alleyLeagueTeams?.leagueTeams);
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  React.useEffect(() => {
    getData();
    getTeamData();
  }, []);
  const listFirst = [
    { primaryData: "Type", data: "Quad" },
    { primaryData: "League Size", data: "50 Teams" },
    { primaryData: "Matches per team", data: "4 matches" },
    { primaryData: "Starting on", data: "Wednesday, 29 June" },
    { primaryData: "Timing", data: "06:00 PM CST" },
    { primaryData: "Interval of match", data: "3 days" },
    { primaryData: "Ends", data: "Wednesday, 29 August" },
  ];

  const listSecond = [
    { image: "/assets/images/Rectangle n1.png", primaryData: "Team Almeria" },
    {
      image: "/assets/images/Rectangle n3.png",
      primaryData: "Satletico Madrid",
    },
    {
      image: "/assets/images/Rectangle n2.png",
      primaryData: "Atletico Madrid",
    },
    {
      image: "/assets/images/Rectangle n1.png",
      primaryData: "Atletico Madrid",
    },
    {
      image: "/assets/images/Rectangle n2.png",
      primaryData: "Atletico Madrid",
    },
    {
      image: "/assets/images/Rectangle n3.png",
      primaryData: "Atletico Madrid",
    },
  ];

  return (
    <Box mt={1} p={1}>
      <Grid container mt={2} spacing={1} justifyContent={"space-between"}>
        <Grid
          item
          xs={12}
          md={5.8}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
          border={"1px solid rgba(255, 255, 255, 0.1)"}
          borderRadius={"5px"}
          component={Paper}
        >
          <ListOfDetails listData={data} />
        </Grid>
        <Grid
          item
          xs={12}
          md={5.8}
          component={Paper}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
          border={"1px solid rgba(255, 255, 255, 0.1)"}
          borderRadius={"5px"}
        >
          <ListOfTeams teamList={team} data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailsActiveLegue;

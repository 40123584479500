import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useDispatch } from "react-redux";


const PictureSection = (props) => {


  return (
    <ImageList sx={{ minWidth: "250px", rowHeight: "164px" }} cols={3}>
      {props?.images?.map((item, index) => (
        <ImageListItem key={index}>
          <img
            style={{ position: "relative" }}
            src={`${item?.url}`}
            alt={`${item.title} ${index}`}
            loading="lazy"
          />
          {props?.edit ? (
            <IconButton
              aria-label="delete"
              sx={{
                zIndex: 99,
                textAlign: "center",
                borderRadius: "50%",
                color: "white",
                position: "absolute",
                bgcolor: "rgba(0,0,0 ,0.6)",
                top: 0,
                right: 5,
                "&:hover": {
                  bgcolor: "rgba(0,0,0 ,0.8)",
                },
              }}
              // onClick={() => removeImage(pic.id)}
            >
              <CloseIcon onClick={() => props?.handleDeleteImage(item._id)} />
            </IconButton>
          ) : (
            ""
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default PictureSection;

// ListOfTeams
import React from "react";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const ListOfTeams = ({ teamList, data }) => {
  return (
    <List sx={{ p: 1 }}>
      <div
        className="heading1 pb-4"
        style={{
          color: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span className="float-start">
          Teams &nbsp;
          <span style={{ color: "rgb(67, 191, 55)" }}>
            {teamList.length}/{data?.numberOfTeam}
          </span>
        </span>
        {/* <a className="seeAll" style={{ color: "rgb(67, 191, 55)" }} href="#">
          See All
        </a> */}
      </div>
      <Divider variant="inset" component="li" />
      {teamList?.map((data, index) => {
        return (
          <>
            <ListItem
              alignItems="flex-start"
              key={index}
              sx={{ alignItems: "center" }}
            >
              <Avatar
                alt={data?.teamName.trim()}
                sx={{ textTransform: "capitalize" }}
                src="/static/images/avatar/1.jpg"
              />
              &nbsp;&nbsp;
              <ListItemText
                primary={data?.teamName}
                sx={{
                  textTransform: "capitalize",
                }}
              />
              <button
                style={{
                  background: "transparent",
                  color: "white",
                  border: "none",
                }}
              >
                <KeyboardArrowRightIcon />
              </button>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        );
      })}
    </List>
  );
};
export default ListOfTeams;

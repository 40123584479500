import { Avatar, CardHeader, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShowScoreTable from "./ShowScoreTable";

const teamData = [
  {
    teamName: "Team A",
    numberOfPlayers: 4,
    players: [
      {
        name: "AJ Chapman",
        image: "/assets/images/AjChapman.png",
        gameNum: 1,
        total: 67,
        gameData: [
          {
            set: 1,
            score: [
              { value: 6 },
              { value: 6 },
              // ,{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},
            ],
            setTotal: 12,
          },
          {
            set: 2,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 3,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 4,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 5,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 6,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 7,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 8,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 9,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 10,
            score: [{ value: 6 }, { value: 6 }, { value: 6 }],
            setTotal: 18,
          },
        ],
      },

      {
        name: "Riu",
        image: "/assets/images/Riu.png",
        gameNum: 1,
        total: 67,
        gameData: [
          {
            set: 1,
            score: [
              { value: 6 },
              { value: 6 },
              // ,{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},
            ],
            setTotal: 12,
          },
          {
            set: 2,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 3,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 4,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 5,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 6,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 7,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 8,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 9,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 10,
            score: [{ value: 6 }, { value: 6 }, { value: 6 }],
            setTotal: 18,
          },
        ],
      },
      {
        name: "Riu",
        image: "/assets/images/Riu.png",
        gameNum: 2,
        total: 56,
        gameData: [
          {
            set: 1,
            score: [
              { value: 6 },
              { value: 6 },
              // ,{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},
            ],
            setTotal: 12,
          },
          {
            set: 2,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 3,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 4,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 5,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 6,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 7,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 8,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 9,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 10,
            score: [{ value: 6 }, { value: 6 }, { value: 6 }],
            setTotal: 18,
          },
        ],
      },
      {
        name: "Riu",
        image: "/assets/images/Riu.png",
        gameNum: 3,
        total: 78,
        gameData: [
          {
            set: 1,
            score: [
              { value: 6 },
              { value: 6 },
              // ,{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},
            ],
            setTotal: 12,
          },
          {
            set: 2,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 3,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 4,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 5,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 6,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 7,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 8,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 9,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 10,
            score: [{ value: 6 }, { value: 6 }, { value: 6 }],
            setTotal: 18,
          },
        ],
      },
    ],
  },
  {
    teamName: "Team B",
    numberOfPlayers: 4,
    players: [
      {
        name: "AJ Chapman",
        image: "/assets/images/AjChapman.png",
        gameNum: 1,
        total: 67,
        gameData: [
          {
            set: 1,
            score: [
              { value: 6 },
              { value: 6 },
              // ,{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},
            ],
            setTotal: 12,
          },
          {
            set: 2,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 3,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 4,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 5,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 6,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 7,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 8,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 9,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 10,
            score: [{ value: 6 }, { value: 6 }, { value: 6 }],
            setTotal: 18,
          },
        ],
      },
      {
        name: "Riu",
        image: "/assets/images/Riu.png",
        gameNum: 1,
        total: 67,
        gameData: [
          {
            set: 1,
            score: [
              { value: 6 },
              { value: 6 },
              // ,{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},
            ],
            setTotal: 12,
          },
          {
            set: 2,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 3,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 4,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 5,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 6,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 7,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 8,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 9,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 10,
            score: [{ value: 6 }, { value: 6 }, { value: 6 }],
            setTotal: 18,
          },
        ],
      },
      {
        name: "Riu",
        image: "/assets/images/Riu.png",
        gameNum: 2,
        total: 56,
        gameData: [
          {
            set: 1,
            score: [
              { value: 6 },
              { value: 6 },
              // ,{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},{"value":6},
              //     {"value":6},
            ],
            setTotal: 12,
          },
          {
            set: 2,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 3,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 4,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 5,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 6,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 7,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 8,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 9,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 10,
            score: [{ value: 6 }, { value: 6 }, { value: 6 }],
            setTotal: 18,
          },
        ],
      },
      {
        name: "Riu",
        image: "/assets/images/Riu.png",
        gameNum: 3,
        total: 78,
        gameData: [
          {
            set: 1,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 2,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 3,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 4,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 5,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 6,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 7,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 8,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 9,
            score: [{ value: 6 }, { value: 6 }],
            setTotal: 12,
          },
          {
            set: 10,
            score: [{ value: 6 }, { value: 6 }, { value: 6 }],
            setTotal: 18,
          },
        ],
      },
    ],
  },
];

const LiveScore = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Stack>
        <Typography fontSize={30}>
          <ArrowBackIcon />
          <span style={{ marginLeft: "0.5em" }}>Live: Team A vs Team B</span>
        </Typography>
      </Stack>
      <Stack
        sx={{
          background:
            " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
        }}
      >
        {teamData.map((tdata, index) => {
          return (
            <>
              <Stack
                mt={2}
                key={index}
                p={1}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{
                  backgroundColor: "#25400B",
                }}
              >
                <Typography>{tdata.teamName}</Typography>
                <Typography>Players: {tdata.numberOfPlayers}</Typography>
              </Stack>
              <Stack>
                {tdata?.players?.map((pdata, index) => {
                  return (
                    <>
                      <Grid key={index} container>
                        <Grid item md={6}>
                          <CardHeader
                            avatar={
                              <Avatar alt={pdata.name} src={pdata.image} />
                            }
                            title={pdata.name}
                          />
                        </Grid>
                        <Grid
                          item
                          mt={1}
                          md={6}
                          display={"flex"}
                          flexDirection={"column"}
                          alignContent={"end"}
                        >
                          <Stack alignItems={"end"}>Game {pdata.gameNum}</Stack>
                          <Stack alignItems={"end"}>Total {pdata.total}</Stack>
                        </Grid>
                      </Grid>
                      <Stack>
                        <ShowScoreTable game={pdata?.gameData} />
                      </Stack>
                    </>
                  );
                })}
              </Stack>
            </>
          );
        })}
      </Stack>
    </Box>
  );
};

export default LiveScore;

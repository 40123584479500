import { Box, Stack } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import AdminPanel from "./pages/dashboard/AdminPanel";
import ThemeComponents from "./pages/ThemeComponents";
import Login from "./pages/login/Login";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar, isLoading } from "./redux/action/defaultActions";
import Forget from "./pages/Forget/Forget";
import Loader from "./reusablecomponents/isLoading/Loader";
import OtpVerification from "./pages/OtpVerification/OtpVerification";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import CreateNewLeague from "./pages/league/CreateNewLeague";
import UpcomingLeague from "./pages/league/UpcomingLeague";
import PastLeagues from "./pages/league/PastLeagues";
import MyProfile from "./pages/profile/MyProfile";
import AccountSettings from "./pages/profile/AccountSettings";
import Address from "./pages/profile/Address";
import ManagePassword from "./pages/profile/ManagePassword";
import AllPlayersTable from "./pages/players/AllPlayers";
import LeagueByPlayers from "./pages/players/LeaguesByPlayers";
import LeagueInfo from "./pages/players/LeagueInfo";
import LeagueDashboard from "./pages/league/leagueDashboard/LeagueDashboard";
import OngoingLeague from "./pages/league/leagueDashboard/OngoingLeague";
import FutureLeague from "./pages/league/leagueDashboard/FutureLeague";
import PreviousLeagues from "./pages/league/leagueDashboard/PreviousLeagues";
import Home from "./pages/dashboard/home/Home";
import ActiveLeagues from "./pages/league/ActiveLeagues";
import ActiveLeagueMainPage from "./pages/league/viewActiveLeagues/ActiveLeagueMainPage";
import OverView from "./pages/league/viewActiveLeagues/OverView";
import ActiveLeagueTeams from "./pages/league/viewActiveLeagues/ActiveLeagueTeams";
import Matches from "./pages/league/viewActiveLeagues/Matches";
import LiveMatches from "./pages/league/viewActiveLeagues/LiveMatches";
import UpcomingMatchesMui from "./pages/league/viewActiveLeagues/UpcomingMatches";
import PastMatches from "./pages/league/viewActiveLeagues/PastMatches";
import TableActiveLeague from "./pages/league/viewActiveLeagues/TableActiveLeague";
import DetailsActiveLegue from "./pages/league/viewActiveLeagues/DetailsActiveLegue";
import PastLeagueMainPage from "./pages/league/viewPastLeagues/PastLeagueMainPage";
import PastDetailsPage from "./pages/league/viewPastLeagues/PastDetailsPage";
import PastTeamsPage from "./pages/league/viewPastLeagues/PastTeamsPage";
import PastMatchesPage from "./pages/league/viewPastLeagues/PastMatchesPage";
import ResultPage from "./pages/league/viewPastLeagues/resultPage";
import UpcomingLeagueMainPage from "./pages/league/viewUpcomingLeagues/UpcomingLeaguePage";
import UpcomingDetails from "./pages/league/viewUpcomingLeagues/UpcomingDetails";
import UpcomingTeams from "./pages/league/viewUpcomingLeagues/UpcomingTeams";
import UpcomingMatchesPage from "./pages/league/viewUpcomingLeagues/UpcomingMatchesPage";
import GalleryImage from "./pages/Gallery/GalleryImage";
import CreateRole from "./pages/role-permission/CreateRole";
import LiveMatch from "./pages/live-matches/LiveMatch";
import AllMatchesScore from "./pages/live-matches/AllMatchesScore";
import BusinessHours from "./pages/BusinessHours/BusinessHours";
import UpdateTeamScores from "./pages/score/UpdateTeamScores";
import MatchSummary from "./pages/score/MatchSummary";
import AllMatchesScoreUpdateHomeScreen from "./pages/score/AllMatchesScoreUpdateHomeScreen";
import Wallet from "./pages/wallet/Wallet";
import PopularLeagues from "./pages/league/PopularLeagues";
import RaiseWithdrawalRequest from "./pages/wallet/RaiseWithdrawalRequest";
import axios from "axios";
import { updateWallet } from "./redux/action/adminActions";
import { useEffect } from "react";
import FirstTimeLogin from "./pages/login/FirstTimeLogin";

import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/terms&Conditions/TermsAndConditions";

function App() {
  const { snackbar, alley } = useSelector((state) => state);
  console.log("alley=>", alley);
  // axios.defaults.baseURL = "https://pocketleague.app/";
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  const getWalletInfo = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/alley/current/wallet/balance`);
      dispatch(updateWallet(res?.data?.walletBalance?.wallet));
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getWalletInfo();
  }, []);

  // console.log("snack=>", snackbar);
  return (
    <>
      <Loader />
      <Snackbar
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={snackbar?.severity}
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <Box>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          <Route
            path="/otpVerification/:emailId"
            element={<OtpVerification />}
          />
          <Route path="/resetPassword/:emailId" element={<ResetPassword />} />
          {alley?.isAuthenticated && !alley.firstTimeLogin ? (
            <Route path="/" element={<AdminPanel />}>
              <Route index element={<Home />} />
              <Route path="/activeLeagues" element={<ActiveLeagues />} />
              <Route path="/players" element={<AllPlayersTable />} />
              <Route path="/gallery" element={<GalleryImage />} />
              <Route path="/roles" element={<CreateRole />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route
                path="/wallet/raise-withdrawal-request"
                element={<RaiseWithdrawalRequest />}
              />
              <Route path="/businessHour" element={<BusinessHours />} />
              {/* routes to manage leagues */}
              <Route path="/leagues/" element={<LeagueDashboard />}>
                <Route index element={<OngoingLeague />} />
                <Route
                  path="/leagues/past-leagues"
                  element={<PreviousLeagues />}
                />
                <Route
                  path="/leagues/upcoming-leagues"
                  element={<FutureLeague />}
                />
              </Route>
              {/* end of all routes to manage leagues*/}

              {/* routes for active leagues status */}
              <Route
                path="/active/leagues/:leagueId"
                element={<ActiveLeagueMainPage />}
              >
                <Route
                  //  index
                  element={<OverView />}
                />
                <Route
                  index
                  // path="/active/leagues/:leagueId/teams"
                  element={<ActiveLeagueTeams />}
                />
                <Route
                  path="/active/leagues/:leagueId/matches/"
                  element={<Matches />}
                >
                  <Route index element={<LiveMatches />} />

                  <Route
                    path="/active/leagues/:leagueId/matches/upcoming-matches"
                    element={<UpcomingMatchesMui />}
                  />
                  <Route
                    path="/active/leagues/:leagueId/matches/past-matches"
                    element={<PastMatches />}
                  />
                </Route>
                <Route
                  path="/active/leagues/:leagueId/table"
                  element={<TableActiveLeague />}
                />
                <Route
                  path="/active/leagues/:leagueId/details"
                  element={<DetailsActiveLegue />}
                />
              </Route>
              {/* routes for upcoming status */}

              {/*  */}
              <Route
                path="/active/leagues/live-match/:matchId"
                element={<LiveMatch />}
              />
              <Route path="/active/leagues/matches" element={<LiveMatch />} />
              <Route
                path="/active/leagues/all-live-scores"
                element={<AllMatchesScore />}
              />
              <Route
                path="/active/leagues/update-team-scrores/:leagueId"
                element={<UpdateTeamScores />}
              />
              {/* ===========match summary ========== */}
              <Route
                path="/active/leagues/update-team-scrores/match-summary/:leagueId"
                element={<MatchSummary />}
              />
              {/* ===========match summary ======== */}
              {/*  */}
              <Route
                path="/active/leagues/teams/:teamId"
                element={<h2>active team</h2>}
              />
              {/* end or routes for active leauges */}
              {/* routes for past leagues status */}
              <Route
                path="/past/leagues/:leagueId/"
                element={<PastLeagueMainPage />}
              >
                <Route
                  //  index
                  element={<PastDetailsPage />}
                />
                <Route
                  index
                  // path="/past/leagues/:leagueId/teams"
                  element={<PastTeamsPage />}
                />

                <Route
                  path="/past/leagues/:leagueId/matches"
                  element={<PastMatchesPage />}
                />
                <Route
                  path="/past/leagues/:leagueId/result"
                  element={<ResultPage />}
                />
              </Route>

              <Route
                path="/past/leagues/teams/:teamId"
                element={<h2>Upcoming team </h2>}
              />
              {/* end or routes for past leauges */}
              {/* routes for upcoming leagues status */}
              <Route
                path="/upcoming/leagues/:leagueId/"
                element={<UpcomingLeagueMainPage />}
              >
                <Route index element={<UpcomingDetails />} />
                <Route
                  path="/upcoming/leagues/:leagueId/matches"
                  element={<UpcomingMatchesPage />}
                />
                <Route
                  path="/upcoming/leagues/:leagueId/teams"
                  element={<UpcomingTeams />}
                />
              </Route>

              <Route
                path="/upcoming/leagues/matches/:matchId"
                element={<h2>Upcoming</h2>}
              />
              <Route
                path="/upcoming/leagues/teams/:teamId"
                element={<h2>Upcoming</h2>}
              />
              {/* end or routes for upcoming leauges */}
              <Route
                path="/players/leagues-by-players"
                element={<LeagueByPlayers />}
              />
              <Route
                path="/league/league-info/:leagueId"
                element={<LeagueInfo />}
              />

              <Route path="/createNewLeague" element={<CreateNewLeague />} />
              <Route path="/upcomingLeagues" element={<UpcomingLeague />} />
              <Route path="/pastLeagues" element={<PastLeagues />} />
              <Route path="/popularLeagues" element={<PopularLeagues />} />
              {/* all routes for profile */}
              <Route path="/myProfile/" element={<MyProfile />}>
                <Route index element={<AccountSettings />} />
                <Route path="/myProfile/address" element={<Address />} />
                <Route
                  path="/myProfile/manage-password"
                  element={<ManagePassword />}
                />
                <Route
                  path="/myProfile/payment-method"
                  element={<AccountSettings />}
                />
                {/* <Route path="/myProfile/timing" element={<AccountSettings />} /> */}
              </Route>
              <Route
                path="/all/ongoing/day/matches"
                element={<AllMatchesScoreUpdateHomeScreen />}
              />

              {/*end of all routes for profile */}
              <Route path="/components" element={<ThemeComponents />} />
            </Route>
          ) : alley.isAuthenticated && alley.firstTimeLogin ? (
            <>
              <Route path="/" element={<FirstTimeLogin />} />
              <Route path="/first-time-login" element={<FirstTimeLogin />} />
            </>
          ) : (
            <Route path="/" element={<Login />} />
          )}
        </Routes>
      </Box>
    </>
  );
}

export default App;

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
// export default function ControlledSwitches() {
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
export default function LeagueByPlayers() {
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ********************Get All Players Details function********************
  const getData = async () => {
    dispatch(isLoading(true));
    try {
      dispatch(isLoading(false));
      const res = await axios.get(`/api/v1/league/alley/all/leagues/web`);
      // console.log("leaked by leaked", res?.data?.alleyLeagues);
      setData(res?.data?.alleyLeagues);
    } catch (error) {
      dispatch(isLoading(false));
      dispatch(openSnackbar("something went wrong", "error"));
      console.log(error.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="white"
      href="/"
      onClick={handleClick}
    >
      All Leagues
    </Link>,
    <Typography key="2" color="text.primary">
      A J Chapman
    </Typography>,
  ];
  return (
    <Box width={"100%"}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "#4D4C4C" }}>
            <TableRow
              sx={{ bgcolor: "rgba(51, 51, 51, 1)", columnWidth: "100%" }}
            >
              <TableCell colSpan={"7"}>
                <Stack spacing={2}>
                  <Breadcrumbs
                    separator="›"
                    sx={{ color: "white" }}
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs}
                  </Breadcrumbs>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ columnWidth: "auto" }}>#</TableCell>
              <TableCell align="left">League Name</TableCell>
              <TableCell align="left">League Type</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Teams Joined</TableCell>
              <TableCell align="left">Schedule</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  background:
                    " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{ color: "black", width: "10rem", cursor: "pointer" }}
                  align="left"
                >
                  <Link
                    onClick={() => navigate(`/league/league-info/${row?._id}`)}
                    sx={{
                      color: "green !important",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {row?.leagueName}
                  </Link>
                </TableCell>
                <TableCell align="left">{row?.leagueType}</TableCell>
                <TableCell sx={{ width: "15rem" }} align="left">
                  {row?.description}
                </TableCell>
                <TableCell align="left">{row?.teamsJoined}</TableCell>
                <TableCell align="left">
                  {`${dayjs(row?.schedule).format("DD-MM-YYYY")}
            ${dayjs(row?.schedule).format("HH:mm")}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

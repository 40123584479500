import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import AlleyLeagueCard from "./AlleyLeagueCard";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../redux/action/defaultActions";
const OngoingLeague = () => {
  const dispatch = useDispatch();
  const [activeData, setActiveData] = useState([]);
  const getApi = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/alley/active/league`);
      // console.log("resOngoing==>", res?.data?.alleyLeagues);
      setActiveData(res?.data?.alleyLeagues);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  useEffect(() => {
    getApi();
  }, []);
  return (
    <Box>
      <Grid container spacing={1}>
        {activeData.length > 0 ? (
          activeData?.map((item, index) => {
            return (
              <Grid item xs={12} sx={6} md={5} lg={4}>
                <AlleyLeagueCard
                  key={index}
                  logo={item?.league?.leagueImages?.url}
                  leagueName={item?.league?.leagueName}
                  to={`/active/leagues/${item?.league?._id}/`}
                />
              </Grid>
            );
          })
        ) : (
          <Box mt={2} p={2}>
            <Typography variant="h6" fontFamily={"Dosis"}>
              No Leagues
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};
//  ALl 10 Gone, The Chunky Bunch, Gourilla Hands, Strike & Spare
export default OngoingLeague;

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Card, CardActions, CardContent, Grid, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import WithdrawalRequestTable from "./WithdrawalRequestTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function RaiseWithdrawalRequest() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [bankAccountUpdated, setBankAccountUpdated] = useState(false);
  const handleClose = () => setOpen(false);
  const [values, setValues] = useState({});
  const [amount, setAmount] = useState(0);
  const [data, setData] = useState();
  const [details, setDetails] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ********************input type value change function********************
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  //  Account data update
  const handleSubmitChangeDetails = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.put(`/api/v1/alley/bank/account/details/update`, {
        bankAccountNo: values.accountNumber,
        accountHolderName: values.accountHolderName,
        bankName: values.bankName,
        swiftCode: values.swiftCode,
      });
      setData(res);
      getData();
      // console.log("bank details added to it", res);
      handleClose();
    } catch (error) {
      handleClose();
      // console.log(error.message);
    }
  };
  // ********************Get Data Bank Details function********************
  const getData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/alley/bank/account/details`);
      if (res?.data?.accountDetails) {
        dispatch(isLoading(false));
        setData(res?.data?.accountDetails);
        setBankAccountUpdated(true);
      }
    } catch (error) {
      dispatch(isLoading(false));
      // dispatch(openSnackbar("something went wrong", "error"));
      console.log("error=>",error)
      setBankAccountUpdated(false);
    }
  };
  // ********************Get All Withdrawal Request function********************
  const allRequest = async () => {
    dispatch(isLoading(true));
    try {
      dispatch(isLoading(false));
      const res = await axios.get(
        `/api/v1/alley/all/wallet/withdrawals/request`
      );
      setDetails(res?.data?.withdrawalRequest);
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>",error)
      // dispatch(openSnackbar("something went wrong", "error"));
      // console.log(error.message);
    }
  };
  // ********************handle submit function********************
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      dispatch(isLoading(false));
      if (bankAccountUpdated) {
        const res = await axios.post(`/api/v1/alley/new/withdrawal/request`, {
          amount: amount,
        });
        if (res?.data?.success === true) {
          dispatch(isLoading(false));
          dispatch(openSnackbar(res?.data?.message, "success"));
          setAmount(0);
        } else {
          dispatch(isLoading(false));
          dispatch(openSnackbar(res?.data?.message, "warning"));
          setAmount(0);
        }
      } else {
        dispatch(isLoading(false));
        dispatch(openSnackbar("Please Add Your Bank Details", "error"));
      }
    } catch (error) {
      dispatch(isLoading(false));
      // dispatch(openSnackbar("Something Went Wrong", "error"));
      console.log("error=>",error)
    }
  };
  useEffect(() => {
    getData();
    allRequest();
  }, []);

  return (
    <Box>
      <Stack>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <Typography></Typography>
            <form onSubmit={handleSubmit}>
              <Typography variant="h5" gutterBottom fontFamily={"Dosis"}>
                Create Withdrawal Request
              </Typography>
              <Grid>
                <Grid md={4} sm={6} xs={12}>
                  <TextField
                    required
                    type="number"
                    sx={{ minWidth: "auto" }}
                    id="outlined-basic"
                    label="Request Amount"
                    variant="outlined"
                    value={amount}
                    name="requestAmount"
                    onFocus={() => setAmount("")}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </Grid>
                {data ? (
                  <Grid
                    container
                    sx={{
                      direction: "row",
                      alignItems: "center",
                      justifyContent: "sapce-between",
                      marginY: "1rem",
                    }}
                  >
                    <Grid item md={8} sm={10} xs={12}>
                      Account Holder Name :{data.accountHolderName}
                      <br />
                      Account-No: {data?.bankAccountNo}
                      <br />
                      Bank Name: {data?.bankName}
                      <br />
                      Swift Code: {data?.swiftCode}
                    </Grid>
                    <Grid item md={4} sm={10} xs={12}>
                      <Button
                        variant="text"
                        sx={{
                          backgroundColor: "transparent",
                          fontSize: "14px",
                          fontWeight: "300",
                        }}
                        onClick={handleOpen}
                      >
                        <EditIcon
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                          }}
                        />
                        Change
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} md={7}>
                    <Button onClick={handleOpen}>Add New Account</Button>
                  </Grid>
                )}
              </Grid>
              <CardActions
                sx={{
                  direction: "row",
                  alignItems: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  sx={{ fontWeight: "500" }}
                  size="large"
                  type="submit"
                  onClose={handleClose}
                >
                  Raise Request
                </Button>
              </CardActions>
            </form>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <WithdrawalRequestTable details={details} />
          </Grid>
        </Grid>
      </Stack>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmitChangeDetails}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 24 }} gutterBottom>
                    Add Bank Details
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        sx={{ minWidth: "30rem" }}
                        id="outlined-basic"
                        label="Account holder Name"
                        variant="outlined"
                        name="accountHolderName"
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        sx={{ minWidth: "30rem" }}
                        id="outlined-basic"
                        label="Account Number"
                        variant="outlined"
                        name="accountNumber"
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        sx={{ minWidth: "30rem" }}
                        id="outlined-basic"
                        label="Bank Name"
                        variant="outlined"
                        name="bankName"
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        type="text"
                        sx={{ minWidth: "30rem" }}
                        id="outlined-basic"
                        label="Swift Code"
                        variant="outlined"
                        name="swiftCode"
                        onChange={handleChange}
                      />
                      {/* <TextField
                            required
                            type="text"
                            sx={{ minWidth: "30rem" }}
                            id="outlined-basic"
                            label="Bank Name"
                            variant="outlined"
                            name="bankName"
                            onChange={handleChange}
                          /> */}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button sx={{ fontWeight: "500" }} size="large" type="submit">
                    Submit
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

import { Card, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import ScoreTable from './ScoreTable'

const matchData = [
    {
        "match": {
            "matchNum": 1,
            "teams": [{
                "teamName": "Team A",
                "NumOfPlayers": 5,
                "players": [
                    { "playername": "P1" }, { "playername": "P2" }, { "playername": "P3" }, { "playername": "p4" }, { "playername": "p5" }
                ]
            }, {
                "teamName": "Team B",
                "NumOfPlayers": 5,
                "players": [
                    { "playername": "P1" }, { "playername": "P2" }, { "playername": "P3" }, { "playername": "p4" }, { "playername": "p5" }
                ]
            },]

        }
    }
]

const UpdateScore = () => {
    return (
        <Card>
            {matchData?.map((data, index) => {
                return (
                    <>
                        <Stack>
                            <Typography>Match No.:{data.match.matchNum} </Typography>
                        </Stack>
                        <Stack>

                            {data?.match?.teams?.map((team) => {
                                return (<Stack sx={{
                                    background:
                                        " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)"
                                }}>
                                    <Grid container justifyContent={'space-between'}>
                                        <Grid item><Typography>{team.teamName}</Typography></Grid>
                                        <Grid item><Typography>Players: {team.NumOfPlayers}</Typography></Grid>
                                    </Grid>
                                    {team?.players.map((player) => {
                                        return (
                                            <Stack mb={2} >
                                                <Stack>{player?.playername}</Stack>
                                                <ScoreTable />
                                            </Stack>
                                        )
                                    })}
                                </Stack>)
                            })}
                        </Stack>
                    </>
                )
            })}
        </Card>
    )
}

export default UpdateScore

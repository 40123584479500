import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import TableLeagues from "./TableLeagues";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading } from "../../redux/action/defaultActions";
import { IndeterminateCheckBoxOutlined } from "@mui/icons-material";

const UpcomingLeague = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const api = async () => {
   
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/alley/upcoming/league`);
      
      const rows = res?.data?.alleyLeagues?.map(({ league }, index) => {
        return { ...league, index: index };
      });
      setData(rows);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log(error.message);
      
    }
  };
  console.log(data);

  useEffect(() => {
    api();
  }, []);

  return (
    <Box>
      <Stack>
        <TableLeagues data={data} action={""} tableType="upcoming" parentPath={"/upcoming/leagues"} />
      </Stack>
    </Box>
  );
};

export default UpcomingLeague;

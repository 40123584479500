import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoading } from "../../redux/action/defaultActions";
import axios from "axios";

const Address = () => {
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state);
  const [addressValues, setAddressValues] = useState({});
  console.log({ profileData: profileData });

  const handleChange = (e) => {
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log("will==>");
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const formValues = new FormData();
      formValues.append(
        "alleyName",
        `${addressValues?.firstName} ${addressValues?.lastName}`
      );
      formValues.append("email", addressValues?.email);
      formValues.append("contactNumber", addressValues?.contactNumber);
      formValues.append("countryCode", `+${addressValues?.countryCode}`);
      formValues.append("addressLine1", `${addressValues?.addressLine1}`);
      formValues.append("city", `${addressValues?.city}`);
      formValues.append("state", `${addressValues?.state}`);
      formValues.append("zipCode", `${addressValues?.zipCode}`);
      const res = await axios.put("/api/v1/alley/account/update", formValues);
      console.log("res==>", res);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    if (profileData) {
      setAddressValues({
        countryCode: profileData?.alleyAccount?.countryCode * 1,
        contactNumber: profileData?.alleyAccount?.contactNumber,
        firstName: profileData?.alleyAccount?.alleyName.trim().split(" ")[0],
        lastName: profileData?.alleyAccount?.alleyName.trim().split(" ")[1]
          ? profileData?.alleyAccount?.alleyName.trim().split(" ")[1]
          : "",
        email: profileData?.email,
        city: profileData?.alleyAccount?.city,
        state: profileData?.alleyAccount?.state,
        addressLine1: profileData?.alleyAccount?.addressLine1,
        zipCode: profileData?.alleyAccount?.zipCode,
      });
    }
  }, []);

  return (
    <Stack px={5} py={3} component={"form"} onSubmit={handleSubmit}>
      <Stack p={1} border={"2px solid #686868"} borderRadius={"8px"}>
        <Typography variant="h6" sx={{ color: "grey" }}>
          Alley Address
        </Typography>
        <Box width={"100%"}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} mt={1} md={12}>
              <TextField
                required
                name="addressLine1"
                placeholder="Enter Address Line 1 Name"
                label="Address Line 1"
                size="small"
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={
                  addressValues?.addressLine1 ? addressValues?.addressLine1 : ""
                }
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                required
                name="city"
                placeholder="Enter City Name"
                label="City"
                size="small"
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={addressValues?.city ? addressValues?.city : ""}
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                required
                name="state"
                placeholder="Enter State/Province Name"
                label="State/Province"
                size="small"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={addressValues?.state ? addressValues?.state : ""}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                required
                name="zipCode"
                placeholder="Enter Pin/Zip code"
                label="Enter Pin/Zip code"
                size="small"
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={addressValues?.zipCode ? addressValues?.zipCode : ""}
                type="number"
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Stack mt={2} alignItems={"center"} justifyContent={"center"}>
        <Button
          variant="contained"
          type="submit"
          color="inherit"
          size="small"
          sx={{
            color: "white",
            backgroundColor: "#6FBE44 !important",
            maxHeight: "35px",
            borderRadius: "3px !important",
            px: "30px",
          }}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default Address;

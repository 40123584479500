import React, { useEffect } from "react";
import Teams from "../../../components/Tables/TeamsTable";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../redux/action/defaultActions";

const UpcomingTeams = () => {
  const [leagueTeams, setLeagueTeams] = useState([]);
  const dispatch = useDispatch();
  const { leagueId } = useParams();
  const getLeagueTeamInfo = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/teams/list/${leagueId}`);
      console.log("res==>", res?.data?.alleyLeagueTeams);
      setLeagueTeams(res?.data?.alleyLeagueTeams?.leagueTeams);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  useEffect(() => {
    getLeagueTeamInfo();
  }, []);

  return (
    <div>
      <Teams teamData={leagueTeams} />
    </div>
  );
};

export default UpcomingTeams;

import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

export default function UnstyledMenuSimple(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profileData } = useSelector((state) => state);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleLogout = async () => {
  //   dispatch(isLoading(true));
  //   try {
  //     console.log("api k andr");
  //     const res = await axios.get(`/api/v1/alley/sign/out`);
  //     console.log(res);
  //     if (res.data.success) {
  //       dispatch(isLoading(false));
  //       dispatch(openSnackbar("logged out Successfully", "success"));
  //       navigate(`/login`);
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //     dispatch(isLoading(false));
  //     dispatch(openSnackbar("something went wrong", "error"));
  //   }
  // };

  return (
    <>
      <ButtonGroup
        size="small"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          color: "white",
          borderRadius: "0px",
        }}
        variant="contained"
        // ref={anchorRef}
        // aria-label="split button"
        disableElevation
      >
        <IconButton
          onClick={handleClick}
          sx={{
            bgcolor: "transparent",
            color: "white",
            fontSize: "12px",
            fontFamily: "Dosis",
            borderRadius: "0px",
          }}
        >
          {/* <MailOutlineIcon sx={{ color: "white" }} /> */}
          {/* <img
            style={{ color: "white", width: "2rem", height: "2rem" }}
            src="./assets/images/dropdown.png"
            alt="hhh"
          /> */}
          <Avatar
            src={profileData?.alleyAccount?.profilePicture?.url}
            alt={profileData?.userName}
          />
          &nbsp;&nbsp;
          <Typography
            variant="body2"
            component={"caption"}
            fontFamily={"Dosis"}
            textTransform={"capitalize"}
            display={{ xs: "none", md: "block", color: "#74BF4C" }}
          >
            {profileData?.userName}
          </Typography>
        </IconButton>
        <Button
          disableElevation
          disableFocusRipple
          onClick={handleClick}
          sx={{
            borderRadius: "0px",
            bgcolor: "transparent",
            color: "white",
            "&.MuiDisabled": { bgcolor: "transparent" },
            "&:hover": { bgcolor: "transparent" },
          }}
          size="small"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/myProfile/`);
          }}
          sx={{ color: "rgb(133, 219, 72)" }}
        >
          <PersonIcon />
          &nbsp; My Profile
        </MenuItem>
        <MenuItem
          onClick={() => props?.handleLogout()}
          sx={{ color: "rgb(133, 219, 72)" }}
        >
          <LogoutIcon /> &nbsp; Logout
        </MenuItem>
      </Menu>
    </>
  );
}

import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoading } from "../../redux/action/defaultActions";
import axios from "axios";
import { useEffect } from "react";
import { MuiTelInput } from "mui-tel-input";

const AccountSettings = () => {
  const { profileData } = useSelector((state) => state);
  const [accountValues, setAccountValues] = useState({
    countryCode: "",
    contactNumber: "",
    numberOfLane: "",
    alleyDetails: "",
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    state: "",
    addressLine1: "",
    zipCode: "",
  });
  const [phone, setPhone] = React.useState("");

  const handleCodeChange = (newPhone) => {
    setPhone(newPhone);
  };
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setAccountValues({ ...accountValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const formValues = new FormData();
      formValues.append(
        "alleyName",
        `${accountValues?.firstName} ${accountValues?.lastName}`
      );
      // formValues.append("email", accountValues?.email);
      formValues.append(
        "contactNumber",
        `${phone.replace(/\s/g, "").slice(-10)}`
      );
      formValues.append(
        "countryCode",
        `${phone.replace(/\s/g, "").slice(0, -10)}`
      );
      formValues.append(
        "addressLine1",
        accountValues?.addressLine1 ? accountValues?.addressLine1 : null
      );
      formValues.append(
        "city",
        accountValues?.city ? accountValues?.city : null
      );
      formValues.append(
        "state",
        accountValues?.state ? accountValues?.state : null
      );
      formValues.append(
        "zipCode",
        accountValues?.zipCode ? accountValues?.zipCode : null
      );
      formValues.append(
        "numberOfLane",
        accountValues?.numberOfLane ? accountValues?.numberOfLane : null
      );
      formValues.append(
        "alleyDetails",
        accountValues?.alleyDetails ? accountValues?.alleyDetails : null
      );
      const res = await axios.put("/api/v1/alley/account/update", formValues);
      console.log("res==>", res);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    if (profileData) {
      console.log({
        name: profileData,
      });
      setAccountValues({
        countryCode: profileData?.alleyAccount?.countryCode,
        contactNumber: profileData?.alleyAccount?.contactNumber
          ? profileData?.alleyAccount?.contactNumber
          : "",
        numberOfLane: profileData?.alleyAccount?.numberOfLane
          ? profileData?.alleyAccount?.numberOfLane
          : "",
        firstName: profileData?.alleyAccount?.alleyName.trim().split(" ")[0]
          ? profileData?.alleyAccount?.alleyName.trim().split(" ")[0]
          : "",
        lastName: profileData?.alleyAccount?.alleyName.trim().split(" ")[1]
          ? profileData?.alleyAccount?.alleyName.trim().split(" ")[1]
          : "",
        email: profileData?.email ? profileData?.email : "",
        city: profileData?.alleyAccount?.city
          ? profileData?.alleyAccount?.city
          : "",
        state: profileData?.alleyAccount?.state
          ? profileData?.alleyAccount?.state
          : "",
        addressLine1: profileData?.alleyAccount?.addressLine1
          ? profileData?.alleyAccount?.addressLine1
          : "",
        alleyDetails: profileData?.alleyAccount?.alleyDetails
          ? profileData?.alleyAccount?.alleyDetails
          : "",
        zipCode: profileData?.alleyAccount?.zipCode
          ? profileData?.alleyAccount?.zipCode
          : "",
      });
      setPhone(
        `${profileData?.alleyAccount?.countryCode}${profileData?.alleyAccount?.contactNumber}`
      );
    }
  }, [profileData]);

  return (
    <Stack
      px={5}
      py={3}
      component={"form"}
      onSubmit={handleSubmit}
      fontFamily={"dosis"}
    >
      <Stack p={1} border={"2px solid #686868"} borderRadius={"8px"}>
        <Typography variant="h6" sx={{ color: "grey" }}>
          Admin Personal Information
        </Typography>
        <Box width={"100%"}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                name="firstName"
                placeholder="Enter First Name"
                label="First Name"
                size="small"
                onChange={handleChange}
                value={accountValues?.firstName ? accountValues?.firstName : ""}
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                name="lastName"
                placeholder="Enter Last Name"
                label="Last Name"
                required
                size="small"
                onChange={handleChange}
                value={accountValues?.lastName ? accountValues?.lastName : ""}
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                type="text"
              />
            </Grid>
            {/* <Grid item xs={12} sm={3} mt={1} md={2}>
             
              <TextField
                name="countryCode"
                placeholder="Provide Country code"
                label="Country Code"
                required
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+</InputAdornment>
                  ),
                }}
                onChange={handleChange}
                value={
                  accountValues?.countryCode ? accountValues?.countryCode : ""
                }
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                type="number"
              />
            </Grid> */}
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <MuiTelInput
                label="Enter Phone Number"
                fullWidth
                value={phone}
                onChange={handleCodeChange}
                defaultCountry="US"
                placeholder="Enter Phone Number"
                sx={{
                  " & input": {
                    color: "grey",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                name="email"
                placeholder="Enter Email"
                label="E-Mail"
                size="small"
                onChange={handleChange}
                value={accountValues?.email ? accountValues?.email : ""}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                type="email"
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                name="numberOfLane"
                placeholder="Enter number Of Lane"
                label="Number Of Lane"
                size="small"
                onChange={handleChange}
                value={
                  accountValues?.numberOfLane ? accountValues?.numberOfLane : ""
                }
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                name="alleyDetails"
                placeholder="Provide Details"
                label="Alley Details"
                size="small"
                onChange={handleChange}
                value={
                  accountValues?.alleyDetails ? accountValues?.alleyDetails : ""
                }
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                type="text"
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="h6" sx={{ color: "grey" }}>
                Admin Address
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                required
                name="addressLine1"
                placeholder="Enter Address Line 1 Name"
                label="Address Line 1"
                size="small"
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={
                  accountValues?.addressLine1 ? accountValues?.addressLine1 : ""
                }
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                required
                name="city"
                placeholder="Enter City Name"
                label="City"
                size="small"
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={accountValues?.city ? accountValues?.city : ""}
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                required
                name="state"
                placeholder="Enter State/Province Name"
                label="State/Province"
                size="small"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={accountValues?.state ? accountValues?.state : ""}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt={1} md={6}>
              <TextField
                required
                name="zipCode"
                placeholder="Enter Pin/Zip code"
                label="Enter Pin/Zip code"
                size="small"
                fullWidth
                // color="black"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "grey",
                  },
                }}
                onChange={handleChange}
                value={accountValues?.zipCode ? accountValues?.zipCode : ""}
                type="number"
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Stack mt={2} alignItems={"center"} justifyContent={"center"}>
        <Button
          variant="contained"
          type="submit"
          color="inherit"
          size="small"
          sx={{
            color: "white",
            backgroundColor: "#6FBE44 !important",
            maxHeight: "35px",
            borderRadius: "3px !important",
            px: "30px",
          }}
        >
          {" "}
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default AccountSettings;

import React from "react";
import Button from "@mui/material/Button";

const Button1 = (props) => {
  return (
    <>
      <Button
        // size={props?.size ? props?.size : "small"}
        size="small"
        variant="contained"
        disableRipple
        disabled={props.disabled}
        type={props?.buttonType ? props?.buttonType : "button"}
        fullWidth
        onClick={
          props?.buttonType === "submit" ? () => {} : () => props?.onClick
        }
        sx={{
          m: props?.m,
          color: "white",
          fontFamily: "Dosis",
          textAlign: "center",
          borderRadius: "12px",

          // px: 1,
          // bgcolor: "rgba(111, 190, 68, 0.9)",
          bgcolor: "#6FBE44",
          "&.Mui-disabled": {
            color: "gray",
          },
          "&:clicked": { bgcolor: "rgba(111, 190, 68, 0.7)", scale: 0.8 },
          "&:hover": { bgcolor: "rgba(111, 190, 68, 0.8)" },
          textTransform: props.textTransform
            ? props?.textTransform
            : "capitalize",
          textShadow: "1px 1px 1px black",
          fontSize: props?.fontSize ? props?.fontSize : "18px",
          borderBottom: "6px solid  #205205",
          maxHeight: "55px",
        }}
      >
        {props?.name}
      </Button>
    </>
  );
};

export default Button1;

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import ErrorIcon from "@mui/icons-material/Error";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isLoading } from "../../redux/action/defaultActions";
import { updateWallet } from "../../redux/action/adminActions";
const Wallet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [walletAmount, setWalletAmount] = useState(0);
  const [allWithdrawalRequest, setAllWithdrawalRequest] = useState([]);
  const { walletBalance } = useSelector((state) => state);
  const getWalletInfo = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`api/v1/alley/current/wallet/balance`);
      console.log("res===", res);
      setWalletAmount(res?.data?.walletBalance?.wallet);
      dispatch(updateWallet(res?.data?.walletBalance?.wallet));
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  const getAllWithdrawalTransaction = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        "/api/v1/alley/all/wallet/withdrawals/request"
      );
      console.log({ res: res });
      setAllWithdrawalRequest(res?.data?.withdrawalRequest);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
    }
  };
  function CustomToolbar() {
    return (
      <Box
        // py={1.5}
        px={1}
        bgcolor={"rgba(51, 51, 51, 0.9)"}
        borderBottom={"1px solid rgba(131, 135, 138, 0.8)"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Grid container>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            sx={{ alignItems: "center", display: "flex" }}
          >
            <Typography>All Transaction</Typography>
          </Grid>

          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            sx={{ alignItems: "center", display: "flex" }}
          >
            <GridToolbarQuickFilter
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "rgba(158, 158, 158, 1)",
                  borderRadius: "10px",
                  borderColor: "rgba(158, 158, 158, 1)",
                },
                "&:svg": {
                  fill: "red",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  const columns = [
    {
      field: "_id",
      headerName: "Sr No.",
      width: 120,
      renderCell: (index) =>
        index?.api?.getRowIndexRelativeToVisibleRows(index.row._id) + 1,
    },
    {
      field: "transactionType",
      headerName: "Widthdrawal",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {/* <Stack direction={"row"} spacing={1}>
              {params?.row?.status === "success" ? (
                <DoneAllIcon sx={{ color: "green" }} />
              ) : params?.row?.status === "pending" ? (
                <AutorenewIcon />
              ) : (
                <ErrorIcon />
              )}

              <Typography>{params?.row?.transactionType}</Typography>
            </Stack> */}
            Widthdrawal
          </>
        );
      },
    },

    {
      field: "requestAmount",
      headerName: "Requested Amount",
      width: 150,
    },
    {
      field: "txnID",
      headerName: "TransactionId",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {params?.value?.txnID ? params?.value?.txnID : "N/A"}
            </Typography>
          </>
        );
      },
    },
    {
      field: "requestStatus",
      headerName: "Status",
      width: 150,
    },
  ];

  useEffect(() => {
    getWalletInfo();
    getAllWithdrawalTransaction();
  }, []);

  return (
    <Box width="100%">
      <Typography variant="h5" fontFamily={"Dosis"}>
        Wallet Amount
      </Typography>
      <Stack
        mt={1}
        direction={"row"}
        // justifyContent={"center"}
        spacing={2}
        alignItems={"center"}
      >
        {/* <Box
          color={"green !important"}
          bgcolor={"rgba(111, 190, 68, 1)"}
          component={Paper}
          p={2}
        >
          <Typography fontFamily={"Dosis"} sx={{ color: "white" }} variant="h5">
            $45858
          </Typography>
        </Box> */}
        <Card
          sx={{
            minWidth: 275,
            // backgroundColor: "rgba(111, 190, 68, 0.5)",
            // background:
            //   "linear-gradient(34deg, rgba(17,56,6,1) 18%, rgba(71,201,14,1) 31%, rgba(0,0,0,1) 84%)",
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Wallet Amount
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"start"}
              spacing={2}
            >
              <Typography variant="h5" component="div">
                ${walletBalance}
              </Typography>
              <AccountBalanceWalletIcon sx={{ fontSize: "30px" }} />
            </Stack>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              available
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              sx={{
                background: "transparent",
                border: "0.5px solid grey",
                "&:hover": { background: "green !important" },
              }}
              size="small"
              onClick={() => navigate("/wallet/raise-withdrawal-request")}
            >
              Withdraw
            </Button>
          </CardActions>
        </Card>
        {/* <Box>
          <Button variant="outlined">Withdraw</Button>
        </Box> */}
      </Stack>
      <Stack mt={2}>
        <Typography fontFamily={"Dosis"} variant="h4">
          Wallet Transaction
        </Typography>
        <Box mt={2}>
          {allWithdrawalRequest?.length > 0 ? (
            <DataGrid
              rows={allWithdrawalRequest}
              columns={columns}
              slots={{ toolbar: CustomToolbar }}
              getRowId={(row) => row?._id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              // checkboxSelection
              sx={{
                fontSize: "15px",
                minHeight: "500px",
                opacity: 1,
                color: "white",
                // backgroundColor:"transparent",

                "& .MuiSvgIcon-root ": { fill: "grey" },

                "& .MuiDataGrid-menuIconButton ,& .css-ptiqhd-MuiSvgIcon-root":
                  {
                    opacity: 1,
                    color: "white",
                  },
                ".MuiDataGrid-toolbarContainer": {
                  backgroundColor: "red !important",
                },
                // border: "1px solid rgba(131, 135, 138, 0.8)",
                border: "transparent",
                "&.MuiDataGrid-columnHeaders,.MuiDataGrid-withBorderColor": {
                  // borderColor: "rgba(131, 135, 138, 0.5) !important",
                  borderColor: "transparent",
                },
                background: "transparent",
                // background:
                //   " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
              }}
            />
          ) : (
            <Stack>
              <Typography variant="body2" component={"p"}>
                No records found
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Wallet;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import MuiTheme from "./theme/Themes";
import { Provider } from "react-redux/es";

import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/reducer";
import Loader from "./reusablecomponents/isLoading/Loader";
import { BiLoaderCircle } from "react-icons/bi";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter >
      <ThemeProvider theme={MuiTheme}>
        <Provider store={store}>
          <PersistGate loading={"loading..."} persistor={persistor}></PersistGate>
          <App />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import SingleInputWithLabels from "../../reusablecomponents/formcontrols/SingleInputWithLabels";
import RolesList from "./RolesList";

const CreateRole = () => {
  const [values, setValues] = useState({ permissions: [] });
  const [checkboxData, setCheckboxData] = useState({
    accountmg: false,
    leaguemg: false,
    rolemg: false,
    scoremg: false,
    leaderboardm: false,
  });
  //   ======handle change ===============
  const handleSunmit = (e) => {
    e.preventDefault();
    console.log("checkboxData=>", checkboxData);
  };
  // ===
  const onPermissionChange = (e) => {
    setCheckboxData({ ...checkboxData, [e.target.name]: e.target.checked });
  };
  // ===
  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={7.8}>
          <Typography variant="h5" textAlign={"center"}>
            Create Role
          </Typography>
          <Box component={Paper} p={2}>
            <form onSubmit={handleSunmit}>
              <SingleInputWithLabels
                labelText={"User Name"}
                inputType={"text"}
                inputName={"username"}
                labelInputId={"username"}
                inputValue={values?.username ? values?.username : ""}
                onChangeHandler={(e) =>
                  setValues({ ...values, username: e.target.value })
                }
                placeholderText={"Enter Username"}
                requiredTrue={true}
                // helperText={"Number of matches every team will be offered to play.*"}
              />
              <SingleInputWithLabels
                labelText={"Email"}
                inputType={"email"}
                inputName={"email"}
                labelInputId={"email"}
                inputValue={values?.email ? values?.email : ""}
                onChangeHandler={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                placeholderText={"Enter Email"}
                requiredTrue={true}
                // helperText={"Number of matches every team will be offered to play.*"}
              />
              <SingleInputWithLabels
                labelText={"Phone"}
                inputType={"phone"}
                inputName={"phone"}
                labelInputId={"phone"}
                inputValue={values?.phone ? values?.phone : ""}
                onChangeHandler={(e) =>
                  setValues({ ...values, phone: e.target.value })
                }
                placeholderText={"Enter Phone Number"}
                requiredTrue={true}
                // helperText={"Number of matches every team will be offered to play.*"}
              />
              <SingleInputWithLabels
                labelText={"Role"}
                inputType={"text"}
                inputName={"role"}
                labelInputId={"role"}
                inputValue={values?.role ? values?.role : ""}
                onChangeHandler={(e) =>
                  setValues({ ...values, role: e.target.value })
                }
                placeholderText={"Give A Name Of Role"}
                requiredTrue={true}
                // helperText={"Number of matches every team will be offered to play.*"}
              />
              <SingleInputWithLabels
                labelText={"Create Password"}
                inputType={"password"}
                inputName={"password"}
                labelInputId={"password"}
                inputValue={values?.password ? values?.password : ""}
                onChangeHandler={(e) =>
                  setValues({ ...values, password: e.target.value })
                }
                placeholderText={"Create Password For User"}
                requiredTrue={true}
                helperText={
                  "Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number.*"
                }
              />
              <Grid container>
                <Grid
                  component={"label"}
                  htmlFor={"permission"}
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={12}
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Permission *
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={12} multiple>
                  <FormGroup row>
                    {subadminPermission.map((option, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          sx={{
                            width: "fit-content",
                          }}
                          control={
                            <Checkbox
                              checked={checkboxData[option.permission]}
                              name={option.permission}
                              onChange={onPermissionChange}
                              sx={{
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                          label={option.permission}
                        />
                      );
                    })}
                  </FormGroup>
                </Grid>
              </Grid>
              <Stack mt={1}>
                <Button
                  variant="conained"
                  type="submit"
                  sx={{
                    bgcolor: "green",
                  }}
                >
                  Create Role
                </Button>
              </Stack>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4.8} lg={3.8}>
          <Typography>All Created Roles</Typography>
          <Box borderRadius={"10px"} mt={1} component={Paper}>
            <RolesList />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
const subadminPermission = [
  { permission: "accountmg" },
  { permission: "leaguemg" },
  { permission: "rolemg" },
  { permission: "scoremg" },
  { permission: "leaderboardmg" },
];
export default CreateRole;

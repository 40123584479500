import React from "react";
import { Box } from "@mui/system";
import ForgetCard from "./ForgetCard";

const Forget = () => {
  return (
    <Box
      height={"100vh"}
      sx={{
        backgroundImage: "url(/assets/background/background.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      width={"100%"}
      //   bgcolor={"black"}
      color={"white"}
    >
      <Box width={{ md: "30%", xs: "90%", sm: "50%", lg: "25%" }}>
        <ForgetCard />
      </Box>
    </Box>
  );
};

export default Forget;

import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/system";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import CarouselHeroSection from "./CarouselHeroSection";
import MatchCards from "../../../components/cards/MatchCards";

import LeagueCards from "../../../components/cards/LeagueCards";

import AddIcon from "@mui/icons-material/Add";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import { useNavigate } from "react-router-dom";
import GamesIcon from "@mui/icons-material/Games";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
// GrDocumentUpdate

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isLoading } from "../../../redux/action/defaultActions";
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { walletBalance } = useSelector((state) => state);
  const [allCurrentLeagues, setAllCurrentLeagues] = useState([]);

  const [liveMatches, setLiveMatches] = useState([]);

  const getAllLiveMatches = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/active/leagues/live/matches`);
      console.log("res====>", res);
      setLiveMatches(res?.data?.leaguesMatches);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };
  const getAllCurrentLeagues = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/alley/active/league`);
      console.log("res==>", res?.data?.alleyLeagues);
      setAllCurrentLeagues(res?.data?.alleyLeagues);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getAllCurrentLeagues();
    getAllLiveMatches();
  }, []);

  return (
    <>
      <Box>
        <Stack
          background={
            " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)"
          }
        >
          <CarouselHeroSection data={allCurrentLeagues} />
        </Stack>
        <Divider variant="inset" />
        <Stack>
          <Box
            borderRadius={"5px"}
            mt={2}
            p={2}
            py={2}
            sx={{
              background:
                " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
            }}
          >
            <Stack mb={1}>
              <Typography variant="h5">Control Center</Typography>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant="text"
                  startIcon={
                    // <img src="/assets/images/NewLeaque.png" alt="logo" />
                    <AddIcon />
                  }
                  sx={{
                    mt: 2,
                    color: "#fff",
                    fontWeight: "500",
                    bgcolor: "rgba(133, 219, 72,0.8)",
                    ":hover": {
                      bgcolor: "rgba(133, 219, 72,0.4)",
                      color: "white",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/createNewLeague")}
                >
                  &nbsp; Create New League
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant="text"
                  startIcon={
                    <GamesIcon style={{ color: "white !important" }} />
                  }
                  sx={{
                    mt: 2,
                    // color: "#fff",
                    color: "white",
                    fontWeight: "500",
                    bgcolor: "rgba(133, 219, 72,0.8)",
                    "& .MuiButton-startIcon": {
                      "& svg": {
                        fill: "white !important",
                      },
                    },
                    "&:hover": {
                      bgcolor: "rgba(133, 219, 72,0.4)",
                      color: "white",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/all/ongoing/day/matches")}
                >
                  &nbsp; Update Scores
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant="text"
                  startIcon={<DashboardCustomizeIcon />}
                  sx={{
                    mt: 2,
                    color: "#fff",
                    fontWeight: "500",
                    bgcolor: "rgba(133, 219, 72,0.8)",
                    ":hover": {
                      bgcolor: "rgba(133, 219, 72,0.4)",
                      color: "white",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/leagues")}
                >
                  &nbsp; League Dashboard
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant="text"
                  startIcon={<AccountBalanceWalletIcon />}
                  sx={{
                    mt: 2,
                    color: "#fff",
                    fontWeight: "500",
                    bgcolor: "rgba(133, 219, 72,0.8)",
                    ":hover": {
                      bgcolor: "rgba(133, 219, 72,0.4)",
                      color: "white",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/wallet")}
                >
                  &nbsp; ${walletBalance}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant="text"
                  startIcon={<RequestQuoteIcon />}
                  sx={{
                    mt: 2,
                    color: "#fff",
                    fontWeight: "500",
                    bgcolor: "rgba(133, 219, 72,0.8)",
                    ":hover": {
                      bgcolor: "rgba(133, 219, 72,0.4)",
                      color: "white",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/wallet/raise-withdrawal-request")}
                >
                  Widthdraw
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            borderRadius={"5px"}
            mt={2}
            p={1}
            py={2}
            sx={
              {
                // background:
                //   " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
              }
            }
          >
            <Grid
              container
              // spacing={1}
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={
                  {
                    // background:
                    //   " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                  }
                }
                border={"1px solid rgba(255, 255, 255, 0.1)"}
                borderRadius={"15px"}
              >
                <Stack
                  sx={{
                    p: 1,
                    backgroundColor: "rgba(111, 190, 68, 1)",
                    textAlign: "center",
                    borderRadius: "15px 15px 0 0",
                  }}
                >
                  <Typography>Active Leagues</Typography>
                </Stack>
                <Stack
                  sx={{
                    maxHeight: 400,
                    p: 2,
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                      bgcolor: "transparent",
                      borderRadius: "6px",
                      height: "100%",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      bgcolor: "white",
                      height: "150px",
                      borderRadius: "6px",
                    },
                  }}
                >
                  {allCurrentLeagues.length > 0
                    ? allCurrentLeagues?.map((item, index) => {
                        return (
                          <Stack
                            key={index}
                            display={"flex"}
                            flexDirection={"row"}
                          >
                            <LeagueCards
                              logo={item?.league?.leagueImages?.url}
                              leagueName={item?.league?.leagueName}
                              redirectTo={"/active/leagues"}
                              matchData={item?.league}
                            />
                          </Stack>
                        );
                      })
                    : "No Active Leagues"}
                </Stack>
              </Grid>
              {/* {liveMatches?.length > 0 ? ( */}
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={
                  {
                    // background:
                    //   " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                  }
                }
                border={"1px solid rgba(255, 255, 255, 0.1)"}
                borderRadius={"15px"}
              >
                <Stack
                  sx={{
                    p: 1,
                    backgroundColor: "rgba(111, 190, 68, 1)",
                    textAlign: "center",
                    borderRadius: "15px 15px 0 0",
                  }}
                >
                  <Typography>Live Matches</Typography>
                </Stack>
                <Stack
                  sx={{
                    maxHeight: 400,
                    p: 2,
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                      bgcolor: "transparent",
                      borderRadius: "6px",
                      height: "100%",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      bgcolor: "white",
                      height: "150px",
                      borderRadius: "6px",
                    },
                  }}
                >
                  {liveMatches?.length > 0
                    ? liveMatches?.map((item, index) => {
                        return (
                          <Stack
                            key={index}
                            display={"flex"}
                            flexDirection={"row"}
                            sx={{ position: "relative" }}
                            mt={1}
                          >
                            <MatchCards
                              teamNameA={item?.matches?.teamB?.teamName}
                              teamNameB={item?.matches?.teamA?.teamName}
                              dateOfMatch={item?.matches?.teamMatchDate}
                              timeOfMatch={item?.matches?.teamMatchTiming}
                            />
                          </Stack>
                        );
                      })
                    : "No Live Matches"}
                </Stack>
              </Grid>
              {/* ) : (
                ""
              )} */}

              {/* <Grid
                item
                xs={12}
                sm={6}
                md={3.5}
                sx={
                  
                }
                border={"1px solid rgba(255, 255, 255, 0.1)"}
                borderRadius={"15px"}
              >
                <Stack
                  sx={{
                    p: 1,
                    backgroundColor: "rgba(111, 190, 68, 1)",
                    textAlign: "center",
                    borderRadius: "15px 15px 0 0",
                  }}
                >
                  <Typography>Leaderboards</Typography>
                </Stack>
                <Stack
                  sx={{
                    maxHeight: 400,
                    p: 2,
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                      bgcolor: "transparent",
                      borderRadius: "6px",
                      height: "100%",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      bgcolor: "white",
                      height: "150px",
                      borderRadius: "6px",
                    },
                  }}
                >
                  
                  {[1, 2, 3].map((item, index) => {
                    return (
                      <>
                        <Stack
                          key={index}
                          
                          mt={1}
                        >
                          

                          <List sx={{ width: "100%", px: 2 }} component={Paper}>
                            <Stack
                              sx={{
                                p: 1,
                                borderRadius: "5px 5px 0 0",
                                background:
                                  " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
                              }}
                            >
                              <Typography>League {index + 1}</Typography>
                            </Stack>
                            <ListItem
                              disableGutters
                              secondaryAction={
                                <Typography sx={{ color: "green !important" }}>
                                  Avg Score
                                </Typography>
                              }
                            >
                              <ListItemText
                                primary={`Team Name`}
                                sx={{ color: "green !important" }}
                              />
                            </ListItem>
                            <ListItem
                              disableGutters
                              secondaryAction={<Typography>100</Typography>}
                            >
                              <ListItemText primary={`Line item`} />
                            </ListItem>
                            <ListItem
                              disableGutters
                              secondaryAction={<Typography>100</Typography>}
                            >
                              <ListItemText primary={`Line item`} />
                            </ListItem>
                            <ListItem
                              disableGutters
                              secondaryAction={<Typography>100</Typography>}
                            >
                              <ListItemText primary={`Line item`} />
                            </ListItem>
                          </List>
                        </Stack>
                      </>
                    );
                  })}
                </Stack>
              </Grid> */}
            </Grid>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Home;

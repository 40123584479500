import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import { sideMenuItemList } from "./components/SideBarMenu";
import ClearIcon from "@mui/icons-material/Clear";
import { styled, useTheme } from "@mui/material/styles";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Badge, MenuItem, Select } from "@mui/material";
import HeaderDropdown from "./components/HeaderDropdown";
import { useDispatch, useSelector } from "react-redux";
// GiBowlingPin
import { BiBowlingBall } from "react-icons/bi";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import axios from "axios";
import {
  logout,
  updateProfileData,
  updateWallet,
} from "../../redux/action/adminActions";
import { useEffect } from "react";
const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const AdminPanel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tempSideMenuPath, walletBalance } = useSelector((state) => state);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  // const { walletBalance } = useSelector((state) => state);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get("/api/v1/alley/sign/out");
      dispatch(logout());
      dispatch(openSnackbar(res?.data?.message, "success"));
      navigate("/");
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };
  // get wallet info
  const getWalletInfo = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/alley/current/wallet/balance`);

      // console.log({res:res})

      dispatch(updateWallet(res?.data?.walletBalance?.wallet));
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  // get profile detail
  const getData = async () => {
    try {
      const res = await axios.get(`/api/v1/alley/profile/data`);
      console.log("profile picture==>", res?.data?.alley);

      dispatch(updateProfileData(res?.data?.alley));
    } catch (error) {
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getWalletInfo();
    getData();
  }, []);
  const drawer = (
    <div>
      {/* <Toolbar />s */}
      <List component={"nav"}>
        {sideMenuItemList?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                component={NavLink}
                to={`${item?.path}`}
                // onClick={handleDrawerToggle}
                sx={{
                  transition: "all 0.1s ease",
                  color: "rgba(111, 190, 68, 1)",
                  "&:hover": {
                    color: "white",
                    borderLeft: "8px solid rgba(111, 190, 68, 1)",
                    boxShadow: "#443e3e78 0px 7px 12px 0px",
                  },
                  "&.active": {
                    color: "white",
                    borderLeft: "8px solid rgba(111, 190, 68, 1)",
                    boxShadow: "#443e3e78 0px 7px 12px 0px",
                  },
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon sx={{ color: "rgba(111, 190, 68, 1)" }}>
                    {item.icons}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ fontFamily: "Montserrat" }}
                    primary={item.label}
                  />
                </ListItemButton>
              </ListItem>
              {index === 1 && tempSideMenuPath?.path ? (
                <ListItem
                  component={NavLink}
                  // to={`${item.path}`}
                  sx={{
                    transition: "all 0.1s ease",
                    color: "rgba(111, 190, 68, 1)",
                    "&:hover": {
                      color: "white",
                      borderLeft: "8px solid rgba(111, 190, 68, 1)",
                      boxShadow: "#443e3e78 0px 7px 12px 0px",
                    },
                    "&.active": {
                      color: "white",
                      borderLeft: "8px solid rgba(111, 190, 68, 1)",
                      boxShadow: "#443e3e78 0px 7px 12px 0px",
                    },
                  }}
                  key={index}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemIcon sx={{ color: "rgba(111, 190, 68, 1)" }}>
                      <BiBowlingBall />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontFamily: "Montserrat",
                        textTransform: "capitalize",
                      }}
                      primary={`${tempSideMenuPath?.pathType}/Leagues`}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );
  return (
    <Box sx={{ display: "flex", maxWidth: "1440px", margin: "auto" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "rgba(25, 28, 36, 1)",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Stack>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { sm: "none" },
                color: "rgba(104, 104, 104, 1)",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Box px={1}>
              <IconButton
                sx={{
                  bgcolor: "transparent",
                  color: "white",
                  fontSize: "12px",
                  fontFamily: "Dosis",
                  borderRadius: "0px",
                }}
              >
                <AccountBalanceWalletIcon sx={{ color: "grey" }} />
                &nbsp;&nbsp;
                <Typography color={"green !important"}>
                  ${walletBalance}
                </Typography>
              </IconButton>
            </Box>
            <Box px={1}>
              <HeaderDropdown handleLogout={handleLogout} />
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <DrawerHeader>
            <Stack
              component={NavLink}
              // to={"/"}
              direction={"row"}
              justifyContent={"center"}
              spacing={1}
              width={"100%"}
              height={"100%"}
              alignItems={"center"}
              alignContent={"center"}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                color: "rgba(111, 190, 68, 1)",
              }}
            >
              <Box
                component={"img"}
                width={{ xs: "50px" }}
                src="/assets/logo/Vector.svg"
              />
              <Typography
                variant="h6"
                sx={{ fontFamily: "Dosis" }}
                component={"caption"}
              >
                Pocket League
              </Typography>
            </Stack>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ color: "rgba(104, 104, 104, 1)" }}
            >
              <ClearIcon />
            </IconButton>
          </DrawerHeader>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <DrawerHeader>
            <Stack
              component={NavLink}
              // to={"/"}
              direction={"row"}
              justifyContent={"center"}
              spacing={1}
              width={"100%"}
              height={"100%"}
              alignItems={"center"}
              alignContent={"center"}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                color: "rgba(111, 190, 68, 1)",
              }}
            >
              <Box
                component={"img"}
                width={{ xs: "50px" }}
                src={"./assets/logo/Vector.svg"}
              />
              <Typography
                variant="h6"
                sx={{ fontFamily: "Dosis" }}
                component={"caption"}
              >
                Pocket League
              </Typography>
            </Stack>
          </DrawerHeader>
          {drawer}
        </Drawer>
      </Box>
      <Stack
        sx={{
          width: {
            sm: `calc(100% - ${drawerWidth}px)`,
            xs: "100%",
            maxWidth: "1440px",
            alignItems: { xs: "center", sm: "end" },
          },
        }}
      >
        <Toolbar />
        <Box p={2}>
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
};
export default AdminPanel;

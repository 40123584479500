import * as React from "react";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { Button, Link, Paper, Typography } from "@mui/material";

import { Stack, borderRadius } from "@mui/system";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { isLoading } from "../../../redux/action/defaultActions";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useState } from "react";

const UpcomingLeagueMainPage = () => {
  const { leagueId } = useParams();
  const [data, setData] = React.useState([]);
  const [isSchedule, setIsSchedule] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const upcomingLeaguePageUrls = [
    { name: "Details", url: `/upcoming/leagues/${leagueId}/` },
    { name: "Teams", url: `/upcoming/leagues/${leagueId}/teams` },
    { name: "Matches", url: `/upcoming/leagues/${leagueId}/matches` },
  ];

  const getApi = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `/api/v1/league/alley/single/league/${leagueId}`
      );
      console.log("singleLeagues  ====>", res.data.alleyLeague);
      setData(res?.data?.alleyLeague);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  const toGetCanScheduleStatus = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`/api/v1/league/signle/schedule/${leagueId}`);
      console.log("res===>", res);
      setIsSchedule(res?.data?.canSchedule);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  const scheduleMatches = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `/api/v1/league/match/scheduling/${leagueId}`
      );
      console.log("schduled==>", res);
      if (res.data.success) {
        setIsSchedule(false);
      }
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  React.useEffect(() => {
    getApi();
    toGetCanScheduleStatus();
  }, []);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          borderRadius={"5px"}
          maxWidth={"1000px"}
          p={1}
          py={2}
          sx={{
            background:
              " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={3} p={1}>
              <img
                className="headImg"
                style={{
                  width: "100%",
                  maxWidth: "250px",
                  minHeight: "7rem",
                  paddingLeft: "1rem",
                }}
                src={data?.leagueImages?.url}
                alt="Image"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Stack>
                <Typography variant={{ xs: "h6", sm: "h5" }}>
                  {data?.leagueName}
                </Typography>
                <Typography variant="body2" component={"p"}>
                  {data?.description}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            overflow={"auto"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box component={"nav"}>
              {upcomingLeaguePageUrls?.map((page, index) => {
                return (
                  <Typography
                    component={NavLink}
                    key={index}
                    to={page?.url}
                    // onClick={() => navigate(page?.url)}
                    fontFamily={"Dosis"}
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "white",
                      "&.active": {
                        color: "rgb(67, 191, 55)",
                        textDecoration: "5px underline",
                        textDecorationColor: "green",
                      },
                      "&:hover": {
                        textDecoration: "5px underline",
                        textDecorationColor: "green",
                        color: "rgb(67, 191, 55)",
                      },
                    }}
                    className="link mx-2"
                    // href="#"
                    underline="hover"
                  >
                    {page?.name}
                  </Typography>
                );
              })}
            </Box>
            {isSchedule ? (
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    background: "transparent",
                    border: "1px solid grey",
                    transition: "ease-in all 300ms",
                    fontFamily: "Dosis",
                    py: 0,
                    py: 0.2,
                  }}
                  onClick={scheduleMatches}
                >
                  Schedule Matches
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Stack>
        </Box>
        <Outlet />
      </Box>
    </>
  );
};

export default UpcomingLeagueMainPage;

import React from "react";

import Box from "@mui/material/Box";

import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import axios from "axios";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import LeagueCards from "../../../components/cards/LeagueCards";

const PastMatchesPage = () => {
  const [pastMatchesData, setPastMatchesData] = useState([]);

  const getApiData = async () => {
    const res = await axios.get("/api/v1/league/active/leagues/past/matches");
    setPastMatchesData(res?.data);
    console.log(res?.data);
  };
  useEffect(() => {
    getApiData();
  }, []);
  return (
    <Stack spacing={3} justifyContent={"center"}>
      <Box
        borderRadius={"5px"}
        p={2}
        component={Paper}
        py={2}
        //   sx={{
        //     background:
        //       " radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.10) 25%, rgba(255, 255, 255, 0.02) 77.08%, rgba(255, 255, 255, 0.00) 60%)",
        //   }}
      >
        <Stack direction={"row"} justifyContent={"space-between"} p={1} py={1}>
          {pastMatchesData.length > 0 ? (
            <>
              <Typography>This Week </Typography>
              <Typography
                fontFamily={"Dosis"}
                to="/"
                sx={{
                  textDecoration: "none",
                  color: "rgbac(154 148 155,1)",
                  "&:hover": {
                    color: "rgb(67, 191, 55)",
                    textDecoration: "2px underline",
                    textDecorationColor: "green",
                  },
                }}
              >
                Filter
              </Typography>
            </>
          ) : (
            <Box width={"100%"} height={"100%"}>
              <Typography
                mt={3}
                variant="h3"
                sx={{ fontFamily: "Dosis", textAlign: "center" }}
              >
                OOP'S SORRY NO MATCHES
              </Typography>
              <Box
                mt={2}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  component={"img"}
                  alt="sorry"
                  width={"250px"}
                  src="/assets/images/bowling-breaks.png"
                />
              </Box>
            </Box>
          )}
        </Stack>

        <Grid container spacing={1}>
          {pastMatchesData?.leaguesMatches?.map((item, index) => {
            return (
              <>
                {/* <Stack container display={"flex"} flexDirection={"column"}> */}
                <Box>
                  <Stack mt={2}>
                    <Typography sx={{ color: "white" }}>
                      {dayjs(item?.matches?.teamMatchDate).format(
                        "dddd, MMMM D, YYYY"
                      )}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Grid item xs={12} sx={6} md={5} lg={4}>
                      <LeagueCards
                        key={index}
                        logo={""}
                        teamNameA={item?.matches?.teamA?.teamName}
                        teamNameB={item?.matches?.teamB?.teamName}
                      />
                    </Grid>
                  </Stack>
                </Box>
                {/* </Stack> */}
              </>
            );
          })}
        </Grid>
      </Box>
    </Stack>
  );
};

export default PastMatchesPage;
